<template>
  <div :class="[$style.key, $style[position]]">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Hotkey',
  props: {
    position: {
      type: String,
      default: 'low'
    }
  }
}
</script>

<style lang="scss" module>
.key {
  position: absolute;
  left: 50%;
  z-index: 50;
  color: white;
  font-size: 0.5vw;
  font-weight: bold;
  text-shadow: none;
  background: #3e3e3e;
  padding: 0.3vw 0.55vw;
  border-radius: 0.25vw;
  border: 0.05vw solid $color_primary;
  animation: keypress linear 1s infinite;

  &.low {
    bottom: -50%;
  }

  &.lower {
    bottom: -90%;
  }
}

@keyframes keypress {
  0% {
    transform: translate(-50%, 0);
    box-shadow: 0 0.2vw 0 0.1vw rgba($color_primary, 0.9);
  }
  15% {
    transform: translate(-50%, 0.2vw);
    box-shadow: 0 0.05vw 0 0.05vw rgba($color_primary, 0.9);
  }
  30% {
    transform: translate(-50%, 0);
    box-shadow: 0 0.2vw 0 0.1vw rgba($color_primary, 0.9);
  }
  100% {
    transform: translate(-50%, 0);
    box-shadow: 0 0.2vw 0 0.1vw rgba($color_primary, 0.9);
  }
}
</style>
