<template>
  <tooltip :position="position" :hideControls="hideControls">
    <div :class="$style.container">
      <basic-facet :facet="facet" />
    </div>
  </tooltip>
</template>

<script>
import Tooltip from './tooltip.vue'
import BasicFacet from '../innate-facet-details/basic-facet.vue'

export default {
  name: 'innate-facet-tooltip',
  components: { Tooltip, BasicFacet },
  props: {
    facet: {
      type: Object,
      required: true
    },
    position: {
      type: String,
      default: 'top'
    },
    hideControls: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  width: 20.65vw;
  gap: 0.2vw;
}
</style>
