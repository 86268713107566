<template>
  <div :class="$style.container">
    <AghanimsDetails :hero="hero" type="scepter" :status="hero.aghanims_scepter" />
    <AghanimsDetails :hero="hero" type="shard" :status="hero.aghanims_shard" />
    <div :class="$style.stem"></div>
  </div>
</template>

<script>
import AghanimsDetails from '@/views/overlays/components/aghanims-details/details.vue'

export default {
  name: 'hero-aghanims',
  components: { AghanimsDetails },
  props: ['hero']
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  gap: 3vw;
  position: relative;
  margin-top: 2vw;
}

.stem {
  position: absolute;
  top: 2vw;
  bottom: 2vw;
  left: 5vw;
  right: 5vw;
  z-index: 0;
  background: rgba($color_black, 0.8);
}
</style>
