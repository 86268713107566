<template>
  <div :class="$style.container">
    <indicator :flag="flag" :class="[$style.topbar, $style.radiant]">
      Click on Heroes to see their details!
    </indicator>
    <indicator :flag="flag" :class="[$style.topbar, $style.dire]">
      Click on Heroes to see their details!
    </indicator>
    <indicator :flag="flag" :class="$style.hud">
      Mouse over hero abilities, talents, aghanims, and inventory to view their tooltips!
    </indicator>
  </div>
</template>

<script>
import Indicator from './components/common/Indicator.vue'

export default {
  name: 'Indicators',
  components: { Indicator },
  data() {
    return {
      flag: false
    }
  },
  computed: {
    matchId() {
      return this.$store.getters.matchId
    }
  },
  mounted() {
    const lastMatchId = localStorage.getItem('lastMatchIdInProgress')

    if (this.matchId !== lastMatchId) {
      this.$store.commit('ShowIndicators')
      localStorage.setItem('lastMatchIdInProgress', this.matchId)
      this.flag = true
    }
  }
}
</script>

<style lang="scss" module>
@keyframes pointing-up {
  0% {
    transform: translateY(0vw);
  }
  20% {
    transform: translateY(-0.3vw);
  }
  80% {
    transform: translateY(-0.3vw);
  }
  100% {
    transform: translateY(0vw);
  }
}

@keyframes pointing-down {
  0% {
    transform: translate(-50%, 0vw);
  }
  20% {
    transform: translate(-50%, 0.3vw);
  }
  80% {
    transform: translate(-50%, 0.3vw);
  }
  100% {
    transform: translate(-50%, 0vw);
  }
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.topbar {
  position: absolute;
  top: 4vw;
  left: 29vw;
  font-size: 0.7vw;
  animation: pointing-up ease-in-out 2s infinite;

  &.dire {
    left: auto;
    right: 29vw;
  }
}

.hud {
  position: absolute;
  bottom: 9vw;
  left: 50%;
  font-size: 0.8vw;
  animation: pointing-down ease-in-out 2s infinite;
}
</style>
