<template>
  <div :class="$style.container">
    <div :class="$style.icon">
      <div :class="[$style.color, ready ? $style.dark : $style.white]"></div>
      <font-awesome v-if="ready" :class="[$style.ficon, $style.ready]" icon="fa-circle-check" />
      <font-awesome v-if="!ready" :class="[$style.ficon, $style.notReady]" icon="fa-circle-xmark" />
    </div>
    <div v-if="buyback.cooldown > 0" :class="$style.cooldown">{{ cooldown }}</div>
    <div v-if="buyback.lacking_gold < 0" :class="[$style.number, $style.lacking]">
      {{ lacking }} <img :class="$style.gold" src="@/assets/imgs/gold.png" />
    </div>
    <div v-if="buyback.surplus_gold > 0" :class="[$style.number, $style.surplus]">
      +{{ surplus }} <img :class="$style.gold" src="@/assets/imgs/gold.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'buyback',
  props: ['data'],
  methods: {
    numberFormat(gold) {
      if (Math.abs(gold) < 1000) {
        return gold
      } else {
        return `${(Math.abs(gold) / 1000).toFixed(1).replace('.0', '')}k`
      }
    }
  },
  computed: {
    buyback() {
      return this.data.hero.buyback
    },
    ready() {
      return this.buyback.ready
    },
    cooldown() {
      const time = Math.floor(this.buyback.cooldown)
      const minutes = Math.floor(time / 60)
      const seconds = time - minutes * 60
      const leadingZero = seconds < 10 ? '0' : ''

      return `${minutes}:${leadingZero}${seconds}`
    },
    lacking() {
      return this.numberFormat(this.buyback.lacking_gold)
    },
    surplus() {
      return this.numberFormat(this.buyback.surplus_gold)
    }
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($color_black, 0.6);
  border-radius: 50%;
  padding: 0.1vw;
}

.color {
  position: absolute;
  top: 0.2vw;
  bottom: 0.2vw;
  left: 0.2vw;
  right: 0.2vw;
  z-index: 0;
  border-radius: 50%;

  &.white {
    background: $color_white;
  }

  &.dark {
    background: $color_dark_green;
  }
}

.ficon {
  font-size: 1.5vw;
  z-index: 10;
}

.ready {
  color: $color_green;
}
.notReady {
  color: $color_red;
}

.cooldown {
  font-size: 0.8vw;
  color: $color_white;
  text-shadow: text-shadow($color_black, 0.05vw);
}

.number {
  font-weight: bold;
  font-size: 0.7vw;
  display: flex;
  align-items: center;
  gap: 0.1vw;
  text-shadow: text-shadow($color_black, 0.05vw);
}

.lacking {
  color: $color_highlight_red;
}

.surplus {
  color: $color_green;
}

.gold {
  width: 0.9vw;
  height: 0.9vw;
}
</style>
