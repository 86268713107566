<template>
  <transition name="fade-left">
    <div v-if="show" :class="$style.container">
      <div :class="$style.main">
        <label :class="$style.field">Language:</label>
        <div :class="$style.select">
          <div :class="$style.value" @click="toggleOptions()">
            <div :class="$style.label">{{ selectedLang.label }}</div>
            <font-awesome
              :class="[$style.ficon, optionsOpen ? $style.open : undefined]"
              :icon="['fas', 'chevron-down']"
            />
          </div>
          <div :class="[$style.options, optionsOpen ? $style.open : undefined]">
            <div
              v-for="lang in languages"
              :key="`lang-${lang.value}`"
              :class="[$style.option, selectedLang.value === lang.value ? $style.selected : '']"
              @click="selectLanguage(lang.value)"
            >
              {{ lang.label }}
            </div>
          </div>
        </div>
      </div>
      <ul :class="$style.disclaimer">
        <li>Tooltip translations are taken directly from Dota 2 localization files.</li>
        <li>Not everything has been translated yet.</li>
      </ul>
      <transition name="fade-in">
        <div v-if="beforeSave" :class="$style.info">
          The extension will restart after applying the selected language.
        </div>
      </transition>
      <transition name="fade-in">
        <div v-if="beforeSave" :class="$style.save">
          <button @click="setLanguage" :class="[$style.button, $style.apply]">Apply</button>
          <button @click="cancel" :class="[$style.button, $style.cancel]">Cancel</button>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Language',
  data() {
    return {
      newLanguage: '',
      optionsOpen: false,
      languages: [
        {
          label: 'English',
          value: 'en'
        },
        {
          label: 'Español',
          value: 'es'
        },
        {
          label: 'Português',
          value: 'pt'
        },
        {
          label: 'Русский',
          value: 'ru'
        }
      ]
    }
  },
  computed: {
    currentLanguage() {
      return localStorage.getItem('language')
    },
    selectedLang() {
      if (this.newLanguage !== '') {
        return this.languages.find((lang) => lang.value === this.newLanguage)
      } else {
        return this.languages.find((lang) => lang.value === this.currentLanguage)
      }
    },
    beforeSave() {
      return this.newLanguage !== '' && this.currentLanguage !== this.newLanguage
    },
    show() {
      return this.$store.getters.popupSettings
    }
  },
  methods: {
    toggleOptions() {
      this.optionsOpen = !this.optionsOpen
    },
    selectLanguage(lang) {
      this.newLanguage = lang
      this.optionsOpen = false
    },
    setLanguage() {
      localStorage.setItem('language', this.newLanguage)
      location.reload()
    },
    cancel() {
      this.newLanguage = ''
    }
  }
}
</script>

<style lang="scss" module>
.container {
  position: absolute;
  top: 6.8vw;
  left: 0.4vw;
  width: 16vw;
  display: flex;
  flex-direction: column;
  gap: 0.4vw;
  padding: 0.8vw 1vw;
  background: rgba(#162024, 0.95);
  border-radius: 0 0.3vw 0.3vw 0;
  box-shadow: 0 0.05vw 0.2vw rgba($color_black, 0.5);
}

.main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.4vw;
}

.field {
  color: white;
  font-size: 0.8vw;
}

.select {
  position: relative;
  padding: 0.4vw 0.6vw;
  border-radius: 0.3vw;
  border: 0.05vw solid rgba(black, 0.2);
  background: #11181b;
}

.ficon {
  transition: transform ease-in-out 0.3s;

  &.open {
    transform: rotateZ(180deg);
  }
}

.value {
  width: 7vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1vw;
  color: $color_primary;
  cursor: pointer;
}

.options {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  background: #11181b;
  border-radius: 0 0 0.3vw 0.3vw;
  color: white;
  font-size: 1vw;
  border: 0.05vw solid rgba(black, 0.2);
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all ease-in-out 0.3s;

  &.open {
    opacity: 1;
    max-height: 10vw;
  }
}

.option {
  cursor: pointer;
  padding: 0.2vw 0.4vw;

  &:hover {
    color: $color_primary;
    background: rgba(white, 0.2);
  }

  &.selected {
    color: $color_primary;
  }
}

.disclaimer {
  display: flex;
  flex-direction: column;
  gap: 0.2vw;
  color: $color_lightblue;
  font-size: 0.65vw;
  margin-left: 0.6vw;
}

.info {
  border: 1px solid $color_red;
  background: rgba($color_red, 0.4);
  color: rgba($color_white, 0.8);
  text-align: center;
  font-size: 0.7vw;
  text-shadow: none;
  padding: 0.2vw 0.4vw;
  border-radius: 0.4vw;
}

.save {
  display: flex;
  justify-content: center;
  gap: 0.4vw;
  flex: 1;
}

.button {
  padding: 0.2vw 0.8vw;
  cursor: pointer;
  border: none;
  border-radius: 0.2vw;
  text-shadow: none;
  text-transform: uppercase;
  font-size: 0.8vw;

  &.apply {
    color: white;
    background: $color_red;
  }
}
</style>
