import Vue from 'vue'
import VueRouter from 'vue-router'
import Overlays from '../views/overlays/index.vue'
import Mobile from '../views/mobile/index.vue'
import Config from '../views/config/index.vue'
import LiveConfig from '../views/live-config/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Overlays
  },
  {
    path: '/overlays',
    name: 'Overlays',
    component: Overlays
  },
  {
    path: '/mobile',
    name: 'Mobile',
    component: Mobile
  },
  {
    path: '/Config',
    name: 'Config',
    component: Config
  },
  {
    path: '/live-config',
    name: 'LiveConfig',
    component: LiveConfig
  }
]

export default new VueRouter({
  base: process.env.BASE_URL,
  routes
})
