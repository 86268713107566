<template>
  <div :class="$style.container">
    <h1>Configuration</h1>
    <p>
      There is no configuration needed for this version. Please only use this app for the main
      streaming channel for now.
    </p>
  </div>
</template>

<script>
export default {
  name: 'Config'
}
</script>

<style lang="scss" module>
.container {
  width: 100vw;
  height: 100vh;
  background: white;
  color: black;
  padding: 20px;
}
</style>
