<template>
  <transition name="slide-up">
    <div v-if="ability !== null" :class="$style.container">
      <div :class="$style.header">
        <img :class="$style.icon" :src="abilityImage" />
        <div :class="$style.name">{{ abilityName }}</div>
        <div :class="$style.close" @click="close">
          <font-awesome icon="fa-times" />
        </div>
      </div>
      <video
        :class="$style.video"
        autoplay
        preload="auto"
        loop
        playsinline
        muted
        :poster="`https://cdn.cloudflare.steamstatic.com/apps/dota2/videos/dota_react/abilities/${ability.hero}/${abilityDataName}.jpg`"
        :src="`https://cdn.cloudflare.steamstatic.com/apps/dota2/videos/dota_react/abilities/${ability.hero}/${abilityDataName}.webm`"
      ></video>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ability-video',
  methods: {
    close() {
      this.$store.commit('SetPreviewedAbility', null)
    }
  },
  computed: {
    ability() {
      return this.$store.getters.previewedAbility
    },
    abilityImage() {
      return this.getAbilityDetails(this.ability.name, 'image')
    },
    abilityName() {
      if (this.ability !== null) {
        return this.getAbilityDetails(this.ability.name, 'name')
      }
      return ''
    },
    abilityDataName() {
      if (this.ability !== null) {
        const ability = this.getAbilityDetails(this.ability.name)
        if (ability.shard_ability) {
          return `${this.ability.hero}_aghanims_shard`
        } else if (ability.scepter_ability) {
          return `${this.ability.hero}_aghanims_scepter`
        } else if (this.ability.name.includes('nevermore_shadowraze')) {
          return 'nevermore_shadowraze1'
        }
        return ability.data_name
      }
      return ''
    }
  }
}
</script>

<style lang="scss" module>
.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 8.5vw;
  bottom: 9vw;
  z-index: 30;
  border: 0.1vw solid rgba($color_black, 0.5);
  border-radius: 0.1vw;
  box-shadow: 0 0.05vw 0.1vw rgba($color_black, 0.5);
  background: #162024f8;
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
  padding: 0.4vw;
  gap: 0.5vw;
}

.icon {
  width: 1.8vw;
  height: 1.8vw;
  border-radius: 0.2vw;
}

.name {
  flex: 1;
  color: $color_white;
  font-family: 'Reaver';
  font-weight: bold;
  font-size: 1vw;
}

.close {
  color: $color_white;
  font-weight: bold;
  cursor: pointer;
  padding: 0.4vw;
}

.video {
  width: 27vw;
  height: 15.2vw;
}
</style>

<style lang="scss">
.slide-up-enter-active {
  opacity: 0;
  animation: slide-up 0.5s;
}
.slide-up-leave-active {
  z-index: 10;
  animation: slide-up 0.5s reverse;
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20vw) scale(0.2);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
</style>
