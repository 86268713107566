<template>
  <div v-if="playerData" :class="$style.wrapper">
    <div :class="$style.menu">
      <div
        v-for="(tab, index) in tabs"
        :class="[$style.tab, { [$style.active]: index === selectedTab }]"
        :key="`${hero.name}-${index}`"
        @click="toggleHeroMenu(index)"
      >
        {{ tab }}
      </div>
    </div>
    <div :class="$style.container">
      <hero-abilities
        :heroName="playerData.hero.name"
        :abilities="playerData.abilities"
        :aghanims="aghanimsStatus"
        v-if="selectedTab === 0"
      />
      <hero-inventory v-if="selectedTab === 1" />
      <hero-aghanims :hero="playerData.hero" v-if="selectedTab === 2" />
      <hero-talents :hero="playerData.hero" position="top" v-if="selectedTab === 3" />
    </div>
  </div>
</template>

<script>
import HeroAbilities from './abilities.vue'
import HeroAghanims from './aghanims.vue'
import HeroTalents from './talents.vue'
import HeroInventory from './inventory.vue'

export default {
  name: 'hero-menu',
  props: ['hero'],
  components: {
    HeroAbilities,
    HeroAghanims,
    HeroTalents,
    HeroInventory
  },
  computed: {
    playerData() {
      return this.$store.getters.playerData
    },
    heroDetails() {
      return this.getHeroDetails(this.hero.name)
    },
    selectedTab() {
      return this.$store.getters.selectedHeroTab
    },
    aghanimsStatus() {
      return {
        scepter: this.playerData.hero.aghanims_scepter,
        shard: this.playerData.hero.aghanims_shard
      }
    },
    tabs() {
      return ['Abilities', 'Items', "Aghanim's", 'Talents']
    }
  },
  methods: {
    toggleHeroMenu(btnIndex) {
      if (btnIndex !== this.$store.getters.selectedHeroTab) {
        this.$store.commit('SetSelectedHeroTab', btnIndex)
      }
    }
  }
}
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background-color: #212528;
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 0.5vw solid black;
}

.tab {
  color: #515557;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 4vw;
  padding: 2vw 4vw;
  margin-bottom: -0.5vw;
  cursor: pointer;
}

.active {
  color: $color_primary;
  border-bottom: 0.5vw solid $color_primary;
}

.container {
  min-height: 20vw;
  width: 100%;
  box-shadow: inset 0 2vw 10vw rgba(black, 0.6);
  padding: 2vw 4vw 5vw 4vw;
  background-color: #272d31;
}
</style>
