<template>
  <div :class="$style.container">
    <template v-if="data.value === 'win_probability'">
      <div :class="[$style.content, $style.win_probability]">
        <win-probability />
      </div>
    </template>
    <template v-else>
      <div :class="$style.team">
        <div
          v-for="(playerData, index) in radiantData"
          :key="`status-${index}`"
          :class="$style.status"
        >
          <component :is="data.value" :data="playerData"></component>
        </div>
      </div>
      <div :class="$style.content"></div>
      <div :class="$style.team">
        <div
          v-for="(playerData, index) in direData"
          :key="`status-${index}`"
          :class="$style.status"
        >
          <component :is="data.value" :data="playerData"></component>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Level from './status/level.vue'
import Ultimate from './status/ultimate.vue'
import Buyback from './status/buyback.vue'
import Bkb from './status/bkb.vue'
import Aghanims from './status/aghanims.vue'
import Neutrals from './status/neutrals.vue'
import WinProbability from './status/win_probability.vue'

export default {
  name: 'status',
  components: { Level, Ultimate, Buyback, Bkb, Aghanims, Neutrals, WinProbability },
  data() {
    return {
      intro: true
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    close() {
      this.intro = true
      setTimeout(() => {
        this.$store.commit('CloseQuickStats')
      }, 500)
    }
  },
  computed: {
    playerData() {
      return this.$store.getters.playerDataList
    },
    radiantData() {
      return this.playerData.slice(0, 5)
    },
    direData() {
      return this.playerData.slice(5)
    },
    mouseOver() {
      return this.$store.getters.mouseOver
    }
  },
  mounted() {
    setTimeout(() => {
      this.intro = false
    }, 100)
  }
}
</script>

<style lang="scss" module>
.container {
  position: absolute;
  left: 28.7vw;
  top: 3.6vw;
  width: 42.7vw;
  height: 10vw;
  display: flex;
  align-items: flex-start;
  z-index: 50;
}

.team {
  flex: 1;
  height: 100%;
  display: flex;
}

.status {
  width: 100%;
  height: 3vw;
  display: flex;
  justify-content: center;
}

.content {
  margin-top: 0;
  width: 10.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: margin-top ease 0.5s;

  &.win_probability {
    width: 100%;

    .title {
      width: 10vw;
      min-width: auto;
    }
  }
}

.ficon {
  margin-left: 0.4vw;
  font-size: 0.8vw;
}
</style>
