<template>
  <div :class="$style.container">
    <div :class="[$style.header, $style[facetColor]]">
      <div :class="$style.icon">
        <img :src="icon" :class="$style.iconImg" />
      </div>
      <div :class="$style.title">{{ facet.name }}</div>
    </div>
    <div :class="$style.description" v-html="facet.desc"></div>
  </div>
</template>

<script>
export default {
  name: 'basic-facet',
  props: {
    facet: {
      type: Object,
      required: true
    }
  },
  computed: {
    icon() {
      return require(`@/assets/imgs/facets/${this.facet.icon}_png.png`)
    },
    facetColor() {
      return `facet_gradient_${this.facet.color}`
    }
  }
}
</script>

<style lang="scss" module>
@import '~@/assets/scss/_facets.scss';

.container {
  display: flex;
  flex-direction: column;
  border-radius: 0.1vw;
  border: 0.15vw solid rgba($color_black, 0.5);
  background: #1b1c26 url('~@/assets/imgs/tooltip-bg.png') center top no-repeat;
  background-size: 100%;
  box-shadow: 0 0.05vw 0.1vw rgba($color_black, 0.5);
}

.header {
  display: flex;
  height: 2.35vw;
}

.icon {
  width: 2.35vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1));
}

.iconImg {
  height: 50%;
}

.title {
  flex: 1;
  padding-left: 1vw;
  font-family: 'Reaver';
  color: $color_white;
  font-size: 0.8vw;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 2.55vw;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background: url('~@/assets/imgs/facet-bg.png') left top no-repeat;
  background-size: 150%;
}

.description {
  color: $color_bluishgrey;
  font-size: 0.7rem;
  padding: 0.4rem 0.8rem;

  strong {
    color: white;
    font-size: 0.85rem;
  }
}
</style>
