<template>
  <div :class="$style.container">
    <Hotkey v-if="showHotkeys">
      {{ hero.index === 9 ? 0 : hero.index + 1 }}
    </Hotkey>
    <!-- HERO PORTRAIT CONTAINER -->
    <div
      :class="[$style.clicker, $style[team], { [$style.indicator]: showIndicators }]"
      @mouseenter="hover = true"
      @mouseleave="hover = false"
      @click="toggleTopBarHero"
    ></div>
    <transition name="fade-down">
      <!-- HOVER LABEL -->
      <div :class="$style.wrapper" v-if="hover && !isSelected">
        <div :class="$style.hoverTooltip">
          <img :class="$style.hoverIcon" :src="heroDetails.icon" />
          <div :class="$style.hoverLabel">{{ heroDetails.name }}</div>
        </div>
      </div>
    </transition>
    <transition name="fade-down">
      <!-- TOOLTIP CONTAINER -->
      <div :class="$style.wrapper" v-if="isSelected">
        <div :class="$style.tooltip">
          <div :class="$style.arrow"></div>
          <div :class="$style.content">
            <div :class="$style.close" @click="toggleTopBarHero">
              <font-awesome icon="fa-times" />
            </div>
            <hero-details :hero="hero" />
          </div>
          <div :class="$style.stem"></div>
          <hero-inventory />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import HeroDetails from '../hero-details/details.vue'
import HeroInventory from '../hero-details/inventory.vue'
import Hotkey from '../popups/hotkey.vue'

export default {
  name: 'hero-tooltip',
  components: {
    HeroDetails,
    HeroInventory,
    Hotkey
  },
  props: ['hero', 'team'],
  data: () => {
    return {
      hover: false
    }
  },
  methods: {
    toggleTopBarHero() {
      if (this.hero.index === this.$store.getters.selectedTopBarHero) {
        this.$store.commit('SetTopBarHero', null)
      } else {
        this.$store.commit('SetTopBarHero', this.hero.index)
      }
    }
  },
  computed: {
    playerData() {
      return this.$store.getters.playerData
    },
    isSelected() {
      return this.$store.getters.selectedTopBarHero === this.hero.index
    },
    heroDetails() {
      return this.getHeroDetails(this.hero.name)
    },
    showHotkeys() {
      return this.$store.getters.showHotkeys
    },
    showIndicators() {
      return this.$store.getters.showIndicators
    }
  }
}
</script>

<style lang="scss" module>
@keyframes indicator {
  0% {
    box-shadow: inset 0 0 1vw $color_primary;
  }
  50% {
    box-shadow: inset 0 0 0.8vw $color_primary;
  }
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  margin-left: -0.1vw;
  margin-right: -0.2vw;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.clicker {
  height: 2.1vw;
  cursor: pointer;
  position: relative;
  transition: all ease-in-out 0.4s;

  &.radiant {
    clip-path: polygon(0 0, 3.2vw 0, 3.2vw 0.2vw, 100% 100%, 0.3vw 100%, 0 0.2vw);
  }

  &.dire {
    clip-path: polygon(0.3vw 0, 100% 0, 100% 0.2vw, 3.2vw 100%, 0 100%, 0.3vw 0.2vw);
  }

  &:hover {
    box-shadow: inset 0 0 1vw rgba($color_primary, 0.9);
    background: rgba($color_white, 0.1);
  }

  &.indicator {
    animation: indicator linear 1s infinite alternate;
  }
}

.hoverTooltip {
  position: absolute;
  top: 1.6vw;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  color: $color_white;
  border-radius: 0.3vw;
  padding: 0.2vw 0.4vw;
  font-size: 0.6vw;
  background: rgba(#162024, 0.95);
  box-shadow: 0 0.05vw 0.2vw rgba($color_black, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hoverIcon {
  height: 1.2vw;
}

.hoverLabel {
  flex: 1;
  padding: 0 0.4vw;
  white-space: nowrap;
  text-align: center;
}

.tooltip {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 1.5vw;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;
  // transition: all ease 0.5s;

  .arrow {
    content: '';
    position: absolute;
    z-index: 10;
    top: -0.34vw;
    left: 50%;
    width: 1vw;
    height: 1vw;
    border: 0.2vw solid $color_primary;
    background: rgba(#162024, 0.95);
    transform: translateX(-50%) rotate(135deg);
    clip-path: polygon(0 0, 0 100%, 100% 100%);
  }

  .content {
    position: relative;
    z-index: 1;
    min-width: 15vw;
    border-radius: 0.2vw;
    border: 0.2vw solid rgba($color_black, 0.5);
    background: rgba(#162024, 0.95);
    box-shadow: 0 0.05vw 0.2vw rgba($color_black, 0.5);

    .close {
      position: absolute;
      top: 0;
      right: 0vw;
      color: $color_white;
      font-weight: bold;
      padding: 0.4vw 0.8vw;
      margin-right: -0.2vw;
      border-radius: 0.2vw;
      cursor: pointer;
    }
  }
}

.stem {
  position: absolute;
  bottom: -0.8vw;
  left: 50%;
  width: 10vw;
  height: 1vw;
  transform: translateX(-50%);
  z-index: 0;
  background: rgba(0, 0, 0, 0.8);
}
</style>
