<template>
  <div id="app">
    <div v-if="channelId !== null && staticDataReady">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    channelId() {
      return this.$store.getters.channelId
    },
    isTest() {
      return this.$route.query.test !== undefined
    }
  },
  methods: {
    init(channelId) {
      let language = localStorage.getItem('language')

      if (language === null) {
        switch (channelId) {
          case '131439345':
          case '728783472':
          case '728784987':
          case '728785240':
            language = 'es'
            break
          case '35631192':
          case '65504150':
          case '65504552':
          case '65504582':
            language = 'ru'
            break
          default:
            language = 'en'
        }
      }

      this.dataLanguage = language

      localStorage.setItem('language', language)

      // Initialize Static Data
      this.initializeStaticData()
    }
  },
  mounted() {
    // Authenticate Twitch Channel
    window.Twitch.ext.onAuthorized((auth) => {
      this.$store.commit('SetChannelId', auth.channelId)
      this.init(auth.channelId)
    })
    // Set Twitch Stream Latency
    window.Twitch.ext.onContext((context) => {
      this.$store.commit('SetStreamLatency', context.hlsLatencyBroadcaster)
    })

    if (this.isTest) {
      if (this.$route.query.channel) {
        this.$store.commit('SetChannelId', this.$route.query.channel)
        this.init(this.$route.query.channel)
      } else {
        this.$store.commit('SetChannelId', 'test')
        this.init('test')
      }
      this.$store.commit('SetStreamLatency', 0)
    } else if (this.$route.query.channel) {
      this.$store.commit('SetChannelId', this.$route.query.channel)
      this.init(this.$route.query.channel)
    }
  }
}
</script>
