<template>
  <div :class="$style.container">
    <exp-tooltip
      position="bottom"
      :level="playerData.hero.level"
      :value="playerData.hero.xp - prevExpFloor"
      :max="expToNextLevel - prevExpFloor"
    />
    <div :class="$style.label">{{ playerData.hero.level }}</div>
    <circular-progress
      :class="$style.progress"
      :value="playerData.hero.xp - prevExpFloor"
      :max="expToNextLevel - prevExpFloor"
    />
  </div>
</template>

<script>
import ExpTooltip from '../tooltips/exp-tooltip.vue'
import CircularProgress from '../common/CircularProgress.vue'

export default {
  name: 'hero-level',
  components: { ExpTooltip, CircularProgress },
  props: ['playerData'],
  computed: {
    prevExpFloor() {
      return this.getXpToLevel(this.playerData.hero.level - 1)
    },
    expToNextLevel() {
      return this.getXpToLevel(this.playerData.hero.level)
    }
  }
}
</script>

<style lang="scss" module>
.container {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0.2vw $color_black;
}

.label {
  color: #caa170;
  font-family: 'Reaver';
  font-size: 0.7vw;
  text-align: center;
  line-height: 1.8vw;
  text-shadow: 0.1rem 0.1rem 0.2rem $color_red, -0.1rem -0.1rem 0.2rem $color_red,
    -0.1rem 0.1rem 0.2rem $color_red, 0.1rem -0.1rem 0.2rem $color_red;
}

.progress {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
