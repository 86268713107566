<template>
  <div :class="$style.container">
    <talent-details :position="position" :hero="hero" :withAttributes="true" />
  </div>
</template>

<script>
import TalentDetails from '@/views/overlays/components/talent-details/details.vue'

export default {
  name: 'hero-talents',
  components: { TalentDetails },
  props: ['position', 'hero']
}
</script>

<style lang="scss" module>
.container {
  width: 100%;
}
</style>
