<template>
  <transition name="fade-in">
    <div v-if="flag && !hideIndicator" :class="$style.container">
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Indicator',
  data() {
    return {
      hideIndicator: false,
      hidingIndicator: false
    }
  },
  methods: {
    startHiding(flag) {
      if (flag && !this.hidingIndicator && !this.hideIndicator) {
        this.hidingIndicator = true
        setTimeout(() => {
          this.hideIndicator = true
          this.$store.commit('HideIndicators')
        }, 8000)
      }
    }
  },
  props: {
    flag: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    flag: {
      handler(flag, _) {
        this.startHiding(flag)
      },
      deep: true
    }
  },
  mounted() {
    this.startHiding(this.flag)
  }
}
</script>

<style lang="scss" module>
.container {
  color: $color_primary;
  padding: 0.3vw 0.8vw;
  background: rgba($color_black, 0.9);
  border: 0.1vw solid $color_primary;
  border-radius: 0.4vw;
  box-shadow: 0 0 0.2vw $color_black;
  text-align: center;
}
</style>
