<template>
  <div :class="$style.container"></div>
</template>

<script>
export default {
  name: 'BgLoading'
}
</script>

<style lang="scss" module>
.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background: linear-gradient(
    100deg,
    rgba($color_primary, 0.6) 30%,
    rgba($color_primary, 0.4) 50%,
    rgba($color_primary, 0.6) 70%
  );
  background-size: 400%;
  animation: fading linear 1.2s infinite;
}

@keyframes fading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
</style>
