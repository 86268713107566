<template>
  <transition name="fade-left">
    <div v-if="show" :class="$style.container">
      <div v-for="team in teams" :class="$style.team" :key="`team-${team}`">
        <div :class="[$style.header, $style[team]]">
          <div :class="$style.score">{{ getScore(team) }}</div>
          <div :class="$style.teamName">{{ getTeamName(team) }}</div>
          <div :class="[$style.col, $style['col-level']]">LVL</div>
          <div :class="[$style.col, $style['col-talent']]">TT</div>
          <div :class="[$style.col, $style['col-kda']]">K / D / A</div>
          <div :class="[$style.col, $style['col-lhd']]">LH / D</div>
          <div :class="[$style.col, $style['col-ultimate']]">ULT</div>
          <div :class="[$style.col, $style['col-gold']]">Gold</div>
          <div :class="[$style.col, $style['col-buyback']]">Buyback</div>
        </div>
        <div :class="$style.rows">
          <div
            v-for="(player, index) in getPlayers(team)"
            :key="`${team}-${index}`"
            :class="[$style.row, $style[`slot_${team === 'radiant' ? index : index + 5}`]]"
          >
            <div :class="$style.heroInfo">
              <img :class="$style.heroImage" :src="heroDetails(player, 'image')" />
              <div :class="$style.info">
                <div :class="$style.player">{{ player.stats.name }}</div>
                <div :class="$style.hero">{{ heroDetails(player, 'name') }}</div>
              </div>
            </div>
            <div :class="[$style.col, $style.value, $style['col-level']]">
              <hero-level :class="$style.level" :playerData="player" />
            </div>
            <div :class="[$style.col, $style.value, $style['col-talent']]">
              <hero-talents
                :hero="player.hero"
                size="small"
                position="right"
                :withAttributes="true"
              />
            </div>
            <div :class="[$style.col, $style.value, $style['col-kda']]">
              <span :class="$style.highlight">{{ player.stats.kills }}</span>
              <span :class="$style.slash">/</span>
              {{ player.stats.deaths }}
              <span :class="$style.slash">/</span>
              {{ player.stats.assists }}
            </div>
            <div :class="[$style.col, $style.value, $style['col-lhd']]">
              <span :class="$style.highlight">{{ player.stats.last_hits }}</span>
              <span :class="$style.slash">/</span>
              {{ player.stats.denies }}
            </div>
            <div :class="[$style.col, $style.value, $style['col-ultimate']]">
              <hero-ultimate :playerData="player" />
            </div>
            <div :class="[$style.col, $style.value, $style['col-gold']]">
              {{ player.hero.gold }}
            </div>
            <div :class="[$style.col, $style.value, $style['col-buyback']]">
              <hero-buyback :buyback="player.hero.buyback" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import HeroTalents from '../hero-details/talents.vue'
import HeroLevel from '../hero-details/level.vue'
import HeroUltimate from '../hero-details/ultimate.vue'
import HeroBuyback from '../hero-details/buyback.vue'

export default {
  name: 'score-board',
  components: { HeroTalents, HeroLevel, HeroUltimate, HeroBuyback },
  data: () => {
    return {
      teams: ['radiant', 'dire']
    }
  },
  methods: {
    getTeamName(team) {
      return this.$store.getters.teams[team]
    },
    getScore(team) {
      return this.$store.getters.scores[team]
    },
    getPlayers(team) {
      if (team === 'radiant') {
        return this.$store.getters.radiantPlayers
      }
      return this.$store.getters.direPlayers
    },
    heroDetails(player, property) {
      return this.getHeroDetails(player.hero.name, property)
    }
  },
  computed: {
    show() {
      return this.$store.getters.popupScoreboard
    }
  }
}
</script>

<style lang="scss" module>
.container {
  position: absolute;
  top: 6.8vw;
  left: 0;
  background: rgba(#162024, 0.95);
  border-radius: 0 0.3vw 0.3vw 0;
  box-shadow: 0 0.05vw 0.2vw rgba($color_black, 0.5);
  border: 0.2vw solid rgba($color_black, 0.5);
}

.header {
  display: flex;
  align-items: center;
  padding: 0.4vw 0.8vw;
  color: rgba($color_white, 0.8);
  font-size: 0.7vw;
  font-weight: bold;
  font-family: 'Reaver';
  gap: 0.6vw;

  &.radiant .teamName,
  &.radiant .score {
    color: $color_white;
    text-shadow: 0.1vw 0.1vw 0.1vw $color_dark_green, 0.1vw 0 0.1vw $color_dark_green,
      0 0.1vw 0.1vw $color_dark_green, 0 0 0.1vw $color_dark_green;
  }

  &.dire .teamName,
  &.dire .score {
    color: $color_white;
    text-shadow: 0.1vw 0.1vw 0.1vw $color_dark_red, 0.1vw 0 0.1vw $color_dark_red,
      0 0.1vw 0.1vw $color_dark_red, 0 0 0.1vw $color_dark_red;
  }
}

.score {
  width: 3.3vw;
  font-size: 1.4vw;
  text-align: center;
}

.teamName {
  width: 10vw;
  font-size: 1vw;
  text-transform: uppercase;
}

.rows {
  display: flex;
  flex-direction: column;
  gap: 0.3vw;
}

.row {
  padding: 0.25vw 0.4vw;
  border-left: 0.2vw solid $color_white;
  display: flex;
  gap: 0.6vw;
  background: rgba($color_black, 0.4);

  &.slot_0 {
    border-color: $color_slot_0;
  }
  &.slot_1 {
    border-color: $color_slot_1;
  }
  &.slot_2 {
    border-color: $color_slot_2;
  }
  &.slot_3 {
    border-color: $color_slot_3;
  }
  &.slot_4 {
    border-color: $color_slot_4;
  }
  &.slot_5 {
    border-color: $color_slot_5;
  }
  &.slot_6 {
    border-color: $color_slot_6;
  }
  &.slot_7 {
    border-color: $color_slot_7;
  }
  &.slot_8 {
    border-color: $color_slot_8;
  }
  &.slot_9 {
    border-color: $color_slot_9;
  }
}

.heroInfo {
  display: flex;
  align-items: center;
  width: 14vw;
  margin-left: 0.1vw;
}

.heroImage {
  width: 3.4vw;
  border-radius: 0.2vw;
}

.info {
  display: flex;
  flex-direction: column;
  margin-left: 0.5vw;
}

.player {
  font-size: 1vw;
  color: $color_white;
  width: 10vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hero {
  font-size: 0.6vw;
  text-transform: capitalize;
  color: rgba($color_white, 0.6);
}

.col {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  &-talent {
    text-transform: none !important;
    width: 2.5vw;
  }

  &-level {
    width: 2vw;

    .level {
      width: 1.8vw;
      height: 1.8vw;
      position: relative;
    }
  }

  &-gold {
    width: 3.5vw;

    &.value {
      font-size: 0.9vw;
      color: gold;
    }
  }

  &-kda,
  &-lhd {
    align-items: center;
    justify-content: flex-start;
    width: 5vw;

    .highlight {
      font-size: 1vw;
      color: rgba($color_white, 0.9);
      font-weight: bold;
    }

    &.value {
      font-size: 0.9vw;
      color: rgba($color_white, 0.6);
    }

    .slash {
      padding: 0 0.1vw;
      font-size: 0.8vw;
      color: rgba($color_white, 0.4);
    }
  }

  &-lhd {
    width: 4vw;
  }

  &-ultimate {
    width: 2vw;
  }

  &-buyback {
    width: 4vw;
  }
}
</style>
