<template>
  <tooltip :position="position">
    <div :class="$style.container">
      <talent-details :position="position" :hero="hero" :withAttributes="withAttributes" />
    </div>
  </tooltip>
</template>

<script>
import Tooltip from './tooltip.vue'
import TalentDetails from '@/views/overlays/components/talent-details/details.vue'

export default {
  name: 'talent-tooltip',
  components: { Tooltip, TalentDetails },
  props: {
    position: {
      default: 'top'
    },
    hero: {
      type: Object,
      required: true
    },
    withAttributes: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: 20rem;
}
</style>
