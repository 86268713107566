<template>
  <div :class="$style.wrapper" v-if="playerData">
    <div :class="$style.tab" @click="toggleTopBarHero">
      <div :class="$style.portrait" :style="{ 'background-image': `url(${heroDetails.image})` }">
        <div :class="$style.level">
          <div :class="$style.label">{{ playerData.hero.level }}</div>
          <circular-progress
            :class="$style.progress"
            :value="playerData.hero.xp - prevExpFloor"
            :max="expToNextLevel - prevExpFloor"
          />
        </div>
      </div>
      <div :class="$style.details">
        <div
          :class="$style.attrib"
          :style="{ 'background-image': `url(${primaryAttribImage})` }"
        ></div>
        <span :class="$style.name">{{ heroDetails.name }}</span>
      </div>
    </div>
    <div :class="$style.tooltip" v-if="isSelected">
      <hero-menu :hero="hero" />
    </div>
  </div>
</template>

<script>
import HeroMenu from './menu.vue'
import CircularProgress from '../../overlays/components/common/CircularProgress.vue'

export default {
  name: 'hero-tab',
  components: {
    HeroMenu,
    CircularProgress
  },
  props: ['hero', 'team'],
  computed: {
    prevExpFloor() {
      return this.getXpToLevel(this.playerData.hero.level - 1)
    },
    expToNextLevel() {
      return this.getXpToLevel(this.playerData.hero.level)
    },
    playerData() {
      if (this.$store.getters.playerDataList) {
        return this.$store.getters.playerDataList[this.hero.index]
      }
      return false
    },
    isSelected() {
      return this.$store.getters.selectedTopBarHero === this.hero.index
    },
    noneSelected() {
      return this.$store.getters.selectedTopBarHero === null
    },
    heroDetails() {
      return this.getHeroDetails(this.hero.name)
    },
    primaryAttribImage() {
      switch (this.heroDetails.primary_attr) {
        case 'agi':
          return require('@/assets/imgs/agility.png')
        case 'int':
          return require('@/assets/imgs/inteligence.png')
        case 'str':
          return require('@/assets/imgs/strength.png')
        case 'all':
          return require('@/assets/imgs/all.png')
        default:
          return ''
      }
    }
  },
  methods: {
    toggleTopBarHero() {
      if (this.hero.index === this.$store.getters.selectedTopBarHero) {
        this.$store.commit('SetTopBarHero', null)
      } else {
        this.$store.commit('SetTopBarHero', this.hero.index)
      }
    }
  }
}
</script>

<style lang="scss" module>
.wrapper {
  background-color: #333;

  &:nth-child(odd) {
    background-color: #252525;
  }
}

.tab {
  padding: 2vw;
  display: flex;
  cursor: pointer;
}

.portrait {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1.5vw;
  width: 20.3%;
}

.level {
  position: absolute;
  z-index: 10;
  left: 0.1rem;
  bottom: 0.1rem;
  background: black;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  box-shadow: 0 0 0.2rem $color_black;
}

.label {
  color: #caa170;
  font-family: 'Reaver';
  font-size: 0.7rem;
  text-align: center;
  line-height: 1.58rem;
  text-shadow: 0.1rem 0.1rem 0.2rem $color_red, -0.1rem -0.1rem 0.2rem $color_red,
    -0.1rem 0.1rem 0.2rem $color_red, 0.1rem -0.1rem 0.2rem $color_red;
}

.progress {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.details {
  display: flex;
  align-items: center;
}

.attrib {
  background-repeat: no-repeat;
  background-size: contain;
  margin: 2.5vw;
  width: 7vw;
  height: 7vw;
}

.name {
  color: $color_white;
  font-family: 'Reaver';
  font-weight: bold;
  font-size: 4.5vw;
  letter-spacing: 0.4vw;
  white-space: nowrap;
}
</style>
