<template>
  <tooltip :position="position" :hideControls="hideControls">
    <div :class="[$style.container, $style[size]]">
      <AghanimsDetails :hero="hero" type="scepter" :status="hero.aghanims_scepter" :size="size" />
      <AghanimsDetails :hero="hero" type="shard" :status="hero.aghanims_shard" :size="size" />
      <div :class="$style.stem"></div>
    </div>
  </tooltip>
</template>

<script>
import Tooltip from './tooltip.vue'
import AghanimsDetails from '@/views/overlays/components/aghanims-details/details.vue'

export default {
  name: 'aghanims-tooltip',
  components: { Tooltip, AghanimsDetails },
  props: {
    hero: {
      type: Object,
      required: true
    },
    position: {
      type: String,
      default: 'bottom'
    },
    size: {
      type: String,
      default: 'normal'
    },
    hideControls: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  width: 19.65vw;
  gap: 0.5vw;
  position: relative;

  &.small {
    gap: 0.4vw;
  }
}

.stem {
  position: absolute;
  top: 2vw;
  bottom: 2vw;
  left: 1vw;
  right: 1vw;
  z-index: 0;
  background: rgba($color_black, 0.8);
}
</style>
