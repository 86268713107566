<template>
  <div :class="[$style.container, $style[colorClass]]">
    <img :class="$style.icon" :src="icon" />
  </div>
</template>

<script>
export default {
  name: 'FacetBlock',
  props: {
    facet: {
      type: Object,
      required: true
    }
  },
  computed: {
    colorClass() {
      return `facet_gradient_${this.facet.color}`
    },
    icon() {
      return require(`@/assets/imgs/facets/${this.facet.icon}_png.png`)
    }
  }
}
</script>

<style lang="scss" module>
@import '~@/assets/scss/_facets.scss';

.container {
  width: 1.4vw;
  height: 1.4vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.1vw;
  box-shadow: inset 0 0 0 0.05vw rgba(white, 0.2), 0 0 0.1vw 0.1vw rgba(black, 0.2);
}

.icon {
  width: 60%;
}
</style>
