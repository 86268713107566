<template>
  <div :class="$style.container">
    <div
      :class="$style.category"
      v-for="(category, index) in categories"
      :key="`category-${index}`"
    >
      <div :class="$style.header">
        <div :class="[$style.title, $style[category.type.replace(' ', '_')]]">
          {{ category.type }}
        </div>
        <div :class="$style.time">
          <font-awesome :class="$style.ficon" icon="fa-clock" />{{ category.time }}
        </div>
      </div>
      <div :class="$style.grid">
        <div
          :class="$style.item"
          v-for="(item, iIndex) in category.items"
          :key="`${category.type}-item-${iIndex}`"
        >
          <div :class="$style.imgContainer">
            <img :class="$style.img" :src="getItemDetails(item, 'image')" />
          </div>
          <item-tooltip :item="item" position="left" :delay="300" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemTooltip from '../tooltips/item-tooltip.vue'

export default {
  name: 'neutral-list',
  components: { ItemTooltip },
  props: {
    categories: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.8vw;
  padding: 0.5vw 1.2vw;
}

.category {
  display: flex;
  flex-direction: column;
  gap: 0.2vw;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 0.8vw;
  font-weight: bold;
  text-transform: uppercase;

  &.tier_1 {
    color: #bdbdbd;
  }

  &.tier_2 {
    color: #74b365;
  }

  &.tier_3 {
    color: #7f93fa;
  }

  &.tier_4 {
    color: #c171e7;
  }

  &.tier_5 {
    color: #eed28b;
  }
}

.time {
  display: flex;
  align-items: center;
  color: rgba(white, 0.6);
  font-size: 0.8vw;

  .ficon {
    margin-right: 0.2vw;
    font-size: 0.7vw;
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  gap: 0.2vw;
}

.item {
  position: relative;

  &:hover {
    box-shadow: inset $color_primary 0 0 0.4vw;
    border-radius: 0.2vw;
  }
}

.imgContainer {
  position: relative;
  height: 1.6vw;
  width: 2.2vw;
  border-radius: 0.2vw;
  overflow: hidden;
  box-shadow: inset rgba(black, 0.8) 0 0 0.8vw;
  border: 0.1vw solid black;
  z-index: -1;
}

.img {
  height: calc(100% + 0.1vw);
  position: absolute;
  top: -0.05vw;
  left: 50%;
  transform: translateX(-50%);
}
</style>
