<template>
  <div :class="$style.container" ref="container">
    <svg :class="$style.svg">
      <circle cx="50%" cy="50%" r="50%" :style="`stroke-dasharray: ${circumference}`" />
      <circle
        cx="50%"
        cy="50%"
        r="50%"
        :style="`stroke-dasharray: ${circumference}; stroke-dashoffset: ${percentageArc}`"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'CircularProgress',
  props: {
    value: {
      required: true
    },
    max: {
      default: 100,
      type: Number
    }
  },
  data() {
    return {
      circumference: 0
    }
  },
  computed: {
    percentageArc() {
      return this.circumference - (this.circumference * (this.value * 0.98)) / (this.max * 2)
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.circumference = Math.floor(2 * Math.PI * this.$refs.container.clientHeight)
    })
  }
}
</script>

<style lang="scss" module>
.svg {
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);

  circle {
    transform: scale(0.9) translate(5%, 5%);
    fill: none;
    stroke-width: 7%;
    transition: all ease-in 0.1s;

    &:nth-child(1) {
      stroke-dashoffset: 0;
      stroke: rgba(white, 0.2);
    }

    &:nth-child(2) {
      // filter: drop-shadow(0 0 0.05vw rgba(red, 0.6));
      stroke: #caa170;
      stroke-linecap: round;
    }
  }
}
</style>
