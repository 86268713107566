var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.$style.container,
    ( _obj = {}, _obj[_vm.$style.inactive] = !_vm.active, _obj[_vm.$style.onCooldown] = _vm.cooldown > 0, _obj[_vm.$style.noMana] = _vm.nomana, _obj )
  ]},[_c('img',{class:_vm.$style.image,attrs:{"src":_vm.abilityImage}}),(_vm.charges >= 0)?_c('div',{class:[_vm.$style.charges, ( _obj$1 = {}, _obj$1[_vm.$style.onCooldown] = _vm.cooldown > 0, _obj$1[_vm.$style.noMana] = _vm.nomana, _obj$1 )]},[_vm._v(" "+_vm._s(_vm.charges)+" ")]):_vm._e(),(_vm.cooldown > 0)?[_c('div',{class:_vm.$style.cooldown},[_vm._v(_vm._s(_vm.cooldown === 99999 ? '??' : _vm.cooldown))])]:_vm._e(),(_vm.nomana)?[_c('div',{class:_vm.$style.nomana})]:_vm._e(),_c('ul',{class:_vm.$style.level},_vm._l((_vm.maxLevel),function(lv){
  var _obj;
return _c('li',{key:((_vm.ability.name) + "-lvl-" + lv),class:[_vm.$style.notch, ( _obj = {}, _obj[_vm.$style.active] = lv <= _vm.ability.level, _obj )]})}),0),_c('ability-tooltip',{attrs:{"ability":_vm.ability,"position":"bottom","aghanims":_vm.aghanims,"hideControls":true}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }