var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.playerData && _vm.playerData.inventory)?_c('div',{class:_vm.$style.container},[_c('div',{class:_vm.$style.inventory},[_c('div',{class:_vm.$style.main},[_vm._l((_vm.playerData.inventory.main),function(item,index){
var _obj;
return [_c('div',{key:("main-item-" + index),class:[
              _vm.$style.item,
              ( _obj = {}, _obj[_vm.$style.empty] = item === 'empty', _obj[_vm.$style.selected] = item !== 'empty' && _vm.isSelected('main', index), _obj )
            ],on:{"click":function($event){return _vm.toggleItem('main', index, item === 'empty')}}},[_c('div',{class:_vm.$style.imgBox},[_c('img',{class:_vm.$style.img,attrs:{"src":_vm.itemImage(item)}})])])]})],2),_c('div',{class:[
          _vm.$style.neutral,
          _vm.$style.item,
          ( _obj = {}, _obj[_vm.$style.empty] = _vm.playerData.inventory.neutral === 'empty', _obj[_vm.$style.selected] = _vm.playerData.inventory.neutral !== 'empty' && _vm.isSelected('neutral', 0), _obj )
        ],on:{"click":function($event){return _vm.toggleItem('neutral', 0, _vm.playerData.inventory.neutral === 'empty')}}},[_c('div',{class:_vm.$style.imgBox},[_c('img',{class:_vm.$style.img,attrs:{"src":_vm.itemImage(_vm.playerData.inventory.neutral)}})])])]),_c('div',{class:_vm.$style.backpack},[_vm._l((_vm.playerData.inventory.backpack),function(item,index){
        var _obj;
return [_c('div',{key:("backpack-item-" + index),class:[
            _vm.$style.item,
            ( _obj = {}, _obj[_vm.$style.empty] = item === 'empty', _obj[_vm.$style.selected] = item !== 'empty' && _vm.isSelected('backpack', index), _obj )
          ],on:{"click":function($event){return _vm.toggleItem('backpack', index, item === 'empty')}}},[_c('div',{class:_vm.$style.imgBox},[_c('img',{class:_vm.$style.img,attrs:{"src":_vm.itemImage(item)}})])])]})],2)]):_vm._e(),(_vm.selectedItem !== null)?_c('div',{class:_vm.$style.details},[_c('div',{class:_vm.$style.header},[_c('div',{class:_vm.$style.name},[_vm._v(_vm._s(_vm.itemDetails(_vm.selectedItemName).name))])]),_c('item-details',{attrs:{"item":_vm.selectedItemName,"item-details":_vm.itemDetails(_vm.selectedItemName)}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }