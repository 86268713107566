<template>
  <div :class="[$style.container, $style[size]]">
    <img
      :class="[$style.on, $style.icon]"
      v-if="hero.aghanims_scepter"
      src="@/assets/imgs/aghanims/scepter-on.png"
    />
    <img :class="$style.icon" v-else src="@/assets/imgs/aghanims/scepter-off.png" />
    <img
      :class="[$style.on, $style.icon]"
      v-if="hero.aghanims_shard"
      src="@/assets/imgs/aghanims/shard-on.png"
    />
    <img :class="$style.icon" v-else src="@/assets/imgs/aghanims/shard-off.png" />
    <aghanims-tooltip :hero="hero" :position="position" :size="size" />
  </div>
</template>

<script>
import AghanimsTooltip from '../tooltips/aghanims-tooltip.vue'

export default {
  name: 'hero-aghanims',
  components: { AghanimsTooltip },
  props: {
    hero: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: 'normal'
    },
    position: {
      type: String,
      default: 'bottom'
    }
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 2vw;
  position: relative;

  .on {
    animation: glow ease-in-out 2s infinite;
  }

  .icon {
    width: 100%;
  }

  &.small {
    width: 1.4vw;
  }
}

@keyframes glow {
  0% {
    filter: drop-shadow(0 0 0.05vw $color_fadewhite);
  }
  50% {
    filter: drop-shadow(0 0 0.2vw rgba(#00b3ff, 0.9));
  }
  100% {
    filter: drop-shadow(0 0 0.05vw $color_fadewhite);
  }
}
</style>
