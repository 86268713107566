<template>
  <div :class="$style.container">
    <facet-block :facet="facet" :class="$style.facet" />
    <img :class="$style.innate" src="@/assets/imgs/innate.png" />
    <innate-facet-tooltip :hero="hero.name" :selectedFacet="hero.facet" position="bottom" />
  </div>
</template>

<script>
import FacetBlock from '../common/facet-block.vue'
import InnateFacetTooltip from '../tooltips/innate-facet-tooltip.vue'

export default {
  name: 'hero-innate-facet',
  components: { FacetBlock, InnateFacetTooltip },
  props: {
    hero: {
      type: Object,
      required: true
    }
  },
  computed: {
    facet() {
      return this.getFacetData(this.hero.name, this.hero.facet)
    }
  }
}
</script>

<style lang="scss" module>
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.2vw;
  margin-bottom: -0.1vw;
}

.innate {
  width: 1.5vw;
  height: 1.5vw;
}

.facet {
  transform: scale(0.9);
}
</style>
