<template>
  <div
    :class="[$style.container, { [$style.noPadding]: noPadding }]"
    v-if="withAttributes || withCooldown || withManaCost || withHealthCost || withCastRange"
  >
    <ul :class="$style.attributes" v-if="withAttributes">
      <li
        :class="$style.attribute"
        v-for="(attribute, index) in filteredAttributes"
        :key="`${ability.name}-attr-${index}`"
      >
        <template v-if="Array.isArray(attribute.value)">
          <span :class="$style.header" v-html="attribute.header"></span>
          <template v-for="(value, vi) in attribute.value">
            <span v-if="vi > 0" :key="`${attribute.key}-${vi}-slash`"> / </span>
            <span
              :class="[
                { [$style[damageType]]: isDamage(attribute.key) },
                {
                  [$style.value]:
                    vi + 1 === getAbilityLevel(ability, attribute.header) || highlightAll
                }
              ]"
              :key="`${attribute.key}-${vi}-value`"
              >{{ value }}</span
            >
          </template>
        </template>
        <template v-if="!Array.isArray(attribute.value)">
          <span :class="$style.header" v-html="attribute.header"></span>
          <span
            :class="[
              { [$style[damageType]]: isDamage(attribute.key) },
              { [$style.value]: getAbilityLevel(ability, attribute.header) > 0 || highlightAll }
            ]"
            >{{ attribute.value }}</span
          >
        </template>
      </li>
    </ul>
    <div
      :class="$style.manaCd"
      v-if="withCooldown || withManaCost || withHealthCost || withCastRange"
    >
      <div :class="$style.cooldown" v-if="withCooldown">
        <img :class="$style.icon" src="@/assets/imgs/cd.png" />
        <div>
          <template v-if="Array.isArray(abilityDetails.cooldown)">
            <template v-for="(value, vi) in abilityDetails.cooldown">
              <span v-if="vi > 0" :key="`${ability.name}-cd-${vi}-slash`"> / </span>
              <span
                :class="{ [$style.value]: vi + 1 === ability.level || highlightAll }"
                :key="`${ability.name}-cd-${vi}-value`"
                >{{ value }}</span
              >
            </template>
          </template>
          <template v-else>
            <span :class="{ [$style.value]: ability.level > 0 || highlightAll }">{{
              abilityDetails.cooldown
            }}</span>
          </template>
        </div>
      </div>
      <div :class="$style.manacost" v-if="withManaCost">
        <img :class="$style.icon" src="@/assets/imgs/mana.png" />
        <div>
          <template v-if="Array.isArray(abilityDetails.mana_cost)">
            <template v-for="(value, vi) in abilityDetails.mana_cost">
              <span v-if="vi > 0" :key="`${ability.name}-mana-${vi}-slash`"> / </span>
              <span
                :class="{ [$style.value]: vi + 1 === ability.level || highlightAll }"
                :key="`${ability.name}-mana-${vi}-value`"
                >{{ value }}</span
              >
            </template>
          </template>
          <template v-else>
            <span :class="{ [$style.value]: ability.level > 0 }">{{
              abilityDetails.mana_cost
            }}</span>
          </template>
        </div>
      </div>
      <div :class="$style.healthcost" v-if="withHealthCost">
        <img :class="$style.icon" src="@/assets/imgs/health.png" />
        <div>
          <template v-if="Array.isArray(abilityDetails.health_cost)">
            <template v-for="(value, vi) in abilityDetails.health_cost">
              <span v-if="vi > 0" :key="`${ability.name}-health-${vi}-slash`"> / </span>
              <span
                :class="{ [$style.value]: vi + 1 === ability.level || highlightAll }"
                :key="`${ability.name}-health-${vi}-value`"
                >{{ value }}</span
              >
            </template>
          </template>
          <template v-else>
            <span :class="{ [$style.value]: ability.level > 0 }">{{
              abilityDetails.health_cost
            }}</span>
          </template>
        </div>
      </div>
      <div :class="$style.castrange" v-if="withCastRange">
        <img :class="$style.icon" src="@/assets/imgs/cast_range.png" />
        <div>
          <template v-if="Array.isArray(abilityDetails.cast_range)">
            <template v-for="(value, vi) in abilityDetails.cast_range">
              <span v-if="vi > 0" :key="`${ability.name}-castrange-${vi}-slash`"> / </span>
              <span
                :class="{ [$style.value]: vi + 1 === ability.level || highlightAll }"
                :key="`${ability.name}-castrange-${vi}-value`"
                >{{ value }}</span
              >
            </template>
          </template>
          <template v-else>
            <span :class="{ [$style.value]: ability.level > 0 }">{{
              abilityDetails.cast_range
            }}</span>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ability-attributes',
  props: [
    'ability',
    'abilityDetails',
    'scepterOnly',
    'shardOnly',
    'noPadding',
    'noManaCd',
    'highlightAll'
  ],
  methods: {
    isDamage(header) {
      return header.includes('damage') && !header.includes('absorb')
    },
    getAbilityLevel(ability, header) {
      if (ability.quas && header.includes('QUAS')) {
        return ability.quas
      } else if (ability.wex && header.includes('WEX')) {
        return ability.wex
      } else if (ability.exort && header.includes('EXORT')) {
        return ability.exort
      } else {
        return ability.level
      }
    }
  },
  computed: {
    damageType() {
      return this.abilityDetails.damage_type.toLowerCase()
    },
    abilityName() {
      return typeof this.ability === 'string' ? this.ability : this.ability.name
    },
    filteredAttributes() {
      const filterValues = ['abilitymanacost', 'abilitycooldown']
      const attributes = this.abilityDetails.attributes.filter((attr) => {
        const shardPrefix = this.abilityName === 'tusk_ice_shards' ? 'aghs_shard' : 'shard'
        if (this.scepterOnly && attr.key.includes('scepter')) {
          return true
        } else if (this.shardOnly && attr.key.includes(shardPrefix)) {
          return true
        } else if (!this.scepterOnly && !this.shardOnly) {
          return (
            !attr.generated &&
            !filterValues.includes(attr.key) &&
            !attr.key.includes('scepter') &&
            !attr.key.includes(shardPrefix)
          )
        }
        return false
      })

      if (
        ((this.abilityDetails.scepter_ability && this.scepterOnly) ||
          (this.abilityDetails.shard_ability && this.shardOnly) ||
          (!this.scepterOnly && !this.shardOnly)) &&
        this.abilityDetails.damage
      ) {
        attributes.push({
          key: 'damage',
          header: 'DAMAGE:',
          value: this.abilityDetails.damage
        })
      }

      return attributes
    },
    withAttributes() {
      return this.filteredAttributes.length > 0
    },
    withManaCost() {
      return (
        !this.noManaCd && this.abilityDetails.mana_cost && this.abilityDetails.mana_cost !== '0'
      )
    },
    withHealthCost() {
      return (
        !this.noManaCd && this.abilityDetails.health_cost && this.abilityDetails.health_cost !== '0'
      )
    },
    withCooldown() {
      return !this.noManaCd && this.abilityDetails.cooldown && this.abilityDetails.cooldown !== '0'
    },
    withCastRange() {
      return (
        !this.noManaCd && this.abilityDetails.cast_range && this.abilityDetails.cast_range !== '0'
      )
    }
  }
}
</script>

<style lang="scss" module>
.container {
  color: $color_lightbluishgrey;
  font-size: 0.7rem;
  letter-spacing: 0 !important;
  padding: 0.4rem 0.2rem 0.4rem 0.4rem;

  &.noPadding {
    padding: 0.4rem 0 0 0;
  }
}

.attributes {
  list-style: none;
}

.attribute {
  margin-bottom: 0.1rem;
}

.manaCd {
  display: flex;
  flex-wrap: wrap;
  gap: 0.05rem 0.5rem;
}

.cooldown,
.manacost,
.healthcost,
.castrange {
  display: flex;
  align-items: center;
  gap: 0.05rem;
}

.icon {
  width: 1rem;
  margin-right: 0.2rem;
}

.header {
  color: $color_lightbluishgrey;
  margin-right: 0.2rem;
}

span {
  color: $color_dark_bluishgrey;
}

.value {
  color: $color_white;
  font-weight: bold;
  font-size: 0.75rem;
}

.physical {
  color: $color_red;

  &.value {
    color: $color_highlight_red;
  }
}

.magical {
  color: $color_blue;

  &.value {
    color: $color_highlight_blue;
  }
}

.pure {
  color: $color_yellow;

  &.value {
    color: $color_highlight_yellow;
  }
}
</style>
