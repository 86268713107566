<template>
  <div :class="$style.container">
    <div :class="$style.team">
      <div
        :class="[$style.bar, { [$style.max]: isMax(value) }]"
        v-for="(value, index) in radiantStats"
        :key="`radiant-stats-${index}`"
        :style="`height: ${getPercentage(value)}`"
      >
        <div :class="$style.label">{{ getValueLabel(value) }}</div>
      </div>
    </div>
    <div :class="$style.content"></div>
    <div :class="$style.team">
      <div
        :class="[$style.bar, { [$style.max]: isMax(value) }]"
        v-for="(value, index) in direStats"
        :key="`radiant-stats-${index}`"
        :style="`height: ${getPercentage(value)}`"
      >
        <div :class="$style.label">{{ getValueLabel(value) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'stats-graph',
  data() {
    return {
      intro: true,
      defaultMax: {
        net_worth: 8000,
        gpm: 800,
        xpm: 600,
        hero_healing: 5000,
        hero_damage: 10000,
        building_damage: 5000,
        camps_stacked: 10
      }
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    getPercentage(value) {
      if (this.intro) {
        return '0%'
      }

      if (this.data.value === 'kill_participation') {
        return value
      }

      return `${(value / this.maxValue) * 100}%`
    },
    getValueLabel(value) {
      if (this.intro) {
        return ''
      }

      if (['kill_participation', 'camps_stacked'].includes(this.data.value)) {
        return value
      }

      if (value < 1000) {
        return value
      }

      return this.kFormat(value)
    },
    isMax(value) {
      if (this.data.value === 'kill_participation') {
        return false
      }
      return value === this.maxValue
    },
    kFormat(num) {
      const numString = (num / 1000).toString()
      return numString.slice(0, numString.indexOf('.') + 2) + 'k'
    }
  },
  computed: {
    stats() {
      return this.playerStats.map((data) => {
        return data[this.data.value]
      })
    },
    radiantStats() {
      return this.stats.slice(0, 5)
    },
    direStats() {
      return this.stats.slice(5)
    },
    maxValue() {
      return Math.max(...this.stats, this.defaultMax[this.data.value])
    },
    playerStats() {
      return this.$store.getters.playerStats
    },
    mouseOver() {
      return this.$store.getters.mouseOver
    }
  },
  mounted() {
    setTimeout(() => {
      this.intro = false
    }, 100)
  },
  beforeUnmount() {
    this.intro = true
  }
}
</script>

<style lang="scss" module>
.container {
  position: absolute;
  left: 28.7vw;
  top: 3.6vw;
  width: 42.7vw;
  height: 10vw;
  display: flex;
  align-items: flex-start;
  z-index: 50;
}

.team {
  flex: 1;
  height: 100%;
  display: flex;
}

.bar {
  width: 100%;
  min-height: 1%;
  background: linear-gradient(
    180deg,
    rgba($color_primary, 0.9) 60%,
    rgba($color_secondary, 0.8) 100%
  );
  box-shadow: 0 0 0.2vw $color_black;
  border-radius: 0.1vw 0.1vw 0.2vw 0.2vw;
  margin: 0 0.4vw;
  transition: height ease 0.5s;
  position: relative;
}

.label {
  position: absolute;
  width: 100%;
  color: $color_white;
  font-size: 0.6vw;
  text-align: center;
  font-weight: bold;
  text-shadow: 0 0 0.1vw $color_black, 0 0 0.1vw $color_black, 0 0 0.1vw $color_black;
  bottom: -0.8vw;
}

.content {
  margin-top: 0;
  width: 10.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
