<template>
  <div v-if="withData" :class="$style.container">
    <ul :class="$style.behavior">
      <li v-if="itemDetails.behavior !== undefined">ABILITY: {{ itemDetails.behavior }}</li>
      <li v-if="itemDetails.affects !== undefined">AFFECTS: {{ itemDetails.affects }}</li>
      <li v-if="itemDetails.damage_type !== 'None'">
        DAMAGE TYPE:
        <strong :class="$style[itemDetails.damage_type.toLowerCase()]">{{
          itemDetails.damage_type
        }}</strong>
      </li>
      <li v-if="itemDetails.bkb_piercing !== undefined">
        PIERCES SPELL IMMUNITY:
        <template v-if="itemDetails.bkb_piercing">
          <strong :class="$style.yes">Yes</strong>
        </template>
        <template v-else>No</template>
      </li>
      <li v-if="itemDetails.dispellable !== undefined">
        DISPELLABLE:
        <template v-if="itemDetails.dispellable === 'No'">
          <strong :class="$style.no">Cannot be dispelled</strong>
        </template>
        <template v-else-if="itemDetails.dispellable === 'Strong'">
          <strong :class="$style.no">Strong Dispels Only</strong>
        </template>
        <template v-else>Yes</template>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'item-behavior',
  props: ['itemDetails'],
  computed: {
    withData() {
      let counter = 0

      if (this.itemDetails.behavior !== undefined && this.itemDetails.behavior !== 'Passive') {
        counter++
      }

      if (this.itemDetails.damage_type !== 'None') {
        counter++
      }

      if (this.itemDetails.bkb_piercing !== undefined) {
        counter++
      }

      if (this.itemDetails.dispellable !== undefined) {
        counter++
      }

      if (this.itemDetails.affects !== undefined) {
        counter++
      }

      return counter > 0
    }
  }
}
</script>

<style lang="scss" module>
.container {
  position: relative;
  color: $color_lightbluishgrey;
  font-size: 0.8rem;
  padding: 0 0.4rem 0.2rem 0.4rem;
  border-bottom: 0.1rem solid rgba($color_white, 0.1);
}

.behavior {
  list-style: none;

  li {
    margin-bottom: 0.2rem;
  }

  .magical {
    color: $color_lightblue;
  }

  .physical {
    color: $color_lightred;
  }

  .pure {
    color: $color_yellow;
  }

  .yes {
    color: $color_green;
  }

  .no {
    color: $color_red;
  }
}
</style>
