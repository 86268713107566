<template>
  <div :class="$style.container">
    <div
      :class="$style.mouseArea"
      @mouseenter="mouseIn"
      @mouseleave="mouseOut"
      @click="toggle"
    ></div>
    <transition :name="transition">
      <div v-if="isActive" :class="$style.wrapper">
        <div :class="[$style.arrow, $style[position]]" v-if="arrow"></div>
        <div
          :class="[$style.tooltip, $style[position], { [$style.noMouse]: disableMouse }]"
          :style="positionCss"
          ref="tooltip"
        >
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'tooltip',
  data() {
    return {
      isActive: false,
      timeout: null,
      x: 0,
      y: 0,
      width: 0,
      height: 0
    }
  },
  props: {
    arrow: {
      type: Boolean,
      default: true
    },
    click: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'top'
    },
    delay: {
      type: Number,
      default: 100
    },
    disableMouse: {
      type: Boolean,
      default: true
    },
    hideControls: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    transition() {
      switch (this.position) {
        case 'right':
          return 'fade-left-tooltip'
        case 'left':
          return 'fade-right-tooltip'
        case 'lower-left':
          return 'fade-lower-right-tooltip'
        case 'lower-right':
          return 'fade-lower-left-tooltip'
        default:
          return 'fade-down'
      }
    },
    positionCss() {
      if (!['top', 'bottom'].includes(this.position)) {
        if (this.y < 0) {
          return `transform: translateY(calc(-30% - ${this.y}px + 20px));`
        }

        const lowerY = this.y + this.height
        if (lowerY > window.innerHeight) {
          const diff = lowerY - window.innerHeight
          return `transform: translateY(calc(-30% - ${diff}px - 20px));`
        }
      } else {
        if (this.x < 0) {
          return `transform: translateX(calc(-50% - ${this.x}px + 20px));`
        }

        const rightX = this.x + this.width
        if (rightX > window.innerWidth) {
          const diff = rightX - window.innerWidth
          return `transform: translateX(calc(-50% - ${diff}px - 20px));`
        }
      }

      return ''
    }
  },
  methods: {
    mouseIn() {
      if (!this.click) {
        // Force a delay before activating the tooltip
        this.timeout = setTimeout(() => {
          this.isActive = true
        }, this.delay)

        if (this.hideControls) {
          this.$store.commit('ToggleHideControls', true)
        }
      }
    },
    mouseOut() {
      if (!this.click) {
        clearTimeout(this.timeout)
        this.isActive = false
      }

      if (this.hideControls) {
        this.$store.commit('ToggleHideControls', false)
      }
    },
    toggle() {
      if (this.click) {
        if (this.isActive) {
          clearTimeout(this.timeout)
          this.isActive = false
        } else {
          // Force a delay before activating the tooltip
          this.timeout = setTimeout(() => {
            this.isActive = true
          }, this.delay)
        }
      }
    }
  },
  watch: {
    isActive: function (newValue, _) {
      if (newValue === true && this.positionCss === '') {
        setTimeout(() => {
          const rect = this.$refs.tooltip.getBoundingClientRect()
          this.x = rect.x
          this.y = rect.y
          this.width = rect.width
          this.height = rect.height
        }, 100)
      }
    }
  }
}
</script>

<style lang="scss" module>
.container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;
}

.mouseArea {
  position: absolute;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.arrow {
  position: absolute;
  width: 1vw;
  height: 1vw;
  z-index: 220;
  border: 0.2vw solid $color_primary;
  background: rgba(#162024, 0.95);
  clip-path: polygon(0 0, 0 100%, 100% 100%);

  &.top {
    top: -1.1vw;
    left: 50%;
    transform: translateX(-50%) rotate(-45deg);
  }

  &.bottom {
    bottom: -1.1vw;
    left: 50%;
    transform: translateX(-50%) rotate(135deg);
  }

  &.right {
    top: 50%;
    right: -1.1vw;
    transform: translateY(-50%) rotate(45deg);
  }

  &.lower-right {
    top: 50%;
    right: -1.1vw;
    transform: translateY(-50%) rotate(45deg);
  }

  &.left {
    top: 50%;
    left: -1.1vw;
    transform: translateY(-50%) rotate(-135deg);
  }

  &.lower-left {
    top: 50%;
    left: -1.1vw;
    transform: translateY(-50%) rotate(-135deg);
  }
}

.tooltip {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 210;
  transition: all ease 0.5s;

  &.noMouse {
    pointer-events: none;
  }

  &.top {
    bottom: calc(100% + 0.43vw);
  }

  &.bottom {
    top: calc(100% + 0.43vw);
  }

  &.right {
    transform: translateY(-30%);
    left: calc(100% + 0.5vw);
    top: 50%;
  }

  &.lower-right {
    transform: translateY(-70%);
    left: calc(100% + 0.5vw);
    top: 50%;
  }

  &.left {
    transform: translateY(-30%);
    left: auto;
    right: calc(100% + 0.5vw);
    top: 50%;
  }

  &.lower-left {
    transform: translateY(-70%);
    left: auto;
    right: calc(100% + 0.5vw);
    top: 50%;
  }
}
</style>
