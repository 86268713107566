<template>
  <tooltip
    :position="position"
    v-if="item !== 'empty'"
    :disableMouse="disableMouse"
    :delay="delay"
    :hideControls="hideControls"
  >
    <div :class="$style.container">
      <div :class="$style.header">
        <img :class="$style.image" :src="itemDetails.image" />
        <div :class="$style.labels">
          <div
            :class="[
              $style.name,
              {
                [$style.long]: itemDetails.name.length >= 18,
                [$style.xlong]: itemDetails.name.length >= 24
              }
            ]"
          >
            {{ itemDetails.name }}
          </div>
          <div :class="$style.price" v-show="itemDetails.cost > 0">
            <img :class="$style.gold" src="@/assets/imgs/gold.png" />
            <div :class="$style.cost">{{ itemDetails.cost }}</div>
          </div>
          <div :class="$style.neutral" v-if="itemDetails.is_neutral">
            <span :class="$style[getItemTier(itemDetails.neutral_tier)]">
              Tier {{ itemDetails.neutral_tier }}
            </span>
            <span :class="$style.label">Neutral Item</span>
          </div>
        </div>
      </div>
      <div :class="$style.details">
        <item-details :itemDetails="itemDetails" />
      </div>
    </div>
  </tooltip>
</template>

<script>
import Tooltip from './tooltip.vue'
import ItemDetails from '@/views/overlays/components/item-details/details.vue'

export default {
  name: 'item-tooltip',
  components: { Tooltip, ItemDetails },
  props: {
    item: {
      type: String,
      required: true
    },
    position: {
      type: String,
      required: true
    },
    disableMouse: {
      type: Boolean,
      default: true
    },
    transition: {
      type: String,
      default: 'fade-down'
    },
    delay: {
      type: Number,
      default: 100
    },
    hideControls: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    itemDetails() {
      return this.getItemDetails(this.item.replace('item_', ''))
    }
  },
  methods: {
    getItemTier(tier) {
      return `tier_${tier}`
    }
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  width: 19.65vw;
  border-radius: 0.1vw;
  border: 0.15vw solid rgba($color_black, 0.5);
  background: #1b1c26 url('~@/assets/imgs/items/tooltip-bg.png') center top no-repeat !important;
  background-size: 130% !important;
  box-shadow: 0 0.05vw 0.1vw rgba($color_black, 0.5);
}

.header {
  min-height: 3.7vw;
  display: flex;
  align-items: flex-start;
  padding: 0.6vw 0.4vw;
  gap: 0.6vw;
}

.image {
  height: 2.5vw;
  border-radius: 0.2vw;
  box-shadow: 0 0 0.3vw $color_black;
}

.labels {
  display: flex;
  flex-direction: column;
}

.name {
  flex: 1;
  font-family: 'Reaver';
  color: $color_white;
  font-size: 1vw;
  font-weight: bold;
  text-transform: uppercase;

  &.long {
    font-size: 0.8vw;
  }

  &.xlong {
    font-size: 0.75vw;
  }
}

.price {
  display: flex;
  align-items: center;
  gap: 0.4vw;
}

.neutral {
  display: flex;
  align-items: center;
  gap: 0.2vw;
  font-size: 0.8vw;
  font-weight: bold;

  .label {
    color: rgba(white, 0.5);
  }

  .tier_1 {
    color: #bdbdbd;
  }

  .tier_2 {
    color: #74b365;
  }

  .tier_3 {
    color: #7f93fa;
  }

  .tier_4 {
    color: #c171e7;
  }

  .tier_5 {
    color: #eed28b;
  }
}

.gold {
  width: 1.2vw;
  height: 1.2vw;
}

.cost {
  font-family: 'Reaver';
  color: gold;
  font-size: 0.7vw;
  font-weight: bold;
  text-transform: uppercase;
}
</style>
