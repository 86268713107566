<template>
  <div :class="$style.container">
    <div :class="$style.abilities">
      <template v-for="(ability, index) in abilities">
        <div
          :class="[$style.ability, { [$style.selected]: index === selectedAbility }]"
          :key="`${ability.name}-${index}`"
          v-if="!ability.skip"
          @click="toggleAbility(index)"
        >
          <div>
            <img :class="$style.abilityImg" :src="getAbilityDetails(ability.name, 'image')" />
          </div>
        </div>
      </template>
    </div>
    <template v-for="(ability, index) in abilities">
      <div
        :class="$style.details"
        :key="`${ability.name}-details-${index}`"
        v-if="!ability.skip && index === selectedAbility"
      >
        <div :class="$style.header">
          <div :class="$style.name">{{ getAbilityDetails(ability.name, 'name') }}</div>
          <div :class="$style.level">Level {{ ability.level }}</div>
        </div>
        <ability-details
          :ability="ability"
          :ability-details="getAbilityDetails(ability.name)"
          :aghanims="aghanims"
        />
      </div>
    </template>
  </div>
</template>

<script>
import AbilityDetails from '@/views/overlays/components/ability-details/details.vue'

export default {
  name: 'hero-abilities',
  props: ['abilities', 'heroName', 'aghanims'],
  components: {
    AbilityDetails
  },
  methods: {
    toggleAbility(index) {
      if (index !== this.$store.getters.selectedHeroAbility) {
        this.$store.commit('SetSelectedHeroAbility', index)
      }
    }
  },
  computed: {
    selectedAbility() {
      return this.$store.getters.selectedHeroAbility
    }
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
}

.abilities {
  display: flex;
  gap: 2vw;
  margin-top: 2vw;
  justify-content: center;
  align-items: center;
}

.ability {
  width: 12vw;
  height: 12vw;
  position: relative;
  transition: all ease 0.5s;
  border-radius: 2vw;
  box-shadow: 0 1vw 2vw rgba($color_black, 0.8);
  overflow: hidden;
  cursor: pointer;

  .abilityImg {
    width: 100%;
    height: 100%;
  }

  &.selected {
    border: 0.5vw solid $color_primary;
    box-shadow: 0 0 2vw $color_primary;
  }
}

.icon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  opacity: 0;
  align-items: center;
  justify-content: center;
  transition: opacity ease-in-out 0.4s;
  font-size: 1.5vw;
  color: rgba($color_white, 0.8);
}

.details {
  margin-top: 2vw;
  padding: 2vw;
  border-radius: 2vw;
  background: #10171d;
  box-shadow: 0 0 1vw rgba($color_primary, 0.5);
}

.header {
  height: 2.35rem;
  border-bottom: 0.1rem solid rgba($color_white, 0.1);
  display: flex;
  justify-content: space-between;
  padding: 0 0.4rem;
  margin-bottom: 0.4rem;
}

.name {
  font-family: 'Reaver';
  color: $color_white;
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 2.55rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 15rem;
}

.level {
  font-family: 'Reaver';
  color: $color_white;
  font-size: 0.7rem;
  line-height: 2.55rem;
}
</style>
