<template>
  <tooltip :position="position" :hideControls="hideControls">
    <div :class="$style.container">
      <template v-if="!innateOnly">
        <basic-facet v-for="facet in facetList" :facet="facet" :key="`${hero}-facet-${facet.id}`" />
      </template>
      <innate :innate="innate" :hidden="isInnateHidden" />
    </div>
  </tooltip>
</template>

<script>
import Tooltip from './tooltip.vue'
import BasicFacet from '../innate-facet-details/basic-facet.vue'
import Innate from '../innate-facet-details/innate.vue'

export default {
  name: 'innate-facet-tooltip',
  components: { Tooltip, BasicFacet, Innate },
  props: {
    hero: {
      type: String,
      required: true
    },
    selectedFacet: {
      type: Number,
      default: -1
    },
    position: {
      type: String,
      default: 'top'
    },
    preview: {
      type: Boolean,
      default: false
    },
    hideControls: {
      type: Boolean,
      default: false
    },
    innateOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    facetList() {
      let facets = this.getHeroFacets(this.hero)

      if (this.selectedFacet > 0) {
        facets = facets.filter((facet) => facet.id === this.selectedFacet)
      }

      return facets
    },
    innate() {
      return this.getHeroAbilities(this.hero, 'innate')
    },
    abilities() {
      return this.getHeroAbilities(this.hero, 'abilities')
    },
    isInnateHidden() {
      const innateAbility = this.abilities.find((ability) => ability.name === this.innate)
      if (innateAbility) {
        return innateAbility.hidden
      }

      return true
    }
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  width: 20.65vw;
  gap: 0.2vw;
}
</style>
