import SSEClient from './client'

export function install(Vue, config) {
  Vue.$sse = Vue.prototype.$sse = new SSEManager(config)

  Vue.mixin({
    mounted() {
      if (this.$options.sse) {
        // Create Event Handlers
        let events = null
        if (this.$options.sse.events) {
          events = {}
          this.$options.sse.events.forEach((event) => {
            // Base Data
            events[event] = (message) => {
              if (message.data !== 'undefined') {
                this.$store.dispatch('commitDelay', {
                  mutation: this.$sse.config.vuexPrefix + event,
                  data: JSON.parse(message.data),
                  update: false
                })
              }
            }
            // Update Data
            events[`${event}-update`] = (message) => {
              const eventName = event.replace('-update', '')
              if (message.data !== 'undefined') {
                this.$store.dispatch('commitDelay', {
                  mutation: this.$sse.config.vuexPrefix + eventName,
                  data: JSON.parse(message.data),
                  update: true
                })
              }
            }
          })
        } else {
          events = (message) => {
            if (message.data !== 'undefined' && message.data !== '"null"') {
              // Base Data
              this.$store.dispatch('commitDelay', {
                mutation: this.$sse.config.vuexPrefix + this.$options.sse.name,
                data: JSON.parse(message.data),
                update: false
              })
              // Update Data
              const eventName = this.$options.sse.name.replace('-update', '')
              this.$store.dispatch('commitDelay', {
                mutation: this.$sse.config.vuexPrefix + eventName,
                data: JSON.parse(message.data),
                update: true
              })
            }
          }
        }

        // Parse Endpoint Params
        const paramRegex = /({param:)(\w+)(})/i
        const endpoint = this.$options.sse.endpoint.replace(
          paramRegex,
          (match, p1, p2, p3, offset, string) => {
            return this[p2]
          }
        )

        // Create SSE Instance
        this.$sse.create({
          id: this._uid,
          store: this.$store,
          ...this.$options.sse,
          endpoint: endpoint,
          channel: this.channelId,
          events
        })
      }
    },
    beforeDestroy() {
      if (this.$options.sse) {
        this.$sse.destroy(this._uid)
      }
    }
  })
}

export class SSEManager {
  constructor(config) {
    this.config = { ...config }
    this.clients = []
  }

  create(options) {
    const client = new SSEClient({ ...this.config, ...options })
    this.clients.push(client)
    client.connect()
  }

  destroy(sseClientId) {
    const clientIndex = this.clients.findIndex((client) => {
      return client.id === sseClientId
    })

    if (clientIndex >= 0) {
      this.clients[clientIndex].disconnect()
      this.clients.splice(clientIndex, 1)
    } else {
      console.log(`SSE Manager: Failed to destroy client ${sseClientId}`)
    }
  }
}

export default SSEManager
