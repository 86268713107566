<template>
  <div :class="$style.container">
    <img
      :class="[$style.on, $style.icon]"
      v-if="aghanims.scepter"
      src="@/assets/imgs/aghanims/scepter-on.png"
    />
    <img :class="[$style.off, $style.icon]" v-else src="@/assets/imgs/aghanims/scepter-off.png" />
    <img
      :class="[$style.on, $style.icon]"
      v-if="aghanims.shard"
      src="@/assets/imgs/aghanims/shard-on.png"
    />
    <img :class="[$style.off, $style.icon]" v-else src="@/assets/imgs/aghanims/shard-off.png" />
    <aghanims-tooltip :hero="data.hero" position="bottom" size="normal" :hideControls="true" />
  </div>
</template>

<script>
import AghanimsTooltip from '../../tooltips/aghanims-tooltip.vue'

export default {
  name: 'aghanims',
  components: { AghanimsTooltip },
  props: ['data'],
  computed: {
    aghanims() {
      return {
        scepter: this.data.hero.aghanims_scepter,
        shard: this.data.hero.aghanims_shard
      }
    }
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 2.4vw;
  position: relative;
  padding: 0.1vw 0.2vw;
  margin-bottom: -0.1vw;

  .on {
    animation: glow ease-in-out 2s infinite;
  }

  .off {
    filter: drop-shadow(0 0 0.1vw black) drop-shadow(0 0 0.05vw black);
  }

  .icon {
    width: 100%;
  }

  &.small {
    width: 1.4vw;
  }
}

@keyframes glow {
  0% {
    filter: drop-shadow(0 0 0.05vw black) drop-shadow(0 0 0.05vw $color_fadewhite);
  }
  50% {
    filter: drop-shadow(0 0 0.05vw black) drop-shadow(0 0 0.2vw rgba(#00b3ff, 0.9));
  }
  100% {
    filter: drop-shadow(0 0 0.05vw black) drop-shadow(0 0 0.05vw $color_fadewhite);
  }
}
</style>
