<template>
  <div :class="$style.container">
    <div :class="$style.header">
      <div :class="[$style.image, upgrade.isInnate ? $style.innate : undefined]">
        <img :class="$style.src" :src="upgrade.isInnate ? innateImage : upgrade.image" />
      </div>
      <div :class="$style.title">
        <div :class="$style.name" v-if="upgrade.name !== ''">{{ upgrade.name }}</div>
        <div :class="$style.type">{{ upgrade.type }}</div>
      </div>
    </div>
    <div :class="$style.details">
      <div :class="$style.description" v-html="upgrade.description"></div>
      <ability-attributes
        v-if="upgrade.ability"
        :ability="upgrade.ability"
        :abilityDetails="abilityDetails"
        :scepterOnly="type === 'scepter' && upgrade.type === 'Upgrade'"
        :shardOnly="type === 'shard' && upgrade.type === 'Upgrade'"
        :noPadding="true"
        :noManaCd="true"
        :highlightAll="true"
      />
    </div>
  </div>
</template>

<script>
import AbilityAttributes from '@/views/overlays/components/ability-details/attributes.vue'

export default {
  name: 'aghanims-upgrade',
  components: { AbilityAttributes },
  props: {
    upgrade: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    innateImage() {
      return require('@/assets/imgs/innate.png')
    },
    abilityDetails() {
      return this.getAbilityDetails(this.upgrade.ability)
    }
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  padding: 0.2rem;
}

.header {
  display: flex;
  align-items: flex-start;
  text-transform: uppercase;
  gap: 0.4vw;
}

.image {
  width: 2rem;

  .src {
    width: 2rem;
    max-height: 2rem;
    border-radius: 0.2rem;
    border: 0.1rem solid $color_black;
    box-shadow: 0 0.1rem 0.2rem rgba($color_black, 0.8);
  }

  &.innate .src {
    border: none;
    box-shadow: none;
    filter: drop-shadow(0 0.1rem 0.2rem rgba($color_black, 0.8));
  }
}

.title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.name {
  color: $color_white;
  font-family: 'Reaver';
  font-size: 0.8rem;
  font-weight: bold;
  margin-right: 0.8rem;
}

.type {
  color: rgba($color_white, 0.8);
  background: rgba(52, 133, 255, 0.5);
  padding: 0.1rem 0.6rem;
  margin-bottom: 0.2rem;
  border-radius: 0.1rem;
  text-shadow: none;
  font-size: 0.55rem;
}

.description {
  color: $color_bluishgrey;
  font-size: 0.7rem;

  strong {
    color: white;
  }
}
</style>
