<template>
  <div
    :class="[
      $style.container,
      { [$style.inactive]: !active, [$style.onCooldown]: cooldown > 0, [$style.noMana]: nomana }
    ]"
  >
    <img :class="$style.image" :src="abilityImage" />
    <div
      v-if="charges >= 0"
      :class="[$style.charges, { [$style.onCooldown]: cooldown > 0, [$style.noMana]: nomana }]"
    >
      {{ charges }}
    </div>
    <template v-if="cooldown > 0">
      <div :class="$style.cooldown">{{ cooldown === 99999 ? '??' : cooldown }}</div>
    </template>
    <template v-if="nomana">
      <div :class="$style.nomana"></div>
    </template>
    <ul :class="$style.level">
      <li
        :class="[$style.notch, { [$style.active]: lv <= ability.level }]"
        v-for="lv in maxLevel"
        :key="`${ability.name}-lvl-${lv}`"
      ></li>
    </ul>
    <ability-tooltip
      :ability="ability"
      position="bottom"
      :aghanims="aghanims"
      :hideControls="true"
    />
  </div>
</template>

<script>
import AbilityTooltip from '../../tooltips/ability-tooltip.vue'

export default {
  name: 'ultimate',
  components: { AbilityTooltip },
  props: ['data'],
  methods: {},
  computed: {
    ability() {
      // If there are more than 1 ultimates, return the last one
      const ultimates = this.data.abilities.filter((ability) => ability.ultimate && !ability.skip)
      return ultimates[ultimates.length - 1]
    },
    abilityImage() {
      return this.getAbilityDetails(this.ability.name, 'image')
    },
    maxLevel() {
      return this.getAbilityDetails(this.ability.name, 'max_level') ?? 0
    },
    cooldown() {
      if (this.ability.charges !== undefined) {
        return this.ability.charges > 0 ? 0 : this.ability.charge_cooldown
      } else {
        return this.ability.cooldown
      }
    },
    charges() {
      if (this.ability.charges !== undefined) {
        return this.ability.charges
      } else {
        return -1
      }
    },
    nomana() {
      return this.cooldown === 0 && this.active && !this.ability.passive && !this.ability.can_cast
    },
    active() {
      return this.ability.level > 0
    },
    aghanims() {
      return {
        scepter: this.data.hero.aghanims_scepter,
        shard: this.data.hero.aghanims_shard
      }
    }
  }
}
</script>

<style lang="scss" module>
.container {
  position: relative;
  width: 2.35vw;
  height: 2.35vw;
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0.2vw $color_black;
  border: 0.15vw solid $color_green;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border ease 0.5s;
}

.noMana {
  border: 0.15vw solid rgb(0, 0, 66);

  .image {
    filter: grayscale(0.4);
  }
}

.onCooldown {
  border: 0.15vw solid $color_red;

  .image {
    filter: grayscale(0.6);
  }
}

.inactive {
  border: 0.15vw solid $color_black;

  .image {
    filter: grayscale(1);
  }
}

.image {
  width: 100%;
  border-radius: 50%;
}

.cooldown {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 50%;
  background: rgba($color_black, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1vw;
  font-weight: bold;
}

.nomana {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 50%;
  background: rgba(0, 0, 116, 0.4);
}

.charges {
  position: absolute;
  bottom: -0.2vw;
  left: -0.2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7vw;
  font-weight: bold;
  color: $color_black;
  text-shadow: 0.05vw 0.05vw 0 rgba($color_dark_green, 0.6);
  width: 1vw;
  height: 1vw;
  border-radius: 50%;
  background: $color_green;
  z-index: 20;

  &.onCooldown {
    color: $color_white;
    text-shadow: 0.05vw 0.05vw 0 rgba($color_black, 0.8);
    background: $color_red;
  }

  &.noMana {
    color: $color_white;
    text-shadow: 0.05vw 0.05vw 0 rgba($color_black, 0.8);
    background: rgb(0, 0, 66);
  }
}

.level {
  position: absolute;
  right: 0;
  left: 0;
  bottom: -0.7vw;
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 0.2vw;
  padding: 0.1vw 0.2vw;
  border-radius: 0.1vw;
}

.notch {
  height: 0.3vw;
  max-width: 0.4vw;
  flex: 1;
  background: rgb(92, 92, 92);
  border: 0.05vw solid rgba(black, 0.8);

  &.active {
    background: linear-gradient(180deg, rgb(247, 216, 122) 0%, rgba(136, 120, 69, 1) 100%);
  }
}
</style>
