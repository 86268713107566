<template>
  <div :class="$style.container">
    <div :class="$style.header">
      <div :class="$style.icon">
        <img :class="$style.src" :src="icon" />
      </div>
      <div :class="$style.title">Aghanim's {{ type }}</div>
    </div>
    <div :class="$style.upgrades">
      <div :class="$style.description" v-html="description"></div>
      <ability-attributes
        v-if="ability"
        :ability="ability"
        :abilityDetails="abilityDetails"
        :scepterOnly="type === 'scepter'"
        :shardOnly="type === 'shard'"
        :noPadding="true"
        :noManaCd="true"
      />
    </div>
  </div>
</template>

<script>
import AbilityAttributes from './attributes.vue'

export default {
  name: 'ability-upgrade',
  components: { AbilityAttributes },
  props: ['type', 'description', 'ability', 'abilityDetails'],
  computed: {
    icon() {
      return require(`@/assets/imgs/aghanims/${this.type}-on.png`)
    }
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  border-radius: 0.1rem;
  border: 0.1rem solid rgba($color_black, 0.5);
  background: #162024f8 url('~@/assets/imgs/aghanims/tooltip-bg.png') center top no-repeat;
  background-size: 100%;
  font-size: 0.8rem;
}

.header {
  height: 1.8rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  padding: 0 0.2rem;
}

.icon {
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .src {
    width: 1.4rem;
    animation: glow ease-in-out 2s infinite;
  }
}

.title {
  font-family: 'Reaver';
  color: $color_lightblue;
  font-weight: bold;
  text-transform: capitalize;
  line-height: 2.45rem;
  white-space: nowrap;
  overflow: hidden;
  text-shadow: 0.1rem 0.1rem 0.05rem $color_black;
}

.upgrades {
  padding: 0.4rem;
}

.description {
  color: $color_bluishgrey;

  strong {
    color: white;
  }
}

@keyframes glow {
  0% {
    filter: drop-shadow(0 0 0.05rem $color_fadewhite);
  }
  50% {
    filter: drop-shadow(0 0 0.2rem rgba(#00b3ff, 0.9));
  }
  100% {
    filter: drop-shadow(0 0 0.05rem $color_fadewhite);
  }
}
</style>
