export default class SSEClient {
  constructor(config) {
    this._source = null
    this.id = config.id
    this.name = config.name
    this.events = config.events
    this.store = config.store

    this.url = `${config.baseUrl}/${config.endpoint}?channel=${config.channel}`

    console.log('Connecting to SSE server...')
  }

  get source() {
    return this._source
  }

  connect() {
    this._source = new window.EventSource(this.url, {
      withCredentials: false
    })

    return new Promise((resolve) => {
      this._source.onopen = () => {
        console.log('Successfully connected to SSE Server!')
        this.store.commit('Connected')
        if (typeof this.events === 'function') {
          this._source.addEventListener(this.name, this.events)
        } else {
          Object.keys(this.events).forEach((event) => {
            this._source.addEventListener(event, this.events[event])
          })
        }

        resolve(this)
      }

      this._source.onerror = () => {
        console.log('Failed to connect to SSE Server!')

        // Disconnect
        this.store.commit('Disconnect')
        this._source.close()
        this._source = null

        // Retry connection after 10 secs
        setTimeout(() => {
          console.log('Retrying SSE connection...')
          this.connect()
        }, 10000)
      }
    })
  }

  disconnect() {
    if (this._source !== null) {
      this._source.close()
      this._source = null
    }
  }
}
