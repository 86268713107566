<template>
  <div>
    <div
      v-if="bkb"
      :class="[$style.container, { [$style.onCooldown]: cooldown > 0, [$style.noMana]: nomana }]"
    >
      <div :class="$style.image" :style="`background-image: url('${image}')`"></div>
      <div
        :class="[$style.duration, { [$style.onCooldown]: cooldown > 0, [$style.noMana]: nomana }]"
      >
        {{ duration }}s
      </div>
      <template v-if="cooldown > 0">
        <div :class="$style.cooldown">{{ cooldown }}</div>
      </template>
      <template v-if="nomana">
        <div :class="$style.nomana"></div>
      </template>
      <item-tooltip item="black_king_bar" position="bottom" :delay="300" :hideControls="true" />
    </div>
    <div v-else :class="$style.none">
      <font-awesome :class="$style.icon" icon="fa-square-xmark" />
      <div :class="$style.border"></div>
      <div :class="$style.color"></div>
    </div>
  </div>
</template>

<script>
import ItemTooltip from '../../tooltips/item-tooltip.vue'

export default {
  name: 'bkb',
  components: { ItemTooltip },
  props: ['data'],
  methods: {},
  computed: {
    bkb() {
      return this.data.inventory.special_items.bkb
    },
    image() {
      return this.getItemDetails('black_king_bar', 'image')
    },
    duration() {
      return this.bkb.duration
    },
    cooldown() {
      return this.bkb.cooldown
    },
    nomana() {
      return this.cooldown === 0 && !this.bkb.can_cast
    }
  }
}
</script>

<style lang="scss" module>
.container {
  position: relative;
  width: 2.35vw;
  height: 2.35vw;
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0.2vw $color_black;
  border: 0.15vw solid $color_green;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border ease 0.5s;
}

.duration {
  position: absolute;
  bottom: -0.2vw;
  left: -0.2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7vw;
  font-weight: bold;
  color: $color_black;
  text-shadow: none;
  width: 1vw;
  height: 1vw;
  border-radius: 50%;
  background: $color_green;
  z-index: 20;

  &.onCooldown {
    color: $color_white;
    text-shadow: 0.05vw 0.05vw 0 rgba($color_black, 0.8);
    background: $color_red;
  }

  &.noMana {
    color: $color_white;
    text-shadow: 0.05vw 0.05vw 0 rgba($color_black, 0.8);
    background: rgb(0, 0, 66);
  }
}

.noMana {
  border: 0.15vw solid rgb(0, 0, 66);

  .image {
    filter: grayscale(0.4);
  }
}

.onCooldown {
  border: 0.15vw solid $color_red;

  .image {
    filter: grayscale(0.6);
  }
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
}

.cooldown {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 50%;
  background: rgba($color_black, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1vw;
  font-weight: bold;
}

.nomana {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 50%;
  background: rgba(0, 0, 116, 0.4);
}

.none {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.4vw;

  .icon {
    color: $color_red;
    font-size: 1.2vw;
    z-index: 20;
  }

  .border {
    position: absolute;
    width: 1.3vw;
    height: 1.3vw;
    background: rgba($color_black, 0.8);
    border-radius: 0.2vw;
    z-index: 0;
  }

  .color {
    position: absolute;
    width: 0.6vw;
    height: 0.6vw;
    background: $color_white;
    z-index: 10;
  }
}
</style>
