<template>
  <div :class="$style.wrapper">
    <div :class="$style.container">
      <exp-tooltip
        position="bottom"
        :level="data.hero.level"
        :value="data.hero.xp - prevExpFloor"
        :max="expToNextLevel - prevExpFloor"
        :hideControls="true"
      />
      <div :class="$style.label">{{ data.hero.level }}</div>
      <circular-progress
        :class="$style.progress"
        :value="data.hero.xp - prevExpFloor"
        :max="expToNextLevel - prevExpFloor"
      />
    </div>
  </div>
</template>

<script>
import ExpTooltip from '../../tooltips/exp-tooltip.vue'
import CircularProgress from '../../common/CircularProgress.vue'

export default {
  name: 'hero-level',
  components: { ExpTooltip, CircularProgress },
  props: ['data'],
  computed: {
    prevExpFloor() {
      return this.getXpToLevel(this.data.hero.level - 1)
    },
    expToNextLevel() {
      return this.getXpToLevel(this.data.hero.level)
    }
  }
}
</script>

<style lang="scss" module>
.wrapper {
  position: relative;
  width: 2.2vw;
  height: 2.2vw;
}

.container {
  width: 100%;
  height: 100%;
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0.2vw $color_black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.label {
  color: #caa170;
  font-family: 'Reaver';
  font-size: 0.8vw;
  text-shadow: 0.1rem 0.1rem 0.2rem $color_red, -0.1rem -0.1rem 0.2rem $color_red,
    -0.1rem 0.1rem 0.2rem $color_red, 0.1rem -0.1rem 0.2rem $color_red;
}

.progress {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
