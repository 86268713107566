<template>
  <transition :name="transition">
    <div v-if="data && heroDetails" :class="[$style.container, $style[team]]">
      <transition :name="transition">
        <div v-if="heroStats" :class="$style.stats">
          <div :class="$style.statRow">
            <div :class="[$style.picks, $style.stat]">
              <div :class="$style.label">Picks:</div>
              <div :class="$style.value">{{ heroStats.overall.picks }}</div>
              <div :class="$style.percentage">{{ heroStats.overall.pickrate }}</div>
            </div>
            <div :class="[$style.bans, $style.stat]">
              <div :class="$style.label">Bans:</div>
              <div :class="$style.value">{{ heroStats.overall.bans }}</div>
              <div :class="$style.percentage">{{ heroStats.overall.banrate }}</div>
            </div>
          </div>
          <div :class="$style.winloss">
            <span :class="$style.w">Win</span> - <span :class="$style.l">Loss</span>
          </div>
          <div :class="$style.statRow">
            <div :class="[$style.wins, $style.stat]">
              <div :class="$style.label">Overall:</div>
              <div :class="[$style.value, $style.w]">{{ heroStats.overall.wins }}</div>
              <div :class="$style.dash">-</div>
              <div :class="[$style.value, $style.l]">
                {{ heroStats.overall.picks - heroStats.overall.wins }}
              </div>
              <div :class="$style.percentage">{{ heroStats.overall.winrate }}</div>
            </div>
            <div :class="[$style.wins, $style.stat]">
              <div :class="$style.label">Radiant:</div>
              <div :class="[$style.value, $style.w]">
                {{ heroStats.radiant.wins }}
              </div>
              <div :class="$style.dash">-</div>
              <div :class="[$style.value, $style.l]">
                {{ heroStats.radiant.picks - heroStats.radiant.wins }}
              </div>
              <div :class="$style.percentage">
                {{ heroStats.radiant.winrate }}
              </div>
            </div>
            <div :class="[$style.wins, $style.stat]">
              <div :class="$style.label">Dire:</div>
              <div :class="[$style.value, $style.w]">
                {{ heroStats.dire.wins }}
              </div>
              <div :class="$style.dash">-</div>
              <div :class="[$style.value, $style.l]">
                {{ heroStats.dire.picks - heroStats.dire.wins }}
              </div>
              <div :class="$style.percentage">{{ heroStats.dire.winrate }}</div>
            </div>
          </div>
          <div :class="$style.disclaimer">* not including this match</div>
        </div>
      </transition>
      <div :class="$style.facets">
        <div
          :class="$style.facet"
          v-for="(facet, index) in facetList"
          :key="`facet-winrate-${facet.key}`"
        >
          <facet-tooltip :facet="facet" />
          <facet-winrate
            :facet="facet"
            :stats="heroStats !== null ? heroStats.facets[index] : undefined"
          />
        </div>
      </div>
      <div :class="$style.hero">
        <div :class="$style.close" @click="closeDraftHero">
          <font-awesome icon="fa-times" />
        </div>
        <div :class="$style.portrait">
          <div :class="$style.type">{{ data.type }}</div>
          <div v-if="data.type === 'banned'" :class="$style.banfilter"></div>
          <img
            v-if="data.type === 'banned'"
            :class="$style.strikethrough"
            src="@/assets/imgs/hero/banned.png"
          />
          <img :class="[$style.image, $style[data.type]]" :src="heroDetails.image" />
          <div :class="[$style.health, $style.bar]">
            <div :class="$style.base">{{ heroDetails.base_health }}</div>
            <div :class="$style.regen">+{{ heroDetails.base_health_regen }}</div>
          </div>
          <div :class="[$style.mana, $style.bar]">
            <div :class="$style.base">{{ heroDetails.base_mana }}</div>
            <div :class="$style.regen">+{{ heroDetails.base_mana_regen }}</div>
          </div>
        </div>
        <div :class="[$style.info, $style[team]]">
          <div :class="$style.name">
            <img :class="$style.attrib" :src="primaryAttribImage" />
            {{ heroDetails.name }}
          </div>
          <ul :class="$style.complexity">
            <li v-for="i in 3" :key="`complexity-${i}`">
              <template v-if="i <= heroDetails.complexity">
                <img :class="$style.level" src="@/assets/imgs/hero/complexity-on.png" />
              </template>
              <template v-else>
                <img :class="$style.level" src="@/assets/imgs/hero/complexity-off.png" />
              </template>
            </li>
          </ul>
          <ul :class="$style.roles">
            <li
              :class="$style.role"
              v-for="(role, index) in heroDetails.roles"
              :key="`${heroDetails.id}-role-${index}`"
            >
              <div>{{ role }}</div>
              <ul :class="$style.role_level">
                <li
                  :class="[$style.notch, { [$style.active]: lv <= heroDetails.role_levels[index] }]"
                  v-for="lv in 3"
                  :key="`${role}-lvl-${lv}`"
                ></li>
              </ul>
            </li>
          </ul>
          <div :class="$style.details">
            <div :class="$style.talents">
              <img :class="$style.talent_icon" src="@/assets/imgs/hero/talent-tree.png" />
              <talent-tooltips :hero="{ name: data.hero_name }" :position="position" />
            </div>
            <div :class="$style.facetInnates">
              <img :class="$style.innate" src="@/assets/imgs/innate.png" />
              <innate-facet-tooltip
                :hero="data.hero_name"
                :position="position"
                :innate-only="true"
              />
            </div>
            <div :class="$style.abilities">
              <div
                :class="$style.ability"
                v-for="ability in abilities"
                :key="`ability-${ability.name}`"
              >
                <img :class="$style.ability_icon" :src="ability.image" />
                <ability-tooltips
                  :ability="{ name: ability.name }"
                  :aghanims="{ scepter: false, shard: false }"
                  :position="position"
                  :preview="true"
                />
              </div>
            </div>
            <div :class="$style.aghanims">
              <img :class="$style.aghanims_icon" src="@/assets/imgs/hero/aghanims.png" />
              <aghanims-tooltips
                :hero="{
                  name: data.hero_name,
                  aghanims_scepter: false,
                  aghanims_shard: false
                }"
                :position="position"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import talentTooltips from '../tooltips/talent-tooltip.vue'
import aghanimsTooltips from '../tooltips/aghanims-tooltip.vue'
import abilityTooltips from '../tooltips/ability-tooltip.vue'
import innateFacetTooltip from '../tooltips/innate-facet-tooltip.vue'
import facetTooltip from '../tooltips/facet-tooltip.vue'
import facetWinrate from '../innate-facet-details/facet-winrate.vue'

export default {
  name: 'DraftHero',
  components: {
    talentTooltips,
    aghanimsTooltips,
    abilityTooltips,
    innateFacetTooltip,
    facetTooltip,
    facetWinrate
  },
  data() {
    return {
      lastFetched: 0,
      heroStats: null,
      facet: { icon: 'blank', color: 'gray_0' }
    }
  },
  props: {
    team: {
      required: true
    }
  },
  methods: {
    closeDraftHero() {
      let mutation = ''
      if (this.team === 'radiant') {
        mutation = 'SetRadiantDraft'
      } else {
        mutation = 'SetDireDraft'
      }

      this.$store.commit(mutation, null)
    },
    fetchHeroStats() {
      if (this.leagueId !== 0) {
        if (this.data) {
          this.lastFetched = this.data.hero_id
          this.heroStats = null
          this.axios
            .get(`${process.env.VUE_APP_STATS_URL}/hero-stats/all/${this.data.hero_id}`)
            .then((response) => {
              this.heroStats = response.data
              console.log('Hero Stats Received!')
            })
            .catch((_) => {
              console.log('Failed to fetch hero stats')
            })
        } else {
          setTimeout(() => {
            this.fetchHeroStats()
          }, 500)
        }
      } else {
        console.log('No League ID')
      }
    }
  },
  watch: {
    data: {
      handler(data, _) {
        if (data !== null && this.lastFetched !== data.hero_id) {
          this.fetchHeroStats()
        }
      },
      deep: true
    }
  },
  computed: {
    leagueId() {
      return this.$store.getters.leagueId
    },
    transition() {
      if (this.team === 'radiant') {
        return 'fade-left'
      } else {
        return 'fade-right'
      }
    },
    data() {
      if (this.team === 'radiant') {
        return this.$store.getters.selectedRadiantDraft
      } else {
        return this.$store.getters.selectedDireDraft
      }
    },
    heroDetails() {
      if (this.data !== null) {
        return this.getHeroDetails(this.data.hero_name)
      }

      return null
    },
    primaryAttribImage() {
      switch (this.heroDetails.primary_attr) {
        case 'agi':
          return require('@/assets/imgs/agility.png')
        case 'int':
          return require('@/assets/imgs/inteligence.png')
        case 'str':
          return require('@/assets/imgs/strength.png')
        case 'all':
          return require('@/assets/imgs/all.png')
        default:
          return ''
      }
    },
    abilities() {
      const abilityDetails = this.getHeroAbilities(this.data.hero_name)

      return abilityDetails.abilities
        .filter((ability) => {
          return !ability.shardAbility && !ability.scepterAbility && !ability.hidden
        })
        .map((ability) => {
          return {
            name: ability.name,
            image: this.getAbilityDetails(ability.name, 'image')
          }
        })
    },
    position() {
      return this.team === 'radiant' ? 'lower-right' : 'lower-left'
    },
    facetList() {
      return this.getHeroFacets(this.data.hero_name)
    }
  },
  mounted() {
    this.fetchHeroStats()
  }
}
</script>

<style lang="scss" module>
.container {
  position: absolute;
  bottom: 15vw;
  display: flex;
  flex-direction: column;
  gap: 0.2vw;
  z-index: 50;

  &.radiant {
    left: 1vw;

    .hero {
      align-self: end;
    }

    .stats {
      right: 0;
      align-self: end;
    }

    .facets {
      align-self: end;
      justify-content: flex-end;
    }
  }

  &.dire {
    right: 1vw;

    .hero {
      align-self: start;
    }

    .stats {
      left: 0;
      align-self: start;
    }

    .facets {
      align-self: start;
      justify-content: flex-start;
    }
  }
}

.hero {
  position: relative;
  display: flex;
  background: rgba($color_black, 0.9);
  border: 0.2vw solid $color_black;
  border-radius: 0.4vw;

  .portrait {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .image {
    width: 12vw;
    border-radius: 0.2vw 0.2vw 0 0;

    &.banned {
      filter: grayscale(0.8) contrast(110%);
    }
  }

  .banfilter {
    position: absolute;
    width: 12vw;
    height: 6.75vw;
    background: rgba(red, 0.2);
    z-index: 10;
  }

  .strikethrough {
    position: absolute;
    transform: rotate(-30deg);
    top: 2.4vw;
    left: 0;
    width: 100%;
    z-index: 20;
  }

  .type {
    position: absolute;
    padding: 0.1vw 0.8vw 0.1vw 0.4vw;
    border-radius: 0 0.4vw 0 0;
    color: #ffffff;
    background: rgba($color_black, 0.6);
    text-transform: uppercase;
    text-shadow: none;
    bottom: 2.27vw;
    left: 0;
    z-index: 30;
  }

  .bar {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.1vw;

    &.health {
      background: linear-gradient(to right, #286323, #7af03c);
    }

    &.mana {
      background: linear-gradient(to right, #1056db, #73f5fe);
      border-radius: 0 0 0.2vw 0.2vw;
    }

    .base {
      font-size: 0.8vw;
      color: $color_white;
      text-shadow: 0.05vw 0.05vw 0.1vw rgba($color_black, 0.8);
    }

    .regen {
      position: absolute;
      color: rgba($color_black, 0.6);
      text-shadow: none;
      font-size: 0.7vw;
      right: 1vw;
    }
  }

  .info {
    color: $color_white;
    padding: 0.4vw 0.8vw 0 0.8vw;
    display: flex;
    flex-direction: column;
  }

  .radiant {
    .name {
      color: #a8f4cc;
    }
  }

  .dire {
    .name {
      color: #e26c20;
    }
  }

  .name {
    font-family: Reaver;
    text-transform: uppercase;
    font-size: 1.2vw;
    font-weight: bold;
    text-shadow: 0.05vw 0.05vw 0.1vw $color_black;
    display: flex;
    align-items: center;
    gap: 0.4vw;
  }

  .attrib {
    width: 1.4vw;
    height: 1.4vw;
  }

  .complexity {
    list-style: none;
    display: flex;
    gap: 0.2vw;

    .level {
      width: 0.8vw;
      height: 0.8vw;
    }
  }

  .roles {
    list-style: none;
    display: flex;
    gap: 0.2vw 0.6vw;
    flex-wrap: wrap;
    font-size: 0.8vw;
  }

  .role {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .role_level {
    display: flex;
    gap: 0.2vw;
    list-style: none;
    margin-left: 0.1vw;
  }

  .notch {
    width: 0.4vw;
    height: 0.2vw;
    border-radius: 0.1vw;
    background-color: rgba($color_white, 0.2);

    &.active {
      background-color: rgba($color_white, 0.8);
    }
  }

  .talents {
    position: relative;
    width: 3vw;
    height: 3vw;
  }

  .facetInnates {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.2vw;
  }

  .innate {
    width: 3vw;
    height: 3vw;
  }

  .talent_icon {
    width: 100%;
    height: 100%;
  }

  .aghanims {
    position: relative;
    width: 3vw;
    height: 3vw;
  }

  .aghanims_icon {
    width: 100%;
    height: 100%;
  }

  .details {
    display: flex;
    align-items: center;
    margin-top: 1vw;
    gap: 0.5vw;
  }

  .abilities {
    display: flex;
    align-items: center;
    gap: 0.4vw;
  }

  .ability {
    position: relative;
    width: 3vw;
    height: 3vw;
  }

  .ability_icon {
    width: 100%;
    height: 100%;
    border-radius: 0.4vw;
  }

  .close {
    position: absolute;
    top: 0;
    right: 0vw;
    color: $color_white;
    font-weight: bold;
    padding: 0.4vw 0.8vw;
    margin-right: -0.2vw;
    border-radius: 0.2vw;
    cursor: pointer;
  }
}

.stats {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.1vw;
  background: rgba($color_black, 0.9);
  padding: 0.4vw 1.2vw;
  border-radius: 0.4vw;
}

.statRow {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1vw;
}

.winloss {
  color: white;
  font-size: 0.6vw;
  text-transform: uppercase;
  margin-bottom: -0.4vw;

  .w {
    font-size: 0.8vw;
    color: #a9cf54;
  }

  .l {
    color: #ff5b5b;
  }
}

.stat {
  display: flex;
  justify-content: center;
  align-items: center;

  .label {
    color: white;
    font-size: 0.8vw;
    text-transform: uppercase;
    margin-right: 0.2vw;
    margin-top: 0.1vw;
  }

  .value {
    font-weight: bold;
    margin: 0 0.1vw;
    color: #a9cf54;

    &.w {
      color: #a9cf54;
    }

    &.l {
      font-size: 0.9vw;
      color: #ff5b5b;
    }
  }

  .dash {
    color: white;
    margin: 0 -0.1vw 0.2vw -0.1vw;
  }

  .percentage {
    font-weight: bold;
    font-size: 0.6vw;
    color: #76b8a6;
    margin-left: 0.1vw;
  }
}

.disclaimer {
  color: #ff5b5b;
  font-size: 0.6vw;
}

.facets {
  width: 100%;
  max-width: 42vw;
  display: flex;
  flex-wrap: wrap-reverse;
  gap: 0.2vw;

  .facet {
    position: relative;
  }
}
</style>
