<template>
  <div :class="$style.container">
    <template v-if="buyback.ready">
      <font-awesome v-if="buyback.ready" :class="$style.ready" icon="fa-check" />
    </template>
    <template v-else>
      <div :class="$style.cooldown" v-if="buyback.cooldown > 0">{{ cooldown }}</div>
    </template>
    <div :class="[$style.number, $style.lacking]" v-if="buyback.lacking_gold < 0">
      {{ buyback.lacking_gold }} <img :class="$style.gold" src="@/assets/imgs/gold.png" />
    </div>
    <div :class="[$style.number, $style.surplus]" v-if="buyback.surplus_gold > 0">
      +{{ buyback.surplus_gold }} <img :class="$style.gold" src="@/assets/imgs/gold.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'hero-buyback',
  props: ['buyback'],
  computed: {
    cooldown() {
      const time = Math.floor(this.buyback.cooldown)
      const minutes = Math.floor(time / 60)
      const seconds = time - minutes * 60
      const leadingZero = seconds < 10 ? '0' : ''

      return `${minutes}:${leadingZero}${seconds}`
    }
  }
}
</script>

<style lang="scss" module>
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.ready {
  text-align: center;
  font-size: 1vw;
  font-weight: bold;
  color: $color_green;
}

.number {
  font-weight: bold;
  font-size: 0.7vw;
  display: flex;
  align-items: center;
  gap: 0.1vw;
  text-shadow: text-shadow($color_black, 0.05vw);
}

.lacking {
  color: $color_highlight_red;
}

.surplus {
  color: $color_green;
}

.gold {
  width: 0.8vw;
  height: 0.8vw;
}

.cooldown {
  text-align: center;
  font-size: 0.7vw;
  color: rgba($color_white, 0.6);
}
</style>
