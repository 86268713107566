<template>
  <tooltip :position="position" :hideControls="hideControls">
    <div :class="$style.container">
      <template v-if="level < 30">
        <span :class="$style.value">{{ value }}</span> /
        <span :class="$style.value">{{ max }}</span>
        <div :class="$style.note">{{ lacking }} xp to next level</div>
      </template>
      <template v-else> Max Level </template>
    </div>
  </tooltip>
</template>

<script>
import Tooltip from './tooltip.vue'

export default {
  name: 'exp-tooltip',
  components: { Tooltip },
  props: {
    level: {
      type: Number
    },
    value: { type: Number },
    max: { type: Number },
    position: { type: String },
    hideControls: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    lacking() {
      return this.max - this.value
    }
  }
}
</script>

<style lang="scss" module>
.container {
  min-width: 8vw;
  font-family: 'Reaver';
  text-align: center;
  font-size: 0.6vw;
  line-height: 1vw;
  color: rgba($color_white, 0.8);
  background: #162024;
  padding: 0.1vw 0.2vw;
  border-radius: 0.4vw;
  box-shadow: 0 0.05vw 0.1vw rgba($color_black, 0.5);
  border: 0.2vw solid rgba($color_black, 0.5);
  text-shadow: 0 0 0.2rem $color_black;
  overflow: hidden;
}

.value {
  line-height: 1.2vw;
  font-size: 0.8vw;
  color: #caa170;
}

.note {
  color: $color_white;
  text-transform: uppercase;
}
</style>
