<template>
  <div :class="$style.container">
    <aghanims-tooltip position="top" :hero="hero" />
  </div>
</template>

<script>
import AghanimsTooltip from '../tooltips/aghanims-tooltip.vue'

export default {
  name: 'hud-aghanims',
  components: { AghanimsTooltip },
  computed: {
    hero() {
      return this.$store.getters.selectedPlayer.hero
    }
  }
}
</script>

<style lang="scss" module>
.container {
  position: absolute;
  top: 0.4vw;
  left: 29.9vw;
  width: 2vw;
  height: 3.4vw;
  cursor: pointer;
  border-radius: 1vw;
  transition: all ease-in-out 0.5s;
}
</style>
