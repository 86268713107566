var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.playerData && _vm.playerData.inventory)?_c('div',{class:_vm.$style.container},[_c('div',{class:_vm.$style.inventory},[_c('div',{class:_vm.$style.main},[_vm._l((_vm.playerData.inventory.main),function(item,index){
var _obj;
return [_c('div',{key:("main-item-" + index),class:[_vm.$style.item, ( _obj = {}, _obj[_vm.$style.empty] = item === 'empty', _obj )]},[_c('div',{class:_vm.$style.imgBox},[_c('img',{class:_vm.$style.img,attrs:{"src":_vm.itemDetails(item)}})]),_c('item-tooltip',{attrs:{"item":item,"position":"bottom","delay":300}})],1)]})],2),_c('div',{class:[
        _vm.$style.neutral,
        _vm.$style.item,
        ( _obj = {}, _obj[_vm.$style.empty] = _vm.playerData.inventory.neutral === 'empty', _obj )
      ]},[_c('div',{class:_vm.$style.imgBox},[_c('img',{class:_vm.$style.img,attrs:{"src":_vm.itemDetails(_vm.playerData.inventory.neutral)}})]),_c('item-tooltip',{attrs:{"item":_vm.playerData.inventory.neutral,"position":"bottom","delay":300}})],1)]),_c('div',{class:_vm.$style.backpack},[_vm._l((_vm.playerData.inventory.backpack),function(item,index){
      var _obj;
return _c('div',{key:("backpack-item-" + index),class:[_vm.$style.item, ( _obj = {}, _obj[_vm.$style.empty] = item === 'empty', _obj )]},[_c('div',{class:_vm.$style.imgBox},[_c('img',{class:_vm.$style.img,attrs:{"src":_vm.itemDetails(item)}})]),_c('item-tooltip',{attrs:{"item":item,"position":"bottom","delay":300}})],1)}),_c('div',{class:_vm.$style.gold},[_c('div',{class:_vm.$style.amount},[_vm._v(_vm._s(_vm.playerData.hero.gold))]),_c('img',{class:_vm.$style.icon,attrs:{"src":require("@/assets/imgs/gold.png")}})])],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }