<template>
  <div :class="$style.container">
    <div :class="[$style.winrate, $style.radiant]" :style="`width: ${winProbability}%;`">
      <div v-if="!intro" :class="$style.rate">{{ winProbability }}%</div>
    </div>
    <div :class="[$style.winrate, $style.dire]" :style="`width: ${100 - winProbability}%;`">
      <div v-if="!intro" :class="$style.rate">{{ 100 - winProbability }}%</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'win_probability',
  props: ['data'],
  data() {
    return {
      intro: true
    }
  },
  computed: {
    winProbability() {
      if (this.intro) {
        return 50
      }
      return this.$store.getters.winProbability
    }
  },
  mounted() {
    setTimeout(() => {
      this.intro = false
    }, 500)
  }
}
</script>

<style lang="scss" module>
.container {
  position: relative;
  width: 90%;
  height: 1vw;
  margin-top: 2vw;
  margin-bottom: 0.2vw;
  box-shadow: 0 0 0.1vw rgba(black, 0.8);
  border-radius: 0.2vw;
  transition: margin-top ease 0.5s;
  display: flex;
}

.winrate {
  position: absolute;
  height: 100%;
  min-width: 5%;
  max-width: 95%;
  transition: width 1s ease;
  display: flex;
  align-items: center;
  box-shadow: inset 0 -0.1vw 0.2vw rgba(black, 0.2), inset 0 0.1vw 0.2vw rgba(black, 0.2);
}

.radiant {
  position: relative;
  left: 0;
  background-image: linear-gradient(110.1deg, rgba(34, 126, 34, 1) 2.9%, rgb(138, 226, 22) 90.3%);
  border-radius: 0.2vw 0 0 0.2vw;
  padding-right: 0.4vw;
  justify-content: flex-end;

  .rate {
    text-align: right;
  }
}

.dire {
  right: 0;
  background-image: radial-gradient(
    circle farthest-corner at 17.1% 22.8%,
    rgb(226, 88, 24) 0%,
    rgb(185, 4, 4) 90%
  );
  border-radius: 0 0.2vw 0.2vw 0;
  padding-left: 0.4vw;
  justify-content: flex-start;
}

.rate {
  color: white;
  width: 1.8vw;
  font-size: 0.8vw;
  font-weight: bold;
}
</style>
