<template>
  <div v-if="neutrals" :class="$style.container">
    <div :class="$style.category" v-for="(neutral, index) in neutrals" :key="`category-${index}`">
      <div :class="$style.header">
        <div :class="[$style.title, $style[neutral.tier]]">
          {{ getTitle(neutral.tier) }}
        </div>
        <div :class="$style.time">
          <template v-if="getItemCount(neutral.items) === 0">
            <font-awesome :class="$style.ficon" icon="fa-clock" />{{ neutralTiming(neutral.tier) }}
          </template>
          <template v-else>[ {{ getItemCount(neutral.items) }} / 5 ]</template>
        </div>
      </div>
      <div :class="$style.grid">
        <div
          :class="$style.item"
          v-for="(item, iIndex) in neutral.items"
          :key="`${neutral.tier}-item-${iIndex}`"
        >
          <img
            v-if="item.hero !== undefined && item.hero !== -1"
            :class="$style.hero"
            :src="getHeroIcon(item.hero)"
          />
          <div :class="$style.imgContainer">
            <div :class="$style.shadow"></div>
            <img :class="$style.img" :src="getItemDetails(item.item, 'image')" />
          </div>
          <item-tooltip :item="item.item" position="left" :delay="300" />
          <div :class="[$style.state, $style[item.state]]">{{ item.state }}</div>
        </div>
        <div
          :class="$style.empty"
          v-for="count in 5 - getItemCount(neutral.items)"
          :key="`${neutral.tier}-empty-${count}`"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemTooltip from '../tooltips/item-tooltip.vue'

export default {
  name: 'neutral-list',
  components: { ItemTooltip },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    neutrals() {
      if (this.type === 'radiant') {
        return this.$store.getters.radiantNeutrals
      }
      return this.$store.getters.direNeutrals
    },
    heroList() {
      return this.$store.getters.heroList
    }
  },
  methods: {
    getTitle(tier) {
      return tier.replace('tier', 'tier ')
    },
    getItemCount(items) {
      return items.length
    },
    neutralTiming(tier) {
      switch (tier) {
        case 'tier1':
          return '7:00+'
        case 'tier2':
          return '17:00+'
        case 'tier3':
          return '27:00+'
        case 'tier4':
          return '37:00+'
        case 'tier5':
          return '60:00+'
      }
    },
    getHeroIcon(player) {
      if (this.heroList) {
        const hero = this.heroList.find((hero) => hero.index === player)
        if (hero) {
          const heroIcon = this.getHeroDetails(hero.name, 'icon')
          return heroIcon
        }
      }
      return ''
    }
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1vw;
  padding: 0.5vw 1.2vw;
  margin-bottom: 1vw;
}

.category {
  display: flex;
  flex-direction: column;
  gap: 0.2vw;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 0.8vw;
  font-weight: bold;
  text-transform: uppercase;

  &.tier1 {
    color: #bdbdbd;
  }

  &.tier2 {
    color: #74b365;
  }

  &.tier3 {
    color: #7f93fa;
  }

  &.tier4 {
    color: #c171e7;
  }

  &.tier5 {
    color: #eed28b;
  }
}

.time {
  display: flex;
  align-items: center;
  color: rgba(white, 0.6);
  font-size: 0.8vw;

  .ficon {
    margin-right: 0.2vw;
    font-size: 0.7vw;
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.4vw;
}

.item {
  position: relative;
  margin-bottom: 0.2vw;

  &:hover {
    box-shadow: inset $color_primary 0 0 0.4vw;
    border-radius: 0.2vw;
  }
}

.imgContainer {
  position: relative;
  height: 2.4vw;
  width: 3.3vw;
  border-radius: 0.2vw;
  overflow: hidden;
  border: 0.1vw solid black;
  z-index: -1;
}

.shadow {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-shadow: inset 0 0 1vw rgba(black, 0.8);
  z-index: 50;
}

.hero {
  position: absolute;
  width: 1.4vw;
  height: 1.4vw;
  bottom: -0.2vw;
  right: -0.2vw;
  filter: image-shadow(rgba(black, 0.6), 0.1vw);
}

.img {
  height: calc(100% + 0.1vw);
  position: absolute;
  top: -0.05vw;
  left: 50%;
  transform: translateX(-50%);
}

.state {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -0.8vw;
  color: rgba(white, 0.8);
  font-size: 0.5vw;
  text-transform: uppercase;
  text-align: center;

  &.equipped {
    font-style: italic;
  }
}

.empty {
  height: 2.4vw;
  width: 3.3vw;
  border: 0.1vw solid black;
  background: rgba(#1b1b1b, 0.95);
  box-shadow: inset 0 0.05vw 0.4vw rgba($color_black, 0.5);
  border-radius: 0.2vw;
}
</style>
