<template>
  <div :class="$style.container">
    <img :class="$style.icon" v-if="status === 'ready'" src="@/assets/imgs/ult_ready.png" />
    <img :class="$style.icon" v-if="status === 'no mana'" src="@/assets/imgs/ult_no_mana.png" />
    <template v-if="status === 'cooldown'">
      <img :class="$style.cooldownIcon" src="@/assets/imgs/ult_cooldown.png" />
      <div :class="$style.cooldown">{{ cooldown === 99999 ? '??' : cooldown }}</div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'hero-ultimate',
  props: ['playerData'],
  computed: {
    ultimate() {
      return this.playerData.abilities.find((ability) => ability.ultimate)
    },
    cooldown() {
      if (this.ultimate.charges !== undefined) {
        return this.ultimate.charges > 0 ? 0 : this.ultimate.charge_cooldown
      } else {
        return this.ultimate.cooldown
      }
    },
    status() {
      if (this.ultimate.level === 0) {
        return 'none'
      } else if (this.cooldown > 0) {
        return 'cooldown'
      } else if (
        this.playerData.hero.alive &&
        this.cooldown === 0 &&
        !this.ultimate.passive &&
        !this.ultimate.can_cast
      ) {
        return 'no mana'
      }
      return 'ready'
    }
  }
}
</script>

<style lang="scss" module>
.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.icon {
  width: 1.2vw;
}

.cooldownIcon {
  width: 1.2vw;
  margin-top: -0.4vw;
}

.cooldown {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: rgba($color_white, 0.6);
  font-size: 0.5vw;
}
</style>
