var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.talents)?_c('div',{class:[
    _vm.$style.container,
    _vm.$style[_vm.position],
    ( _obj = {}, _obj[_vm.$style.withAttributes] = _vm.withAttributes, _obj[_vm.$style.invoker] = _vm.hero.name === 'invoker', _obj )
  ]},[_c('div',{class:_vm.$style.title},[_vm._v("Talent Tree")]),_c('div',{class:_vm.$style.levels},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOgreCustom),expression:"isOgreCustom"}],class:_vm.$style.ogre,attrs:{"src":require("@/assets/imgs/talents/ogre.png")}}),_c('talent-level',{attrs:{"level":"25","talent1":_vm.talents.talent_7,"talent2":_vm.talents.talent_8}}),_c('talent-level',{attrs:{"level":"20","talent1":_vm.talents.talent_5,"talent2":_vm.talents.talent_6}}),_c('talent-level',{attrs:{"level":"15","talent1":_vm.talents.talent_3,"talent2":_vm.talents.talent_4}}),_c('talent-level',{attrs:{"level":"10","talent1":_vm.talents.talent_1,"talent2":_vm.talents.talent_2}})],1),(_vm.hero.attributes_level >= 0)?_c('div',{class:_vm.$style.attributes},[_c('div',{class:_vm.$style.label},[_vm._v("+2 to all attributes")]),_c('div',{class:_vm.$style.lvl},_vm._l((7),function(level){
  var _obj;
return _c('div',{key:("attribute-level-" + level),class:[_vm.$style.notch, ( _obj = {}, _obj[_vm.$style.active] = _vm.hero.attributes_level >= level, _obj )]})}),0)]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }