var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.container},[_c('div',{class:_vm.$style.main},_vm._l((_vm.inventory.main),function(item,index){
var _obj;
return _c('div',{key:("main-item-" + index),class:[
        _vm.$style.item,
        ( _obj = {}, _obj[_vm.$style.empty] = item === 'empty', _obj[_vm.$style.indicator] = item !== 'empty' && _vm.showIndicators, _obj )
      ]},[_c('item-tooltip',{attrs:{"item":item,"position":"top"}})],1)}),0),_c('div',{class:_vm.$style.backpack},_vm._l((_vm.inventory.backpack),function(item,index){
      var _obj;
return _c('div',{key:("backpack-item-" + index),class:[
        _vm.$style.item,
        ( _obj = {}, _obj[_vm.$style.empty] = item === 'empty', _obj[_vm.$style.indicator] = item !== 'empty' && _vm.showIndicators, _obj )
      ]},[_c('item-tooltip',{attrs:{"item":item,"position":"top"}})],1)}),0),_c('div',{class:[
      _vm.$style.neutral,
      _vm.$style.item,
      ( _obj = {}, _obj[_vm.$style.empty] = _vm.inventory.neutral === 'empty', _obj[_vm.$style.indicator] = _vm.inventory.neutral !== 'empty' && _vm.showIndicators, _obj )
    ]},[_c('item-tooltip',{attrs:{"item":_vm.inventory.neutral,"position":"top"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }