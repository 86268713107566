<template>
  <div v-if="isConnected && ['PRE_GAME', 'IN_PROGRESS'].includes(gameState)">
    <div v-for="team in teams" :class="$style.team" :key="`team-${team}`" :team="team">
      <div :class="$style.title">
        <p>{{ team }}</p>
      </div>
      <hero-tab
        v-for="(hero, index) in getHeroes(team)"
        :hero="hero"
        :team="team"
        :key="`${team}-${index}`"
      />
    </div>
  </div>
</template>

<script>
import HeroTab from './components/tabs.vue'

export default {
  name: 'mobile',
  components: {
    HeroTab
  },
  data: () => {
    return {
      teams: ['radiant', 'dire']
    }
  },
  computed: {
    gameState() {
      return this.$store.getters.gameState
    },
    channelId() {
      return this.$store.getters.channelId
    },
    isConnected() {
      return this.$store.getters.isConnected
    }
  },
  methods: {
    getHeroes(team) {
      if (team === 'radiant') {
        return this.$store.getters.radiantTeam
      }
      return this.$store.getters.direTeam
    },
    recalculateBaseFontSize() {
      const width = document.documentElement.clientWidth || document.body.clientWidth
      const fontSize = width / 22
      document.documentElement.style.fontSize = `${fontSize}px`
    }
  },
  sse: {
    name: 'BaseData',
    endpoint: 'base-data',
    events: ['GameState', 'HeroList', 'Heroes', 'Abilities', 'Inventory', 'Stats']
  },
  mounted() {
    this.recalculateBaseFontSize()
    window.addEventListener('resize', this.recalculateBaseFontSize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.recalculateBaseFontSize)
  }
}
</script>

<style lang="scss" module>
.team {
  width: 100%;
  flex-direction: column;
}

.title {
  padding: 2vw 0 2vw 0;
  background: black;
  color: $color_white;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  font-size: 4vw;
}
</style>
