<template>
  <div :class="[$style.container, $style[size]]">
    <talent-tooltip :position="position" :hero="hero" :withAttributes="withAttributes" />
    <div :class="[$style.progress, $style[progressLevel]]"></div>
    <template v-for="i in 8">
      <div
        v-if="hero[`talent_${i}`]"
        :class="[$style.talent, $style[`talent_${i}`]]"
        :key="`${hero.id}-talent-${i}`"
      ></div>
    </template>
  </div>
</template>

<script>
import TalentTooltip from '../tooltips/talent-tooltip.vue'

export default {
  name: 'hero-talents',
  components: {
    TalentTooltip
  },
  props: {
    hero: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: 'normal'
    },
    position: {
      type: String,
      default: 'bottom'
    },
    withAttributes: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    progressLevel() {
      if (this.hero.talent_7 || this.hero.talent_8) {
        return 'level4'
      } else if (this.hero.talent_5 || this.hero.talent_6) {
        return 'level3'
      } else if (this.hero.talent_4 || this.hero.talent_3) {
        return 'level2'
      } else if (this.hero.talent_2 || this.hero.talent_1) {
        return 'level1'
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" module>
.container {
  background: url('~@/assets/imgs/talents/talent-tree.png') center center no-repeat;
  background-size: contain;
  width: 3vw;
  height: 3vw;
  position: relative;

  &.small {
    width: 2vw;
    height: 2vw;
  }
}

.progress {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: url('~@/assets/imgs/talents/stem.png') center bottom no-repeat;
  background-size: 100%;

  &.level1 {
    top: 80%;
  }

  &.level2 {
    top: 65%;
  }

  &.level3 {
    top: 45%;
  }

  &.level4 {
    top: 0;
  }
}

.talent_1 {
  background: url('~@/assets/imgs/talents/talent_1.png') center center no-repeat;
}

.talent_2 {
  background: url('~@/assets/imgs/talents/talent_2.png') center center no-repeat;
}

.talent_3 {
  background: url('~@/assets/imgs/talents/talent_3.png') center center no-repeat;
}

.talent_4 {
  background: url('~@/assets/imgs/talents/talent_4.png') center center no-repeat;
}

.talent_5 {
  background: url('~@/assets/imgs/talents/talent_5.png') center center no-repeat;
}

.talent_6 {
  background: url('~@/assets/imgs/talents/talent_6.png') center center no-repeat;
}

.talent_7 {
  background: url('~@/assets/imgs/talents/talent_7.png') center center no-repeat;
}

.talent_8 {
  background: url('~@/assets/imgs/talents/talent_8.png') center center no-repeat;
}

.talent {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: contain;
}
</style>
