<template>
  <div :class="$style.container">
    <div :class="$style.header">
      <div :class="[$style.icon, { [$style.on]: status }]">
        <img :class="$style.src" :src="icon" />
      </div>
      <div :class="$style.title">
        <div :class="$style.titleShadow">Aghanim's {{ type }}</div>
        <div :class="[$style.titleText, { [$style.on]: status }]">Aghanim's {{ type }}</div>
      </div>
    </div>
    <div :class="$style.upgrades">
      <div
        :class="$style.upgrade"
        v-for="(upgrade, index) in upgrades"
        :key="`${type}-upgrade-${index}`"
      >
        <aghs-upgrade :upgrade="upgrade" :type="type" />
      </div>
      <div v-if="upgrades.length === 0" :class="$style.none">No Aghanim's {{ type }} upgrade</div>
    </div>
  </div>
</template>

<script>
import AghsUpgrade from './upgrade.vue'

export default {
  name: 'aghanims-details',
  components: { AghsUpgrade },
  props: {
    hero: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    status: {
      type: Boolean,
      required: true
    },
    size: {
      type: String,
      default: 'normal'
    }
  },
  computed: {
    icon() {
      return require(`@/assets/imgs/aghanims/${this.type}-${this.status ? 'on' : 'off'}.png`)
    },
    upgrades() {
      return this.getAghsUpgrades(this.hero.name, this.type)
    }
  }
}
</script>

<style lang="scss" module>
.container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  border-radius: 0.1rem;
  border: 0.15rem solid rgba($color_black, 0.5);
  background: #1b1c26 url('~@/assets/imgs/aghanims/tooltip-bg.png') center top no-repeat;
  background-size: 120%;
  box-shadow: 0 0.05rem 0.1rem rgba($color_black, 0.5);
}

.header {
  height: 2.45rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  padding: 0 0.2rem;
}

.icon {
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .src {
    width: 1.8rem;
  }

  &.on {
    .src {
      animation: glow ease-in-out 2s infinite;
    }
  }
}

.title {
  color: $color_black;
  font-size: 1.2rem;
  font-weight: 900;
  text-transform: capitalize;
  height: 2.55rem;
  flex: 1;
  line-height: 2.55rem;
  white-space: nowrap;
  overflow: hidden;
  text-shadow: none;
  position: relative;
}

.titleText {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(90deg, $color_lightblue 0%, $color_dark_blue 30%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: none;

  &.on {
    background-image: linear-gradient(90deg, #09a8d8 0%, $color_dark_blue 20%);
    animation: textGlow ease 4s infinite;
  }
}

.titleShadow {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-shadow: 0.1rem 0.1rem 0.05rem $color_black;
}

.upgrades {
  padding: 0.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.none {
  color: rgba($color_yellow, 0.5);
  font-weight: bold;
}

.note {
  color: rgba($color_primary, 0.7);
  text-align: center;
  font-size: 0.7rem;
}

@keyframes glow {
  0% {
    filter: drop-shadow(0 0 0.05rem $color_fadewhite);
  }
  50% {
    filter: drop-shadow(0 0 0.2rem rgba(#00b3ff, 0.9));
  }
  100% {
    filter: drop-shadow(0 0 0.05rem $color_fadewhite);
  }
}

@keyframes textGlow {
  0% {
    background-size: 100%;
  }
  50% {
    background-size: 200%;
  }
  100% {
    background-size: 100%;
  }
}
</style>
