<template>
  <ul :class="$style.abilities">
    <template v-for="(ability, index) in abilities">
      <li
        :class="[$style.ability, { [$style.inactive]: ability.level === 0 }]"
        :key="`${ability.name}-${index}`"
        @click="previewAbility(ability)"
        v-if="!ability.skip"
      >
        <bg-loading :class="$style.abilityImg" />
        <img :class="$style.abilityImg" :src="getAbilityImage(ability.name)" />
        <ul :class="$style.level">
          <li
            :class="[$style.notch, { [$style.active]: lv <= ability.level }]"
            v-for="lv in getMaxLevel(ability.name)"
            :key="`${ability.name}-lvl-${lv}`"
          ></li>
        </ul>
        <ability-tooltip :ability="ability" position="bottom" :aghanims="aghanims" />
      </li>
    </template>
  </ul>
</template>

<script>
import AbilityTooltip from '../tooltips/ability-tooltip.vue'
import BgLoading from '../common/BgLoading.vue'

export default {
  name: 'hero-abilities',
  components: { AbilityTooltip, BgLoading },
  props: ['abilities', 'heroName', 'aghanims'],
  methods: {
    getAbilityImage(ability) {
      return this.getAbilityDetails(ability, 'image')
    },
    getMaxLevel(ability) {
      return this.getAbilityDetails(ability, 'max_level') ?? 0
    },
    previewAbility(ability) {
      if (ability.skip) {
        return false
      }
      this.$store.commit('SetPreviewedAbility', { ...ability, hero: this.heroName })
    }
  }
}
</script>

<style lang="scss" module>
.abilities {
  display: flex;
  gap: 0.4vw;
  margin-top: 0.2vw;
  justify-self: flex-end;
  align-items: center;
  list-style: none;
}

.ability {
  width: 2.5vw;
  height: 3vw;
  position: relative;
  transition: all ease 0.5s;

  .abilityImg {
    position: absolute;
    top: 0;
    border-radius: 0.2vw;
    box-shadow: 0 0.1vw 0.2vw rgba($color_black, 0.8);
    width: 2.5vw;
    height: 2.5vw;
  }

  &.inactive {
    .abilityImg {
      filter: grayscale(95%);
      box-shadow: none;
    }
  }
}

.level {
  position: absolute;
  right: 0;
  left: 0;
  bottom: -0.1vw;
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 0.2vw;
}

.notch {
  height: 0.2vw;
  max-width: 0.4vw;
  flex: 1;
  background: black;

  &.active {
    background: linear-gradient(180deg, rgba(231, 210, 145, 1) 0%, rgba(136, 120, 69, 1) 100%);
  }
}
</style>
