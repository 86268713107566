<template>
  <div :class="$style.container" v-if="playerData && playerData.inventory">
    <div :class="$style.inventory">
      <div :class="$style.main">
        <template v-for="(item, index) in playerData.inventory.main">
          <div
            :class="[$style.item, { [$style.empty]: item === 'empty' }]"
            :key="`main-item-${index}`"
          >
            <div :class="$style.imgBox">
              <img :class="$style.img" :src="itemDetails(item)" />
            </div>
            <item-tooltip :item="item" position="bottom" :delay="300" />
          </div>
        </template>
      </div>
      <div
        :class="[
          $style.neutral,
          $style.item,
          { [$style.empty]: playerData.inventory.neutral === 'empty' }
        ]"
      >
        <div :class="$style.imgBox">
          <img :class="$style.img" :src="itemDetails(playerData.inventory.neutral)" />
        </div>
        <item-tooltip :item="playerData.inventory.neutral" position="bottom" :delay="300" />
      </div>
    </div>
    <div :class="$style.backpack">
      <div
        v-for="(item, index) in playerData.inventory.backpack"
        :class="[$style.item, { [$style.empty]: item === 'empty' }]"
        :key="`backpack-item-${index}`"
      >
        <div :class="$style.imgBox">
          <img :class="$style.img" :src="itemDetails(item)" />
        </div>
        <item-tooltip :item="item" position="bottom" :delay="300" />
      </div>
      <div :class="$style.gold">
        <div :class="$style.amount">{{ playerData.hero.gold }}</div>
        <img :class="$style.icon" src="@/assets/imgs/gold.png" />
      </div>
    </div>
  </div>
</template>

<script>
import ItemTooltip from '../tooltips/item-tooltip.vue'

export default {
  name: 'hero-inventory',
  components: { ItemTooltip },
  computed: {
    playerData() {
      return this.$store.getters.playerData
    },
    inventory() {
      return this.$store.getters.selectedPlayer.inventory
    }
  },
  methods: {
    itemDetails(item) {
      return this.getItemDetails(item, 'image')
    }
  }
}
</script>

<style lang="scss" module>
.container {
  position: absolute;
  bottom: calc(-100% + 1.5vw);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.2vw 0.4vw;
  border-radius: 0.2vw;
  border: 0.2vw solid rgba($color_black, 0.5);
  background: rgba(#162024, 0.95);
  box-shadow: 0 0.05vw 0.2vw rgba($color_black, 0.5);
}

.item {
  position: relative;
  border-radius: 0.2vw;
  cursor: pointer;

  &:hover:not(.empty) {
    box-shadow: 0 0 0.2vw $color_primary;
  }

  .imgBox {
    border: 0.1vw solid black;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 0.2vw;
    overflow: hidden;
    box-shadow: inset rgba(black, 0.8) 0 0 0.8vw;
  }

  .img {
    height: calc(100% + 0.1vw);
    position: absolute;
    top: -0.05vw;
    left: 50%;
    transform: translateX(-50%);
  }

  &.empty {
    cursor: default;

    .img {
      display: none;
    }
  }
}

.inventory {
  display: flex;
  align-items: center;
  gap: 0.2vw;
}

.main {
  height: auto;
  display: flex;
  flex-wrap: wrap;
  width: 9vw;
  gap: 0.2vw;

  .item {
    height: 2vw;
    width: 2.7vw;
  }
}

.backpack {
  height: auto;
  width: 100%;
  display: flex;
  gap: 0.4vw;
  margin-top: 0.3vw;
  justify-content: center;
  align-self: flex-start;

  .item {
    height: 1.5vw;
    width: 2.1vw;
  }

  .imgBox {
    background: rgba(black, 0.3);
  }

  .img {
    filter: grayscale(1);
  }
}

.gold {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.2vw;

  .amount {
    font-size: 0.9vw;
    color: gold;
  }

  .icon {
    width: 1vw;
  }
}

.neutral {
  height: 2.2vw;
  width: 2.2vw;
  border-radius: 50%;

  .imgBox {
    border-radius: 50%;
    background: rgba(black, 0.3);
  }
}
</style>
