<template>
  <div>
    <div :class="$style.container" v-if="playerData && playerData.inventory">
      <div :class="$style.inventory">
        <div :class="$style.main">
          <template v-for="(item, index) in playerData.inventory.main">
            <div
              :class="[
                $style.item,
                {
                  [$style.empty]: item === 'empty',
                  [$style.selected]: item !== 'empty' && isSelected('main', index)
                }
              ]"
              :key="`main-item-${index}`"
              @click="toggleItem('main', index, item === 'empty')"
            >
              <div :class="$style.imgBox">
                <img :class="$style.img" :src="itemImage(item)" />
              </div>
            </div>
          </template>
        </div>
        <div
          :class="[
            $style.neutral,
            $style.item,
            {
              [$style.empty]: playerData.inventory.neutral === 'empty',
              [$style.selected]:
                playerData.inventory.neutral !== 'empty' && isSelected('neutral', 0)
            }
          ]"
          @click="toggleItem('neutral', 0, playerData.inventory.neutral === 'empty')"
        >
          <div :class="$style.imgBox">
            <img :class="$style.img" :src="itemImage(playerData.inventory.neutral)" />
          </div>
        </div>
      </div>
      <div :class="$style.backpack">
        <template v-for="(item, index) in playerData.inventory.backpack">
          <div
            :class="[
              $style.item,
              {
                [$style.empty]: item === 'empty',
                [$style.selected]: item !== 'empty' && isSelected('backpack', index)
              }
            ]"
            :key="`backpack-item-${index}`"
            @click="toggleItem('backpack', index, item === 'empty')"
          >
            <div :class="$style.imgBox">
              <img :class="$style.img" :src="itemImage(item)" />
            </div>
          </div>
        </template>
      </div>
    </div>
    <div :class="$style.details" v-if="selectedItem !== null">
      <div :class="$style.header">
        <div :class="$style.name">{{ itemDetails(selectedItemName).name }}</div>
      </div>
      <item-details :item="selectedItemName" :item-details="itemDetails(selectedItemName)" />
    </div>
  </div>
</template>

<script>
import ItemDetails from '@/views/overlays/components/item-details/details.vue'

export default {
  name: 'hero-inventory',
  components: {
    ItemDetails
  },
  computed: {
    playerData() {
      return this.$store.getters.playerData
    },
    selectedItem() {
      return this.$store.getters.selectedHeroItem
    },
    selectedItemName() {
      if (this.selectedItem.type === 'neutral') {
        return this.playerData.inventory[this.selectedItem.type]
      }

      return this.playerData.inventory[this.selectedItem.type][this.selectedItem.slot]
    }
  },
  methods: {
    itemImage(item) {
      return this.getItemDetails(item, 'image')
    },
    itemDetails(item) {
      return this.getItemDetails(item)
    },
    toggleItem(type, slot, isEmpty) {
      if (!isEmpty) {
        this.$store.commit('SetSelectedHeroItem', {
          type,
          slot
        })
      }
    },
    isSelected(type, slot) {
      return (
        this.selectedItem !== null &&
        this.selectedItem.slot === slot &&
        this.selectedItem.type === type
      )
    }
  }
}
</script>

<style lang="scss" module>
.container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2vw;
  border-radius: 0.6rem;
  border: 0.1rem solid rgba($color_black, 0.5);
  background: rgba(#162024, 0.95);
  box-shadow: 0 0.05rem 0.2rem rgba($color_black, 0.5);
}

.item {
  position: relative;
  border-radius: 0.2rem;
  cursor: pointer;

  &.selected {
    border: 0.1rem solid rgba($color_primary, 0.5);
    box-shadow: 0 0 0.2rem $color_primary;
  }

  .imgBox {
    border: 0.1rem solid black;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 0.2rem;
    overflow: hidden;
    box-shadow: inset rgba(black, 0.8) 0 0 0.8rem;
  }

  .img {
    height: calc(100% + 0.1rem);
    position: absolute;
    top: -0.05rem;
    left: 50%;
    transform: translateX(-50%);
  }

  &.empty {
    cursor: default;

    .img {
      display: none;
    }
  }
}

.inventory {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.main {
  height: auto;
  display: flex;
  flex-wrap: wrap;
  width: 14rem;
  gap: 0.2rem;

  .item {
    height: 3rem;
    width: 4.2rem;
  }
}

.backpack {
  height: auto;
  display: flex;
  gap: 0.4rem;
  margin-top: 0.3rem;

  .item {
    height: 2.25rem;
    width: 3.15rem;
  }

  .imgBox {
    background: rgba(black, 0.3);
  }

  .img {
    filter: grayscale(1);
  }
}

.neutral {
  height: 3.2rem;
  width: 3.2rem;
  border-radius: 50%;

  .imgBox {
    border-radius: 50%;
    background: black;
  }
}

.details {
  margin-top: 2vw;
  padding: 2vw;
  border-radius: 2vw;
  background: #10171d;
  box-shadow: 0 0 1vw rgba($color_primary, 0.5);
}

.header {
  height: 2.35rem;
  border-bottom: 0.1rem solid rgba($color_white, 0.1);
  padding: 0 0.4rem;
  margin-bottom: 0.4rem;
}

.name {
  font-family: 'Reaver';
  color: $color_white;
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 2.55rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 15rem;
}
</style>
