<template>
  <div :class="$style.container">
    <div :class="$style.header">
      <div :class="$style.icon">
        <img :src="image" :class="$style.iconImg" @error="fallbackImage" />
      </div>
      <div :class="$style.title">
        <div :class="$style.name">{{ abilityDetails.name }}</div>
        <div :class="$style.tag">Innate Ability</div>
      </div>
    </div>
    <div :class="$style.content">
      <ability-details
        :ability="innate"
        :abilityDetails="abilityDetails"
        :aghanims="{ scepter: false, shard: false }"
      />
    </div>
  </div>
</template>

<script>
import AbilityDetails from './details.vue'

export default {
  name: 'innate',
  components: { AbilityDetails },
  props: {
    innate: {
      type: String,
      required: true
    },
    hidden: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    fallbackImage(e) {
      e.target.src = require('@/assets/imgs/innate.png')
    }
  },
  computed: {
    abilityDetails() {
      return this.getAbilityDetails(this.innate)
    },
    image() {
      if (this.hidden) {
        return require('@/assets/imgs/innate.png')
      }
      return this.abilityDetails.image
    }
  }
}
</script>

<style lang="scss" module>
@import '~@/assets/scss/_facets.scss';

.container {
  display: flex;
  flex-direction: column;
  border-radius: 0.1vw;
  border: 0.15vw solid rgba($color_black, 0.5);
  background: #1b1c26 url('~@/assets/imgs/tooltip-bg.png') center top no-repeat;
  background-size: 120%;
  box-shadow: 0 0.05vw 0.1vw rgba($color_black, 0.5);
}

.header {
  display: flex;
  height: 2.8vw;
}

.icon {
  width: 2.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconImg {
  width: 80%;
  border-radius: 0.2vw;
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  background: url('~@/assets/imgs/facet-bg.png') left top no-repeat;
  background-size: 150%;
  padding: 0.2vw 1vw;
  gap: 0.2vw;
}

.name {
  font-family: 'Reaver';
  color: $color_white;
  font-size: 0.8vw;
  font-weight: bold;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.tag {
  width: 5vw;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.5vw;
  color: #d9caa6;
  background: #4e4f4b;
  border-radius: 0.2vw;
  border: 0.05vw solid #605f57;
  padding: 0.05vw 0.1vw;
}

.content {
  padding: 0.4vw;
  position: relative;
}
</style>
