<template>
  <div>
    <div
      :class="[
        $style.container,
        { [$style.mouseOver]: mouseOver, [$style.open]: openedShop !== null }
      ]"
    >
      <div :class="$style.buttons">
        <div
          :class="[$style.button, { [$style.active]: openedShop === 'shop' }]"
          @click="setOpenShop('shop')"
        >
          <img :class="[$style.icon, $style.shop]" src="@/assets/imgs/shopkeeper/shop-icon.png" />
          <div :class="$style.label">Shop</div>
        </div>
        <div
          :class="[$style.button, { [$style.active]: openedShop === 'neutrals' }]"
          @click="setOpenShop('neutrals')"
        >
          <img
            :class="[$style.icon, $style.neutrals]"
            src="@/assets/imgs/shopkeeper/neutrals-icon.png"
          />
          <div :class="$style.label">Neutrals</div>
        </div>
      </div>
    </div>

    <shop />
    <neutrals />
  </div>
</template>

<script>
import Shop from './components/shopkeeper/shop.vue'
import Neutrals from './components/shopkeeper/neutrals.vue'

export default {
  name: 'shopkeeper',
  components: { Shop, Neutrals },
  computed: {
    mouseOver() {
      return this.$store.getters.mouseOver
    },
    openedShop() {
      return this.$store.getters.shopkeeper
    }
  },
  methods: {
    setOpenShop(item) {
      if (this.openedShop === item) {
        this.$store.commit('SetShopkeeper', null)
      } else {
        this.$store.commit('SetShopkeeper', item)
      }
    }
  }
}
</script>

<style lang="scss" module>
.container {
  position: absolute;
  bottom: 7vw;
  right: -15vw;
  height: 22vw;
  width: 16vw;
  background: url('~@/assets/imgs/shopkeeper/shopkeeper.png') right bottom no-repeat;
  background-size: contain;
  opacity: 0;
  transition: all ease 0.5s;
  transform: scale(0.5);
  transform-origin: bottom right;

  &.mouseOver,
  &.open {
    opacity: 1;
    right: 0;
  }

  &:hover,
  &.open {
    transform: scale(1);
  }
}

.buttons {
  position: absolute;
  right: 6.2vw;
  bottom: 1vw;
  display: flex;
  flex-direction: column;
  gap: 0.6vw;
  width: 12.2vw;
  transition: all ease 0.5s;
}

.button {
  line-height: 2vw;
  position: relative;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(19, 19, 19, 0.8) 100%);
  border: 0.1vw solid black;
  padding: 0.2vw 1vw 0.2vw 0.8vw;
  text-align: right;
  border-radius: 0.5vw;
  overflow: hidden;

  &.active {
    overflow: visible;
    border: 0.1vw solid $color_primary !important;

    .label {
      font-size: 1.6vw !important;
    }

    .icon {
      transform: scale(1.1) !important;
    }

    .shop {
      top: -2.6vw;
    }

    .neutrals {
      top: -1.5vw;
    }
  }

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(19, 19, 19, 0.6) 100%);
    border: 0.1vw solid rgba($color_primary, 0.5);
    cursor: pointer;

    .label {
      font-size: 1.55vw;
    }

    .icon {
      transform: scale(1.05);
    }
  }
}

.label {
  color: white;
  font-weight: bold;
  font-size: 1.5vw;
  text-transform: uppercase;
  text-shadow: text-shadow($color_black, 0.2vw);
  position: relative;
  z-index: 20;
  transition: all ease 0.5s;
}

.icon {
  position: absolute;
  z-index: 10;
  transition: all ease 0.5s;
  pointer-events: none;

  &.shop {
    height: 6vw;
    top: -2.4vw;
    left: -0.7vw;
  }

  &.neutrals {
    height: 4.5vw;
    top: -1.3vw;
    left: -1vw;
  }
}
</style>
