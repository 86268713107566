<template>
  <div :class="$style.container">
    <ability-behavior :ability="ability" :abilityDetails="abilityDetails" />
    <p
      v-if="abilityDetails.description !== ''"
      :class="$style.description"
      v-html="abilityDetails.description"
    ></p>
    <ability-highlight
      v-if="abilityDetails.use !== undefined"
      type="use"
      :abilityDetails="abilityDetails"
    />
    <ability-upgrade
      v-if="hasScepterUpgrade"
      type="scepter"
      :ability="ability"
      :abilityDetails="abilityDetails"
      :description="abilityDetails.scepter_upgrade"
    />
    <ability-upgrade
      v-if="hasShardUpgrade"
      type="shard"
      :ability="ability"
      :abilityDetails="abilityDetails"
      :description="abilityDetails.shard_upgrade"
    />
    <ability-attributes :ability="ability" :abilityDetails="abilityDetails" />
    <p v-if="abilityDetails.lore !== ''" :class="$style.lore">
      {{ abilityDetails.lore }}
    </p>
  </div>
</template>

<script>
import AbilityBehavior from './behavior.vue'
import AbilityAttributes from './attributes.vue'
import AbilityUpgrade from './upgrade.vue'
import AbilityHighlight from './highlight.vue'

export default {
  name: 'ability-details',
  props: ['ability', 'abilityDetails', 'aghanims'],
  components: { AbilityBehavior, AbilityAttributes, AbilityUpgrade, AbilityHighlight },
  computed: {
    hasScepterUpgrade() {
      return this.aghanims.scepter && this.abilityDetails.scepter_upgrade !== undefined
    },
    hasShardUpgrade() {
      return this.aghanims.shard && this.abilityDetails.shard_upgrade !== undefined
    }
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.description {
  color: $color_bluishgrey;
  font-size: 0.7rem;
  padding: 0.2rem 0.4rem;

  strong {
    color: white;
    font-size: 0.85rem;
  }
}

.lore {
  color: rgba($color_bluishgrey, 0.6);
  font-size: 0.6rem;
  font-style: italic;
  padding: 0.4rem 0.8rem;
  background: rgba($color_black, 0.4);
  border-radius: 0.2rem;
}
</style>
