<template>
  <div :class="$style.container" v-if="itemDetails.attributes">
    <ul :class="$style.attributes">
      <li
        :class="$style.attribute"
        v-for="(attribute, index) in itemDetails.attributes"
        :key="`${attribute.key}-attr-${index}`"
      >
        <span :class="$style.header" v-html="attribute.header"></span>
        <span :class="$style.valueBlock">
          <template v-if="isSpecialItem">
            <span :class="clockTime < 25 ? $style.value : $style.inactive">{{
              attribute.value[0]
            }}</span>
            <span :class="$style.separator">/</span>
            <span :class="clockTime >= 25 ? $style.value : $style.inactive">{{
              attribute.value[1]
            }}</span>
          </template>
          <template v-else>
            <span :class="$style.value">{{ formatValue(attribute.value) }}</span>
          </template>
        </span>
        <span :class="$style.footer" v-html="attribute.footer"></span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'item-attributes',
  props: ['itemDetails'],
  computed: {
    isSpecialItem() {
      return ['wraith_band', 'null_talisman', 'bracer'].includes(this.itemDetails.data_name)
    },
    clockTime() {
      return Math.floor(this.$store.getters.clockTime / 60)
    }
  },
  methods: {
    formatValue(value) {
      if (Array.isArray(value)) {
        return value.join(' / ')
      }
      return value
    }
  }
}
</script>

<style lang="scss" module>
.container {
  padding: 0.4rem 0.2rem 0 0.4rem;
}

.attributes {
  list-style: none;
}

.attribute {
  margin-bottom: 0.1rem;
  font-size: 0.8rem;
  letter-spacing: 0 !important;
}

.header {
  color: $color_lightbluishgrey;
  margin-right: 0.1rem;
}

.valueBlock {
  margin-right: 0.2rem;
}

.value {
  color: $color_white;
  font-weight: bold;
}

.inactive {
  color: rgba($color_lightbluishgrey, 0.6);
}

.separator {
  color: $color_lightbluishgrey;
  padding: 0 0.1rem;
}

.footer {
  color: $color_lightbluishgrey;
}
</style>
