<template>
  <div :class="[$style.container, $style[abilityClass]]">
    <div
      :class="[
        $style.ability,
        { [$style.skip]: ability.skip, [$style.indicator]: !ability.skip && showIndicators }
      ]"
      v-for="ability in abilities"
      :key="ability.name"
      @click="previewAbility(ability)"
    >
      <template v-if="!ability.skip">
        <div :class="$style.icon">
          <font-awesome :class="$style.ficon" icon="fa-play" />
        </div>
        <ability-tooltip :ability="ability" :aghanims="aghanimsStatus" position="top" />
      </template>
    </div>
  </div>
</template>

<script>
import AbilityTooltip from '../tooltips/ability-tooltip.vue'

export default {
  name: 'hud-abilities',
  components: { AbilityTooltip },
  methods: {
    previewAbility(ability) {
      if (ability.skip) {
        return false
      }
      this.$store.commit('SetPreviewedAbility', { ...ability, hero: this.heroName })
    }
  },
  computed: {
    selectedPlayer() {
      return this.$store.getters.selectedPlayer
    },
    abilities() {
      if (this.selectedPlayer) {
        return this.selectedPlayer.abilities
      }
      return []
    },
    heroName() {
      if (this.selectedPlayer) {
        return this.selectedPlayer.hero.name
      }
      return ''
    },
    abilityClass() {
      if (this.selectedPlayer) {
        return `ability_${this.abilities.length}`
      }
      return ''
    },
    aghanimsStatus() {
      if (this.selectedPlayer) {
        const hero = this.selectedPlayer.hero
        return {
          scepter: hero.aghanims_scepter,
          shard: hero.aghanims_shard
        }
      }

      return {
        scepter: false,
        shard: false
      }
    },
    showIndicators() {
      return this.$store.getters.showIndicators
    }
  }
}
</script>

<style lang="scss" module>
@keyframes indicator {
  0% {
    box-shadow: inset 0 0 0.4vw rgba($color_primary, 0.9);
  }
  50% {
    box-shadow: inset 0 0 0.2vw rgba($color_primary, 0.9);
  }
}

.container {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0.52vw;
  left: 13.6vw;
  width: 16.2vw;
  padding: 0 0.6vw;

  .ability {
    position: relative;
    width: 2.8vw;
    height: 2.75vw;
    transition: all ease-in-out 0.4s;

    &.indicator {
      animation: indicator linear 1s infinite alternate;
    }

    &:not(.skip):hover {
      box-shadow: inset 0 0 0.4vw rgba($color_primary, 0.9);
      background: rgba($color_black, 0.4);

      .icon {
        opacity: 1;
      }
    }
  }

  &.ability_5 {
    padding: 0 0 0 0.2vw;

    .ability {
      width: 2.8vw;
      height: 2.8vw;
    }
  }

  &.ability_6 {
    padding: 0;

    .ability {
      width: 2.52vw;
      height: 2.72vw;
    }
  }
}

.icon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  opacity: 0;
  align-items: center;
  justify-content: center;
  transition: opacity ease-in-out 0.4s;
  font-size: 1.5vw;
  color: rgba($color_white, 0.8);
}

.ficon {
  filter: drop-shadow(0 0 0.1vw $color_black);
}
</style>
