import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueRouter from 'vue-router'
import router from './router'
import VueSSE from './sse'
import StaticData from './data'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faTimes,
  faPlay,
  faCircleChevronDown,
  faCircleChevronUp,
  faCircleInfo,
  faChartSimple,
  faSackDollar,
  faCoins,
  faHeartCirclePlus,
  faHouseCrack,
  faChartLine,
  faSkullCrossbones,
  faCircleXmark,
  faCircleCheck,
  faSquareXmark,
  faCampground,
  faCheck,
  faClock,
  faChevronDown,
  faGear
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import axios from 'axios'
import VueAxios from 'vue-axios'

// Font Awesome
library.add(
  faTimes,
  faPlay,
  faCircleChevronDown,
  faCircleChevronUp,
  faChartSimple,
  faCircleInfo,
  faSackDollar,
  faCoins,
  faHeartCirclePlus,
  faHouseCrack,
  faChartLine,
  faSkullCrossbones,
  faCircleXmark,
  faCircleCheck,
  faSquareXmark,
  faCampground,
  faCheck,
  faClock,
  faChevronDown,
  faGear
)
Vue.component('font-awesome', FontAwesomeIcon)

// Axios
Vue.use(VueAxios, axios)

// SSE
Vue.use(VueSSE, {
  baseUrl: process.env.VUE_APP_BROADCAST_SERVER_URL,
  vuexPrefix: 'PGL_'
})

// Static Data
Vue.use(StaticData)

Vue.use(VueRouter)

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: (h) => h(App)
}).$mount('#app')
