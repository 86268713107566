<template>
  <div :class="$style.bottomHud" v-if="player">
    <hud-talent-tree :withAttributes="true" />
    <hud-innate-facet />
    <hud-abilities />
    <hud-aghanims />
    <hud-inventory />
    <div :class="$style.exp">
      <exp-tooltip
        position="top"
        :level="player.hero.level"
        :value="player.hero.xp - prevExpFloor"
        :max="expToNextLevel - prevExpFloor"
      />
    </div>
  </div>
</template>

<script>
import HudAbilities from './components/hud/abilities.vue'
import HudTalentTree from './components/hud/talent-tree.vue'
import HudInnateFacet from './components/hud/innate-facet.vue'
import HudAghanims from './components/hud/aghanims.vue'
import HudInventory from './components/hud/inventory.vue'
import ExpTooltip from './components/tooltips/exp-tooltip.vue'

export default {
  name: 'bottom-hud',
  components: {
    HudAbilities,
    HudTalentTree,
    HudAghanims,
    HudInventory,
    HudInnateFacet,
    ExpTooltip
  },
  computed: {
    player() {
      return this.$store.getters.selectedPlayer
    },
    prevExpFloor() {
      return this.getXpToLevel(this.player.hero.level - 1)
    },
    expToNextLevel() {
      return this.getXpToLevel(this.player.hero.level)
    }
  }
}
</script>

<style lang="scss" module>
.bottomHud {
  position: absolute;
  bottom: 0vw;
  left: 27.4vw;
  height: 7.6vw;
  width: 45vw;
}

.exp {
  position: absolute;
  bottom: 0.25vw;
  left: -1.64vw;
  height: 2vw;
  width: 2vw;
  border-radius: 50%;

  &:hover {
    box-shadow: 0 0 0.4vw rgba($color_primary, 0.9);
  }
}
</style>
