<template>
  <div :class="$style.container">
    <div v-for="team in teams" :class="[$style.team, $style[team]]" :key="`team-${team}`">
      <hero-tooltip
        v-for="(hero, index) in getHeroes(team)"
        :hero="hero"
        :team="team"
        :key="`${team}-${index}`"
      />
    </div>
  </div>
</template>

<script>
import HeroTooltip from './components/tooltips/hero-tooltip.vue'

export default {
  name: 'top-bar',
  components: {
    HeroTooltip
  },
  data: () => {
    return {
      teams: ['radiant', 'dire']
    }
  },
  methods: {
    getHeroes(team) {
      if (team === 'radiant') {
        return this.$store.getters.radiantTeam
      }
      return this.$store.getters.direTeam
    }
  }
}
</script>

<style lang="scss" module>
@keyframes float {
  0% {
    transform: translate(-50%, 0);
  }
  50% {
    transform: translate(-50%, -0.2vw);
  }
  100% {
    transform: translate(-50%, 0);
  }
}

.indicator {
  position: absolute;
  bottom: -4vw;
  left: 50%;
  transform: translateX(-50%);
  animation: float linear 1s infinite;
}

.container {
  position: absolute;
  top: 0vw;
  left: 28.35vw;
  height: 2.8vw;
  width: 43.3vw;
  display: flex;
  justify-content: space-between;
}

.team {
  display: flex;
  justify-content: space-evenly;
  width: 16.4vw;
}
</style>
