<template>
  <div
    v-if="talents"
    :class="[
      $style.container,
      $style[position],
      { [$style.withAttributes]: withAttributes, [$style.invoker]: hero.name === 'invoker' }
    ]"
  >
    <div :class="$style.title">Talent Tree</div>
    <div :class="$style.levels">
      <img v-show="isOgreCustom" src="@/assets/imgs/talents/ogre.png" :class="$style.ogre" />
      <talent-level level="25" :talent1="talents.talent_7" :talent2="talents.talent_8" />
      <talent-level level="20" :talent1="talents.talent_5" :talent2="talents.talent_6" />
      <talent-level level="15" :talent1="talents.talent_3" :talent2="talents.talent_4" />
      <talent-level level="10" :talent1="talents.talent_1" :talent2="talents.talent_2" />
    </div>
    <div :class="$style.attributes" v-if="hero.attributes_level >= 0">
      <div :class="$style.label">+2 to all attributes</div>
      <div :class="$style.lvl">
        <div
          :class="[$style.notch, { [$style.active]: hero.attributes_level >= level }]"
          v-for="level in 7"
          :key="`attribute-level-${level}`"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import TalentLevel from '../talent-details/level.vue'

export default {
  name: 'talent-details',
  components: { TalentLevel },
  props: {
    position: {
      type: String
    },
    hero: {
      type: Object
    },
    withAttributes: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    talents() {
      if (this.hero) {
        const talents = this.getTalents(this.hero.name)
        if (talents) {
          const talentsObj = {}
          Object.keys(talents).map((talent) => {
            talentsObj[talent] = {
              label: talents[talent],
              active: this.hero[talent] ?? false
            }
          })

          return talentsObj
        }
      }
      return false
    },
    isOgreCustom() {
      return this.hero.name === 'ogre_magi' && this.hero.facet === 2
    }
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  align-items: center;
  padding: 0.4rem 0.5rem;

  &.top,
  &.right,
  &.lower-left,
  &.lower-right {
    background: url('~@/assets/imgs/talents/container-top.png') center top no-repeat;
    background-size: contain;
    justify-content: flex-start;
    padding-bottom: 0;
    flex-direction: column;

    &.withAttributes {
      background-image: url('~@/assets/imgs/talents/container-top-attributes.png');
    }
  }

  &.top {
    .title {
      line-height: 1.55rem;
    }
    .levels {
      margin-bottom: 0.4rem;
    }
  }

  &.right {
    margin-left: -0.3rem;
    .levels {
      margin-bottom: 0.8rem;
    }
  }

  &.bottom {
    background: url('~@/assets/imgs/talents/container-bottom.png') center bottom no-repeat;
    background-size: contain;
    justify-content: flex-end;
    padding-top: 0;
    flex-direction: column-reverse;

    &.withAttributes {
      background-image: url('~@/assets/imgs/talents/container-bottom-attributes.png');
    }

    .levels {
      margin-top: 0.6rem;
    }
  }

  &.invoker {
    background-size: 100% auto;
    height: 12.3rem;

    &.bottom {
      .attributes {
        height: 0;
        margin-top: -0.7rem;
      }
    }

    &.right {
      height: 12.4rem;
    }

    .attributes {
      visibility: hidden;
    }
  }
}

.title {
  color: $color_white;
  text-align: center;
  font-family: 'Reaver';
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: bold;
  line-height: 1.7rem;
  text-shadow: 0.05rem 0.05rem 0.2rem $color_black;
}

.levels {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.ogre {
  height: 100%;
  position: absolute;
  z-index: 50;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.attributes {
  width: 10rem;
  height: 2.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.4rem;
}

.label {
  color: white;
  font-size: 0.6rem;
  font-weight: bold;
  text-shadow: 0.05rem 0.05rem 0.2rem $color_black;
}

.lvl {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
}

.notch {
  width: 0.5rem;
  height: 0.3rem;
  border-radius: 0.1rem;
  border: 0.05rem solid rgba(black, 0.6);
  background: linear-gradient(90deg, rgb(27, 27, 27) 0%, rgb(0, 0, 0) 100%);

  &.active {
    border: none;
    background: linear-gradient(180deg, rgba(231, 210, 145, 1) 0%, rgba(136, 120, 69, 1) 100%);
  }
}

.lower-left {
  margin-right: -1.5rem;
  padding: 0.4rem 1.5rem;
  transform: translateX(-0.2rem);

  &.container {
    width: 21.5rem;
    height: 12.55rem;
  }

  .title {
    line-height: 1.5rem;
  }
}

.lower-right {
  margin-left: -1.3rem;
  padding: 0.4rem 1.5rem;

  &.container {
    width: 21.5rem;
    height: 12.55rem;
  }

  .title {
    line-height: 1.5rem;
  }
}
</style>
