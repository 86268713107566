<template>
  <div :class="[$style.container, { [$style.open]: isOpen }]">
    <div :class="$style.tabs">
      <div
        :class="[$style.tab, { [$style.open]: activeTab === 'basics' }]"
        @click="setTab('basics')"
      >
        Basics
      </div>
      <div
        :class="[$style.tab, { [$style.open]: activeTab === 'upgrades' }]"
        @click="setTab('upgrades')"
      >
        Upgrades
      </div>
    </div>

    <item-grid v-show="activeTab === 'basics'" :categories="basics" />
    <item-grid v-show="activeTab === 'upgrades'" :categories="upgrades" />

    <div :class="$style.close" @click="close">
      Close <font-awesome :class="$style.ficon" icon="fa-close" />
    </div>
  </div>
</template>

<script>
import ItemGrid from './item-grid.vue'

export default {
  name: 'shop',
  components: { ItemGrid },
  data() {
    return {
      activeTab: 'basics'
    }
  },
  computed: {
    isOpen() {
      return this.$store.getters.shopkeeper === 'shop'
    },
    basics() {
      return this.getShopkeeperItems('basics')
    },
    upgrades() {
      return this.getShopkeeperItems('upgrades')
    }
  },
  methods: {
    setTab(tab) {
      this.activeTab = tab
    },
    close() {
      this.$store.commit('SetShopkeeper', null)
    }
  }
}
</script>

<style lang="scss" module>
.container {
  position: absolute;
  right: -30vw;
  bottom: 15vw;
  width: 22vw;
  height: 30vw;
  background: linear-gradient(180deg, rgba(#162024, 0.95) 40%, rgba(#162024, 0.8) 100%);
  box-shadow: 0 0.05vw 0.2vw rgba($color_black, 0.5);
  border: 0.2vw solid rgba($color_black, 0.5);
  border-radius: 0.3vw;
  z-index: 50;
  opacity: 0;
  transition: opacity ease 0.5s, right ease 0.5s;

  &.open {
    opacity: 1;
    right: 6vw;
  }
}

.tabs {
  position: absolute;
  bottom: 100%;
  display: flex;
  gap: 0.3vw;
  margin-left: 0.2vw;
}

.tab {
  background: linear-gradient(180deg, #1f1f1f 40%, #141414 100%);
  border: 0.2vw solid rgba($color_black, 0.5);
  padding: 0.3vw 1vw 0.2vw 1vw;
  border-radius: 0.4vw 0.4vw 0 0;
  color: white;
  font-size: 0.9vw;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    text-shadow: text-shadow(rgba($color_primary, 0.6), 0.2vw);
  }

  &.open {
    background: linear-gradient(180deg, rgba(#162024, 0.95) 40%, rgba(#162024, 0.8) 100%);
    border-bottom: none;
    text-shadow: text-shadow(rgba($color_primary, 0.4), 0.2vw);
  }
}

.content {
  flex: 1;
  border-top: 0.2vw solid rgba($color_black, 0.8);
}

.close {
  position: absolute;
  top: -1.9vw;
  right: 0;
  text-transform: uppercase;
  text-shadow: none;
  font-weight: bold;
  color: $color_white;
  font-size: 0.8vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3vw 0.6vw;
  border-radius: 0.2vw;
  background: rgba(rgb(143, 0, 0), 0.8);
  cursor: pointer;

  &:hover {
    transition: all ease 0.5s;
    background: rgba(rgb(143, 0, 0), 1);
  }

  .ficon {
    margin-left: 0.4vw;
    font-size: 1vw;
  }
}
</style>
