var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.draft)?_c('div',{class:_vm.$style.container},[_c('indicator',{class:_vm.$style.indicator,attrs:{"flag":!_vm.heroSelected && _vm.draftReady && _vm.showIndicator}},[_vm._v(" Click on heroes to view their abilities, talents, upgrades, stats, and more! ")]),_vm._l((_vm.teams),function(team){return _c('div',{key:("team-" + team),class:[_vm.$style.team, _vm.$style[team]]},[_c('draft-hero',{attrs:{"team":team}}),_c('div',{class:_vm.$style.picks},_vm._l((_vm.draft[team].picks),function(pick,index){
var _obj;
return _c('div',{key:(team + "-pick-" + index),class:[
          _vm.$style.pick,
          ( _obj = {}, _obj[_vm.$style.active] = pick.hero_id > 0, _obj[_vm.$style.selected] = _vm.isSelected(team, pick.hero_id), _obj )
        ],on:{"click":function($event){return _vm.selectDraftHero(team, Object.assign({}, pick, {type: 'picked'}))}}})}),0),_c('div',{class:_vm.$style.bans},_vm._l((_vm.draft[team].bans),function(ban,index){
        var _obj;
return _c('div',{key:(team + "-ban-" + index),class:[
          _vm.$style.ban,
          ( _obj = {}, _obj[_vm.$style.active] = ban.hero_id > 0, _obj[_vm.$style.selected] = _vm.isSelected(team, ban.hero_id), _obj )
        ],on:{"click":function($event){return _vm.selectDraftHero(team, Object.assign({}, ban, {type: 'banned'}))}}})}),0)],1)})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }