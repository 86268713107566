<template>
  <div :class="$style.container" v-if="draft">
    <indicator :flag="!heroSelected && draftReady && showIndicator" :class="$style.indicator">
      Click on heroes to view their abilities, talents, upgrades, stats, and more!
    </indicator>
    <div :class="[$style.team, $style[team]]" v-for="team in teams" :key="`team-${team}`">
      <draft-hero :team="team" />
      <div :class="$style.picks">
        <div
          :class="[
            $style.pick,
            {
              [$style.active]: pick.hero_id > 0,
              [$style.selected]: isSelected(team, pick.hero_id)
            }
          ]"
          v-for="(pick, index) in draft[team].picks"
          :key="`${team}-pick-${index}`"
          @click="selectDraftHero(team, { ...pick, type: 'picked' })"
        ></div>
      </div>
      <div :class="$style.bans">
        <div
          :class="[
            $style.ban,
            {
              [$style.active]: ban.hero_id > 0,
              [$style.selected]: isSelected(team, ban.hero_id)
            }
          ]"
          v-for="(ban, index) in draft[team].bans"
          :key="`${team}-ban-${index}`"
          @click="selectDraftHero(team, { ...ban, type: 'banned' })"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import DraftHero from './components/draft/hero.vue'
import Indicator from './components/common/Indicator.vue'

export default {
  name: 'Draft',
  components: { DraftHero, Indicator },
  data() {
    return {
      timeout: null,
      showIndicator: false
    }
  },
  computed: {
    teams() {
      return ['radiant', 'dire']
    },
    draft() {
      return this.$store.getters.draft
    },
    radiantDraft() {
      return this.$store.getters.selectedRadiantDraft
    },
    direDraft() {
      return this.$store.getters.selectedDireDraft
    },
    draftReady() {
      return (
        this.draft.radiant.picks.filter((pick) => pick.hero_id !== 0).length >= 2 &&
        this.draft.dire.picks.filter((pick) => pick.hero_id !== 0).length >= 2
      )
    },
    heroSelected() {
      return this.radiantDraft !== null || this.direDraft !== null
    },
    matchId() {
      return this.$store.getters.matchId
    }
  },
  methods: {
    isSelected(team, hero) {
      if (team === 'radiant') {
        return this.radiantDraft !== null && this.radiantDraft.hero_id === hero
      } else {
        return this.direDraft !== null && this.direDraft.hero_id === hero
      }
    },
    selectDraftHero(team, data) {
      clearTimeout(this.timeout)
      if (data !== null && data.hero_id > 0) {
        let mutation = ''
        if (team === 'radiant') {
          mutation = 'SetRadiantDraft'
          if (this.radiantDraft !== null && this.radiantDraft.hero_id === data.hero_id) {
            data = null
          }
        } else {
          mutation = 'SetDireDraft'
          if (this.direDraft !== null && this.direDraft.hero_id === data.hero_id) {
            data = null
          }
        }

        this.$store.commit(mutation, null)
        if (data !== null) {
          this.timeout = setTimeout(() => {
            this.$store.commit(mutation, data)
          }, 500)
        }
      }
    }
  },
  mounted() {
    const lastMatchId = localStorage.getItem('lastMatchIdDraft')

    if (this.matchId !== lastMatchId) {
      this.$store.commit('ShowIndicators')
      localStorage.setItem('lastMatchIdDraft', this.matchId)
      this.showIndicator = true
    }
  }
}
</script>

<style lang="scss" module>
@keyframes float {
  0% {
    transform: translate(-50%, -0.2vw);
  }
  50% {
    transform: translate(-50%, 0);
  }
  100% {
    transform: translate(-50%, -0.2vw);
  }
}

@keyframes indicator {
  0% {
    box-shadow: inset 0 0 0.4vw $color_primary;
  }
  50% {
    box-shadow: inset 0 0 0.2vw $color_primary;
  }
}

.container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  height: 14.6vw;
}

.indicator {
  width: 40vw;
  font-size: 1vw;
  position: absolute;
  top: -3vw;
  left: 50%;
  animation: float linear 2s infinite;
}

.team {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .picks {
    display: flex;
    width: 40.2vw;
    justify-content: space-between;

    .pick {
      width: 7.9vw;
      height: 11.3vw;
      border-radius: 0.4vw;
    }
  }

  .bans {
    display: flex;
    justify-content: space-between;

    .ban {
      width: 5.6vw;
      height: 3.2vw;
      border-radius: 0.2vw;
    }
  }
}

.dire {
  .picks {
    flex-direction: row-reverse;
  }

  .bans {
    flex-direction: row-reverse;
  }
}

.pick.active,
.ban.active {
  transition: all ease 0.3s;
  cursor: pointer;
  animation: indicator 1s linear 0s 3 normal forwards;

  &:hover {
    box-shadow: inset 0 0 0.4vw $color_primary;
  }

  &.selected {
    border: 0.2vw solid rgba($color_primary, 0.8);
  }
}
</style>
