var render = function () {
var _obj, _obj$1, _obj$2, _obj$3, _obj$4;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.withAttributes || _vm.withCooldown || _vm.withManaCost || _vm.withHealthCost || _vm.withCastRange)?_c('div',{class:[_vm.$style.container, ( _obj = {}, _obj[_vm.$style.noPadding] = _vm.noPadding, _obj )]},[(_vm.withAttributes)?_c('ul',{class:_vm.$style.attributes},_vm._l((_vm.filteredAttributes),function(attribute,index){
var _obj, _obj$1;
return _c('li',{key:((_vm.ability.name) + "-attr-" + index),class:_vm.$style.attribute},[(Array.isArray(attribute.value))?[_c('span',{class:_vm.$style.header,domProps:{"innerHTML":_vm._s(attribute.header)}}),_vm._l((attribute.value),function(value,vi){
var _obj, _obj$1;
return [(vi > 0)?_c('span',{key:((attribute.key) + "-" + vi + "-slash")},[_vm._v(" / ")]):_vm._e(),_c('span',{key:((attribute.key) + "-" + vi + "-value"),class:[
              ( _obj = {}, _obj[_vm.$style[_vm.damageType]] = _vm.isDamage(attribute.key), _obj ),
              ( _obj$1 = {}, _obj$1[_vm.$style.value] = vi + 1 === _vm.getAbilityLevel(_vm.ability, attribute.header) || _vm.highlightAll, _obj$1 )
            ]},[_vm._v(_vm._s(value))])]})]:_vm._e(),(!Array.isArray(attribute.value))?[_c('span',{class:_vm.$style.header,domProps:{"innerHTML":_vm._s(attribute.header)}}),_c('span',{class:[
            ( _obj = {}, _obj[_vm.$style[_vm.damageType]] = _vm.isDamage(attribute.key), _obj ),
            ( _obj$1 = {}, _obj$1[_vm.$style.value] = _vm.getAbilityLevel(_vm.ability, attribute.header) > 0 || _vm.highlightAll, _obj$1 )
          ]},[_vm._v(_vm._s(attribute.value))])]:_vm._e()],2)}),0):_vm._e(),(_vm.withCooldown || _vm.withManaCost || _vm.withHealthCost || _vm.withCastRange)?_c('div',{class:_vm.$style.manaCd},[(_vm.withCooldown)?_c('div',{class:_vm.$style.cooldown},[_c('img',{class:_vm.$style.icon,attrs:{"src":require("@/assets/imgs/cd.png")}}),_c('div',[(Array.isArray(_vm.abilityDetails.cooldown))?[_vm._l((_vm.abilityDetails.cooldown),function(value,vi){
          var _obj;
return [(vi > 0)?_c('span',{key:((_vm.ability.name) + "-cd-" + vi + "-slash")},[_vm._v(" / ")]):_vm._e(),_c('span',{key:((_vm.ability.name) + "-cd-" + vi + "-value"),class:( _obj = {}, _obj[_vm.$style.value] = vi + 1 === _vm.ability.level || _vm.highlightAll, _obj )},[_vm._v(_vm._s(value))])]})]:[_c('span',{class:( _obj$1 = {}, _obj$1[_vm.$style.value] = _vm.ability.level > 0 || _vm.highlightAll, _obj$1 )},[_vm._v(_vm._s(_vm.abilityDetails.cooldown))])]],2)]):_vm._e(),(_vm.withManaCost)?_c('div',{class:_vm.$style.manacost},[_c('img',{class:_vm.$style.icon,attrs:{"src":require("@/assets/imgs/mana.png")}}),_c('div',[(Array.isArray(_vm.abilityDetails.mana_cost))?[_vm._l((_vm.abilityDetails.mana_cost),function(value,vi){
          var _obj;
return [(vi > 0)?_c('span',{key:((_vm.ability.name) + "-mana-" + vi + "-slash")},[_vm._v(" / ")]):_vm._e(),_c('span',{key:((_vm.ability.name) + "-mana-" + vi + "-value"),class:( _obj = {}, _obj[_vm.$style.value] = vi + 1 === _vm.ability.level || _vm.highlightAll, _obj )},[_vm._v(_vm._s(value))])]})]:[_c('span',{class:( _obj$2 = {}, _obj$2[_vm.$style.value] = _vm.ability.level > 0, _obj$2 )},[_vm._v(_vm._s(_vm.abilityDetails.mana_cost))])]],2)]):_vm._e(),(_vm.withHealthCost)?_c('div',{class:_vm.$style.healthcost},[_c('img',{class:_vm.$style.icon,attrs:{"src":require("@/assets/imgs/health.png")}}),_c('div',[(Array.isArray(_vm.abilityDetails.health_cost))?[_vm._l((_vm.abilityDetails.health_cost),function(value,vi){
          var _obj;
return [(vi > 0)?_c('span',{key:((_vm.ability.name) + "-health-" + vi + "-slash")},[_vm._v(" / ")]):_vm._e(),_c('span',{key:((_vm.ability.name) + "-health-" + vi + "-value"),class:( _obj = {}, _obj[_vm.$style.value] = vi + 1 === _vm.ability.level || _vm.highlightAll, _obj )},[_vm._v(_vm._s(value))])]})]:[_c('span',{class:( _obj$3 = {}, _obj$3[_vm.$style.value] = _vm.ability.level > 0, _obj$3 )},[_vm._v(_vm._s(_vm.abilityDetails.health_cost))])]],2)]):_vm._e(),(_vm.withCastRange)?_c('div',{class:_vm.$style.castrange},[_c('img',{class:_vm.$style.icon,attrs:{"src":require("@/assets/imgs/cast_range.png")}}),_c('div',[(Array.isArray(_vm.abilityDetails.cast_range))?[_vm._l((_vm.abilityDetails.cast_range),function(value,vi){
          var _obj;
return [(vi > 0)?_c('span',{key:((_vm.ability.name) + "-castrange-" + vi + "-slash")},[_vm._v(" / ")]):_vm._e(),_c('span',{key:((_vm.ability.name) + "-castrange-" + vi + "-value"),class:( _obj = {}, _obj[_vm.$style.value] = vi + 1 === _vm.ability.level || _vm.highlightAll, _obj )},[_vm._v(_vm._s(value))])]})]:[_c('span',{class:( _obj$4 = {}, _obj$4[_vm.$style.value] = _vm.ability.level > 0, _obj$4 )},[_vm._v(_vm._s(_vm.abilityDetails.cast_range))])]],2)]):_vm._e()]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }