<template>
  <div :class="$style.container">
    <div :class="[$style.header, $style[facetColor]]">
      <div :class="$style.icon">
        <img :src="icon" :class="$style.iconImg" />
      </div>
      <div :class="$style.title">{{ facet.name }}</div>
    </div>
    <div v-if="stats" :class="$style.stats">
      <div :class="$style.stat">
        <div :class="$style.label">Picks:</div>
        <div :class="$style.value">{{ stats.picks }}</div>
        <div :class="$style.percentage">{{ stats.pickrate }}</div>
      </div>
      <div :class="$style.stat">
        <div :class="$style.label">Wins:</div>
        <div :class="$style.value">{{ stats.wins }}</div>
        <div :class="$style.percentage">{{ stats.winrate }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'basic-facet',
  props: {
    facet: {
      type: Object,
      required: true
    },
    stats: {
      type: Object,
      required: false
    }
  },
  computed: {
    icon() {
      return require(`@/assets/imgs/facets/${this.facet.icon}_png.png`)
    },
    facetColor() {
      return `facet_gradient_${this.facet.color}`
    }
  }
}
</script>

<style lang="scss" module>
@import '~@/assets/scss/_facets.scss';

.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 0.15vw solid rgba($color_black, 0.5);
  background: #1b1c26 url('~@/assets/imgs/tooltip-bg.png') center top no-repeat;
  background-size: 100%;
  box-shadow: 0 0.05vw 0.1vw rgba($color_black, 0.5);
  border-radius: 0.4vw;
}

.header {
  display: flex;
  height: 1.8vw;
}

.icon {
  width: 1.8vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1));
}

.iconImg {
  height: 50%;
}

.title {
  flex: 1;
  padding: 0 0.6vw;
  font-family: 'Reaver';
  color: $color_white;
  font-size: 0.8vw;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.8vw;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background: url('~@/assets/imgs/facet-bg.png') left top no-repeat;
  background-size: 150%;
}

.stats {
  display: flex;
  justify-content: center;
  gap: 0.8vw;
  padding: 0 0.6vw;
  background: rgba(black, 0.9);

  .stat {
    display: flex;
    justify-content: center;
    align-items: center;

    .label {
      color: white;
      font-size: 0.7vw;
      text-transform: uppercase;
      margin-right: 0.1vw;
      margin-top: 0.1vw;
    }

    .value {
      font-size: 1vw;
      font-weight: bold;
      margin: 0 0.2vw;
      color: #a9cf54;
    }

    .percentage {
      font-weight: bold;
      font-size: 0.7vw;
      color: #76b8a6;
      margin-left: 0.1vw;
    }
  }
}
</style>
