<template>
  <div :class="[$style.container, { [$style.indicator]: showIndicators }]">
    <talent-tooltip position="top" :hero="hero" :withAttributes="true" />
  </div>
</template>

<script>
import TalentTooltip from '../tooltips/talent-tooltip.vue'

export default {
  name: 'hud-talent-tree',
  components: { TalentTooltip },
  computed: {
    hero() {
      return this.$store.getters.selectedPlayer.hero
    },
    showIndicators() {
      return this.$store.getters.showIndicators
    }
  }
}
</script>

<style lang="scss" module>
@keyframes indicator {
  0% {
    box-shadow: 0 0 0.4vw rgba($color_primary, 0.9);
  }
  50% {
    box-shadow: 0 0 0.2vw rgba($color_primary, 0.9);
  }
}

.container {
  position: absolute;
  top: 0.7vw;
  left: 9.15vw;
  height: 2.6vw;
  width: 2.6vw;
  border-radius: 50%;
  cursor: pointer;
  transition: all ease-in-out 0.4s;

  &.indicator {
    animation: indicator linear 1s infinite alternate;
  }

  &:hover {
    box-shadow: 0 0 0.4vw rgba($color_primary, 0.9);
  }
}
</style>
