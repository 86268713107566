<template>
  <div :class="$style.container">
    <div :class="$style.list">
      <template v-for="(item, index) in stats">
        <transition name="grow-in" :key="`stats-${index}`">
          <div
            :class="$style.item"
            v-if="item.type === active"
            :style="`animation-delay: ${index * 0.05}s;`"
            @click="setQuickStat(item)"
          >
            <font-awesome :class="$style.ficon" :icon="item.icon" />
            {{ item.label }}
          </div>
        </transition>
      </template>
    </div>
    <div :class="$style.list">
      <template v-for="(item, index) in status">
        <transition name="grow-in" :key="`status-${index}`">
          <div
            :class="$style.item"
            v-if="item.type === active"
            :style="`animation-delay: ${index * 0.05}s;`"
            @click="setQuickStat(item)"
          >
            <font-awesome :class="$style.ficon" :icon="item.icon" />
            {{ item.label }}
          </div>
        </transition>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'quick-stats-list',
  props: ['active'],
  data() {
    return {
      stats: [
        {
          type: 'stats',
          label: 'Net Worth',
          value: 'net_worth',
          icon: 'fa-sack-dollar'
        },
        {
          type: 'stats',
          label: 'GPM',
          value: 'gpm',
          icon: 'fa-coins'
        },
        {
          type: 'stats',
          label: 'XPM',
          value: 'xpm',
          icon: 'fa-chart-line'
        },
        {
          type: 'stats',
          label: 'Kill Participation',
          value: 'kill_participation',
          icon: 'fa-skull-crossbones'
        },
        {
          type: 'stats',
          label: 'Hero Damage',
          value: 'hero_damage',
          icon: 'fa-chart-simple'
        },
        {
          type: 'stats',
          label: 'Hero Healing',
          value: 'hero_healing',
          icon: 'fa-heart-circle-plus'
        },
        {
          type: 'stats',
          label: 'Building Damage',
          value: 'building_damage',
          icon: 'fa-house-crack'
        },
        {
          type: 'stats',
          label: 'Camps Stacked',
          value: 'camps_stacked',
          icon: 'campground'
        }
      ]
    }
  },
  computed: {
    winProbability() {
      return this.$store.getters.winProbability
    },
    status() {
      const statuses = [
        {
          type: 'status',
          label: 'Level & Exp',
          value: 'level',
          icon: 'fa-circle-info'
        },
        {
          type: 'status',
          label: 'Ultimates',
          value: 'ultimate',
          icon: 'fa-circle-info'
        },
        {
          type: 'status',
          label: 'Buyback',
          value: 'buyback',
          icon: 'fa-circle-info'
        },
        {
          type: 'status',
          label: 'BKB',
          value: 'bkb',
          icon: 'fa-circle-info'
        },
        {
          type: 'status',
          label: "Aghanim's",
          value: 'aghanims',
          icon: 'fa-circle-info'
        },
        {
          type: 'status',
          label: 'Neutral Items',
          value: 'neutrals',
          icon: 'fa-circle-info'
        }
      ]

      if (this.winProbability !== undefined) {
        statuses.push({
          type: 'status',
          label: 'Win Probability',
          value: 'win_probability',
          icon: 'fa-circle-info'
        })
      }

      return statuses
    }
  },
  methods: {
    setQuickStat(quickStat) {
      this.$store.commit('SetQuickStats', quickStat)
      this.$emit('selected')
    },
    closeQuickStat() {
      this.$store.commit('CloseQuickStats')
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: 100%;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 0.2vw;
  width: 100%;
}

.item {
  background: #162024;
  padding: 0.4vw;
  border-radius: 0.4vw;
  color: rgb(185, 185, 185);
  letter-spacing: 0.1vw;
  text-shadow: none;
  text-transform: uppercase;
  font-size: 0.65vw;
  line-height: 0.7vw;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.4vw;
  border: 0.05vw solid $color_black;
  box-shadow: 0 0 0.1vw $color_black;
  cursor: pointer;

  .ficon {
    color: $color_primary;
  }

  &:hover {
    color: $color_primary;
    border: 0.05vw solid $color_primary;
    transition: all ease 0.5s;
  }
}
</style>
