<template>
  <div :class="$style.hoverArea" v-if="roshan">
    <tooltip :arrow="false" :delay="0">
      <div :class="$style.container">
        <video
          v-show="roshan.alive"
          src="@/assets/videos/roshan.webm"
          :class="$style.roshan"
          autoplay
          loop
          muted
          playsinline
        ></video>
        <div :class="[$style.lower, { [$style.alive]: roshan.alive }]">
          <template v-if="roshan.alive">
            <div :class="$style.name">Roshan</div>
            <div :class="$style.healthbar">
              <div :class="$style.health" :style="`width: ${health}%;`"></div>
            </div>
            <div :class="$style.healthlabel">{{ roshan.health }} / {{ roshan.max_health }}</div>
            <ul :class="$style.inventory">
              <li
                v-for="index in 4"
                :key="`roshan-item-${index}`"
                :class="[$style.item, { [$style.empty]: inventory[index - 1] === undefined }]"
              >
                <img
                  v-if="inventory[index - 1] !== undefined"
                  :class="$style.image"
                  :src="itemImage(inventory[index - 1])"
                />
              </li>
            </ul>
          </template>
          <template v-else>
            <div :class="$style.phase">{{ phaseText }} {{ phaseTimer }}</div>
          </template>
        </div>
      </div>
    </tooltip>
  </div>
</template>

<script>
import Tooltip from './components/tooltips/tooltip.vue'

export default {
  name: 'roshan-status',
  components: { Tooltip },
  computed: {
    roshan() {
      return this.$store.getters.roshanStatus
    },
    health() {
      if (this.roshan) {
        return (this.roshan.health / this.roshan.max_health) * 100
      }
      return 0
    },
    inventory() {
      if (this.roshan.items_drop) {
        return this.roshan.items_drop.map((item) => item.replace('item_', ''))
      }

      return []
    },
    phaseText() {
      if (this.roshan.alive) {
        return ''
      }

      switch (this.roshan.spawn_phase) {
        case 1:
          return 'Roshan may respawn in'
        case 2:
          return 'Roshan will respawn in'
      }

      return ''
    },
    phaseTimer() {
      if (!this.roshan.alive) {
        const time = Math.floor(this.roshan.phase_time_remaining)
        const minutes = Math.floor(time / 60)
        const seconds = time - minutes * 60
        const leadingZero = seconds < 10 ? '0' : ''
        return `${minutes}:${leadingZero}${seconds}`
      }
      return ''
    }
  },
  methods: {
    itemImage(item) {
      return this.getItemDetails(item, 'image')
    }
  }
}
</script>

<style module lang="scss">
.hoverArea {
  position: absolute;
  bottom: 5.85vw;
  left: 14.88vw;
  height: 2.2vw;
  width: 2.2vw;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 0.4vw rgba($color_primary, 0.9);
  }
}

.container {
  background: rgba(#162024, 0.6);
  border-radius: 0.4vw;
  box-shadow: 0 0.05vw 0.1vw rgba($color_black, 0.5);
  border: 0.2vw solid rgba($color_black, 0.5);
  display: flex;
  flex-direction: column;
  gap: 0.2vw;
  overflow: hidden;
}

.roshan {
  width: 12vw;
  margin-top: -0.8vw;
}

.lower {
  width: 12vw;
  padding: 0.3vw 0.4vw;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.2) 100%);

  &.alive {
    margin-top: -0.8vw;
  }
}

.name {
  color: white;
  text-align: center;
  font-size: 0.8vw;
  text-transform: uppercase;
  margin-bottom: 0.2vw;
}

.healthbar {
  background: black;
  border: 0.1vw solid black;
  height: 0.5vw;
}

.health {
  background: linear-gradient(180deg, rgb(202, 43, 43) 0%, rgb(92, 5, 5) 100%);
  height: 100%;
  transition: width linear 0.3s;
}

.healthlabel {
  color: white;
  text-align: center;
  font-size: 0.8vw;
}

.phase {
  color: white;
  text-align: center;
  font-size: 0.8vw;
  text-transform: uppercase;
}

.inventory {
  margin-top: 0.2vw;
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 0.4vw;
}

.item {
  position: relative;
  width: 2.4vw;
  height: 1.8vw;
  border-radius: 0.4vw;
  border: 0.1vw solid black;
  overflow: hidden;
  background: rgba(black, 0.5);
  box-shadow: inset 0 0 0.2vw rgba(black, 0.8);
}

.image {
  height: 100%;
}
</style>
